export const TypeOfAcquisition = {
	"NATURALIZATION": 1
	,
	"NON_CITIZEN_MARRIED_TO_NAURU_CITIZEN": 2
	,
	"ENTITLEMENT": 3
	,
	"REGAIN": 4
	,
	"GRANTING_TO_INVESTOR_UNDER_CAPITAL_INVESTMENT_IMMIGRATION_PLAN": 5
	,
	"HONORARY_CITIZENSHIP_UNDER_NAURU_DEVELOPMENT_SUPPORT_PROGRAM": 6
	,
	"GRANTING_TO_INDIVIDUAL_UNDER_NAURU_CONTRIBUTION_PROGRAM": 7
	,
	"GRANTING_UNDER_REAL_ESTATE_OPTION_PROGRAM": 8
	,
	"BY_DESCENT": 9
	
}
Object.freeze(TypeOfAcquisition);
const toTypeMap = {};
Object.keys(TypeOfAcquisition).forEach(key => {
	toTypeMap[TypeOfAcquisition[key]] = key;
});
export function getTypeOfAcquisition (value) {
	return toTypeMap[value];
}

export function getAllTypeOfAcquisition () {
    return Object.keys(TypeOfAcquisition);
}

export const TYPE_OF_ACQUISITION_NATURALIZATION = "NATURALIZATION";
export const TYPE_OF_ACQUISITION_NON_CITIZEN_MARRIED_TO_NAURU_CITIZEN = "NON_CITIZEN_MARRIED_TO_NAURU_CITIZEN";
export const TYPE_OF_ACQUISITION_ENTITLEMENT = "ENTITLEMENT";
export const TYPE_OF_ACQUISITION_REGAIN = "REGAIN";
export const TYPE_OF_ACQUISITION_GRANTING_TO_INVESTOR_UNDER_CAPITAL_INVESTMENT_IMMIGRATION_PLAN = "GRANTING_TO_INVESTOR_UNDER_CAPITAL_INVESTMENT_IMMIGRATION_PLAN";
export const TYPE_OF_ACQUISITION_HONORARY_CITIZENSHIP_UNDER_NAURU_DEVELOPMENT_SUPPORT_PROGRAM = "HONORARY_CITIZENSHIP_UNDER_NAURU_DEVELOPMENT_SUPPORT_PROGRAM";
export const TYPE_OF_ACQUISITION_GRANTING_TO_INDIVIDUAL_UNDER_NAURU_CONTRIBUTION_PROGRAM = "GRANTING_TO_INDIVIDUAL_UNDER_NAURU_CONTRIBUTION_PROGRAM";
export const TYPE_OF_ACQUISITION_GRANTING_UNDER_REAL_ESTATE_OPTION_PROGRAM = "GRANTING_UNDER_REAL_ESTATE_OPTION_PROGRAM";
export const TYPE_OF_ACQUISITION_BY_DESCENT = "BY_DESCENT";
