import React from "react";

export class Task {

    constructor(id, name, gui, args) {
        this.id = id;
        this.name = name;
        this.completed = false;
        this.gui = gui;
        this.onFinishCallback;
        this.args = args;
      }
    
      complete = () => {
        this.completed = true;
        if (this.onFinishCallback) {
            this.onFinishCallback(this.id);
        }
      }
    
      // Placeholder for process function
      start = (callback) => {
       
      }
      getState = () => {
        return this.completed;
      }
  
}