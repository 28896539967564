import React, { useState } from "react";
import { useField } from "formik";
import { FormLabel } from '@mui/material';
import LoadingOverlay from 'react-loading-overlay-ts';
import SearchBar from "material-ui-search-bar";
import { t } from "../../../auto/js/services";
import { ImageViewer } from "../../../auto/js/widgets";
import { formatDate } from "../../../auto/js/utils";
import { geoDataMetadataLoader } from "../../../auto/js/metadata/GeoDataMetadataLoader";
import { Button } from "@material-ui/core";
import { formState } from "../forms/FormState";

export const PersonComponent = ({...props}) => {
    const [field, meta, helpers] = useField(props.name);
    const { value } = meta;
    const { setValue } = helpers;
    const [searchResult, setSearchResult] = useState(true);
    const [searchText, setSearchText] = useState("");

    const onChange = (text) => {
		setSearchText(text);
        setSearchResult(false);
	}
	const onRequestSearch = () => {
        if (searchText != null && searchText != "") {
            setValue(searchText);
		    setSearchResult(true);
        }
	}

    return(
        <>
        
        {(!props.readOnly) && 
        <>
        <FormLabel component={"legend"}>{(props.label)?t(props.label):t(props.name)}</FormLabel>
        <SearchBar
			style={{marginLeft: '1rem', marginRight: '-1rem'}}
			onChange={onChange}
			onRequestSearch={onRequestSearch}/>
        </>}
        {(searchResult && value)?
		<Person loadData={ async () => props.loadData(value)} disabledPhoto={props.disabledPhoto}/>
		:null}
        </>
    )
}

class Person extends React.Component {
    constructor(props) {
		super(props);
		this.formRef = React.createRef()
		this.state = {
			data: undefined,
			loading: true,
			loadingMessage: ''
		}
		props.loadData().then((data) => {
            if (data)
                formState.setState(data);
            this.setState({ data: data, loading: false })
        });
	}

    openPersonTab = (id) => {
        var currentUrl = window.location.href;
        var url;
        if (currentUrl.includes("#")) {
            var parts = currentUrl.split("#");
            url = parts[0] + '#' + 'viewvr:id=' + id;
        } 
        else
            url = window.location.hash + '#' + 'viewvr:id=' + id;
        window.open(url, '_blank');
    }
    
    render() {
        let fullname = '';
        let birthdate;
        let birthPlace = [];
        let address = [];
        if (this.state.data) {
            birthdate = this.state.data.birthdate? formatDate(this.state.data.birthdate):t`Unknown`;
            fullname += this.state.data.firstname ? this.state.data.firstname + ' ' : '';
            fullname += this.state.data.secondname ? this.state.data.secondname + ' ' : '';
            fullname += this.state.data.thirdname ? this.state.data.thirdname + ' ' : '';
            fullname += this.state.data.fourthname ? this.state.data.fourthname + ' ' : '';
            fullname += this.state.data.fifthname ? this.state.data.fifthname + ' ' : '';
            if (this.state.data.birthPlace != null) {
                let birthPlaceComponents = this.state.data.birthPlace.split(".");
                let place = this.state.data.birthPlace;
                birthPlace.push(
                    <p style={{marginBottom: "0px"}}>{(geoDataMetadataLoader.getArea(place))?t(geoDataMetadataLoader.getArea(place).name):t("INVALID DATA") + " (" + t(geoDataMetadataLoader.getAreaLevelName(place)) + ")"} </p>
                )
                for (let i = 0; i < birthPlaceComponents.length - 1 ; i ++) {
                    let parentAreaId = place.substring(0, place.lastIndexOf("."));
                    place = parentAreaId;
                    birthPlace.push(
                        <p style={{marginBottom: "0px"}}>{(geoDataMetadataLoader.getArea(parentAreaId))?t(geoDataMetadataLoader.getArea(parentAreaId).name):t("INVALID DATA") + " (" + t(geoDataMetadataLoader.getAreaLevelName(parentAreaId)) + ")"} </p>
                    )                
                }
            }
            else
                birthPlace.push(<p>{t`Unknown Birth Place`}</p>)
            if (this.state.data.address) {
                let addressComponents = this.state.data.address.split(".");
                let place = this.state.data.address;
                address.push(
                    <p style={{marginBottom: "0px"}}>{(geoDataMetadataLoader.getArea(place))?t(geoDataMetadataLoader.getArea(place).name):t("INVALID DATA") + " (" + t(geoDataMetadataLoader.getAreaLevelName(place)) + ")"} </p>
                )
                for (let i = 0; i < addressComponents.length - 1 ; i ++) {
                    let parentAreaId = place.substring(0, place.lastIndexOf("."));
                    place = parentAreaId;
                    address.push(
                        <p style={{marginBottom: "0px"}}>{(geoDataMetadataLoader.getArea(parentAreaId))?t(geoDataMetadataLoader.getArea(parentAreaId).name):t("INVALID DATA") + " (" + t(geoDataMetadataLoader.getAreaLevelName(parentAreaId)) + ")"} </p>
                    )                
                }
            }
            else
                address.push(<p>{t`Unknown`}</p>);
        }
        return (
            <>
            <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text={this.state.loadingMessage}
            >
            {(this.state.data)?
            <div class="row rv5info">
            {(!this.props.disabledPhoto) &&
            <div class="col-md-2">
                <ImageViewer image={this.state.data.image} width={125} height={125}/>  
            </div>}
             <div class="col-md-4"><div class="results" style={{paddingLeft:"0px"}}><h4>{t`Personal Details`}</h4>
                 <p>{fullname}</p>
                 <p>{t`ID`} #: {this.state.data.id}</p>
                 <p style={{fontWeight: "bold"}}>{t`Mother`}:</p>
                 <p>{t`ID`} #: {(this.state.data.motherId)?this.state.data.motherId:t`Reported`}</p>
                 <p style={{fontWeight: "bold"}}>{t`Father`}:</p>
                 <p>{t`ID`} #: {(this.state.data.fatherId)?this.state.data.fatherId:t`Reported`}</p>
             </div>
             </div>
    
             <div class="col-md-3">
                <div class="results">
                  <h4>{t`Birth Details`}</h4>
                 <p>{birthdate}<br />
                {birthPlace}</p>
                <h4>{t`Current Residency`}</h4>
                {address}
                </div>
            </div>
            <div class="col-md-2">
                <div class="results">
                  <h4>{t`Other Details`}</h4>
                 <p>{this.state.data.maritalStatus}</p>
                 <p>{this.state.data.gender}</p>
                </div>
            </div>
            <div class="col-md-1">
                <Button className="link-button" onClick={() => this.openPersonTab(this.state.data.id)}>{t`View Person`}</Button>
            </div>
            </div>:
            <div className="ValidationError">{t`Person not found`}</div>
            }
            </LoadingOverlay>
            </>
        )    
    }
}