import React from "react";
import {v4 as uuidv4} from 'uuid';

import { OPEN_VIEW_EVENT } from "../../../auto/js/events/Gui";
import { createFormComponent } from "../../../auto/js/widgets"
import { rest } from "../../../auto/js/services";
import { showNotification } from "../../../auto/js/utils";
import { PersonComponent } from "../../../auto/js/widgets/PersonComponent";
import { loadPersonData } from "../utils";

const issuedDocumentsFields = [
    {name: "tags", type:"tags", x:1, y:1, layout:"col-md-12"},
    {name:"image", type:"image", x:1, y:2, layout:"col-md-12"},
    {name: "documentId", type:"text", x:1, y:3, layout:"col-md-6"},
    {name: "vitalRecordId", type:"custom", x:1, y:3, layout:"col-md-12", component: (name, disabled) => <PersonComponent name={name} readOnly={disabled} loadData={loadPersonData} disabledPhoto={true}/>},
    {name: "issuedDate", type:"date", x:1, y:4, layout:"col-md-6"},
    {name: "expires", type:"date", x:1, y:4, layout:"col-md-6"},
    {name: "cancelledDate", type:"date", x:1, y:5, layout:"col-md-6"},
    {name: "cancelledReason", type:"text", x:1, y:6, layout:"col-md-12"},
]

export const displayReadIssuedDocumentsForm = (onFinish) => (id) => {
    let IssuedDocumentsFormComponent = createFormComponent(issuedDocumentsFields)

    let uuid = uuidv4();
	return {
		uuid, view: () =>  <IssuedDocumentsFormComponent key={uuid} loadData={async () => loadFormData(id)} buttons={() => null} readOnly={true} />
	};
}

const loadFormData = async (id) => {
    return await rest.read('issued-documents', id).then(response => {
		let form = response;
        let face = null;
		if (response.face != null) {
			let mimeType = "image/png";
			face = "data:".concat(mimeType, ";base64,", response.face)			
		}	
		form['image'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (face != null)?face: '/public/avatar.png', isEmpty: true};
		if (response.status)
            showNotification(response.message.split('Detail: ')[1], "error");
        let tagFilter = {and: true};
        tagFilter['issued-documents-tag'] = {issuedDocumentsId: id};
        return rest.search('issued-documents-tag', tagFilter).then(tags => {
            form['tags'] = tags
            if (tags.status)
                showNotification(response.message.split('Detail: ')[1], "error");
            return form;
        })
	})
}