import React from "react";
import {v4 as uuidv4} from 'uuid';
import { createFormComponent } from '../../../auto/js/widgets/FormComponent';
import { deathRecordFields, loadFormData } from '../../../main/js/forms/DeathRecordFormCommon';
import { AttachmentsArea } from "../widgets";
import { rest, t } from "../services";
import { getServiceUri } from "../metadata";

export const displayReadDeathRecordForm = (onFinish) => (id) => {
	let DeathRecordForm = createFormComponent(deathRecordFields);
	let uuid = uuidv4();
	return {
		uuid, view: () => {
			return (
				<>
				<DeathRecordForm key={uuid} loadData={async () => loadFormData(id)} buttons={() => null} readOnly={true}/>
				<AttachmentsArea label={t`Attachments`} previewUrl={(id) => getPreviewUrl(id)} loadData={async () => loadDeathRecordAttachment(id)} handleClick={(fileId) => handleDeathRecordAttachmentClick(fileId)} readOnly/>
				</>
			)
		}
	};
}

const handleDeathRecordAttachmentClick = async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	window.location = getServiceUri() + 'death-record-attachment' + '/' + id + '/' + token;
};

const getPreviewUrl = (id) => {
	return getServiceUri() + 'death-record-attachment' + '/preview/' + id;
}

const loadDeathRecordAttachment = async (id) => {
	let filter = {and: true};
	filter['death-record-attachment'] = {};
	filter['death-record-attachment']['deathRecordId'] = id;
	return rest.search('death-record-attachment', filter)
}
