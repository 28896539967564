import React from "react";
import { t } from "../../../auto/js/services"

export const Section = ({name}) => {
	return (
		<>
		<div class="col-md-12 sectiontitle">
			<h2 style={{ display: "inline" }}>{t(name)}</h2>
		</div>
		</>
	)
}