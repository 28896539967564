import React from "react";
import { getServiceUri } from "../../../../auto/js/metadata";
import { CIVIL_STATUS_MTLB_ORDER_BY_FIRSTNAME } from "../../../../auto/js/metadata/CivilStatusMtlbOrderBy";
import { MTLB_TYPE_CIVIL_STATUS_CHANGE } from "../../../../auto/js/metadata/MtlbType";
import { rest } from "../../../../auto/js/services";
import { GeoDataComponent } from "../../../../auto/js/widgets/GeoDataComponent";

export const amendmentApplicationListColumns = [
    {title:"image", field:"image", render: rowData => <img src={rowData.image} onError={(e)=>{e.target.onerror = null; e.target.src="/public/avatar.png"}} style={{width: 40, borderRadius: '50%', height: 40}}/>},
    {title: "vitalRecordId", field: "vitalRecordId"},
    {title: "firstname", field: "firstname"},
    {title: "secondname", field: "secondname"},
    // {title: "thirdname", field: "thirdname"},
    {title: "fourthname", field: "fourthname"},
    {title: "birthdate", field: "birthdate"},
];

export const getData = async (filter) => {
    let searchEndpoint = (filter.query)?"civil-status-mtlb/advanced-search":"civil-status-mtlb/search-by-mtlb-type-and-status/";
    return await rest.request(getServiceUri() + searchEndpoint, "POST", filter);
}

export const countData = async (filter) => {
    let countEndpoint = (filter.query)?"civil-status-mtlb/count/advanced-search":"civil-status-mtlb/count/search-by-mtlb-type-and-status/";
    return await rest.request(getServiceUri() + countEndpoint, "POST", filter);
}

export const getOrderBy = () => {
    return CIVIL_STATUS_MTLB_ORDER_BY_FIRSTNAME
}

export const getMtlbTypeList = () => {
    return [MTLB_TYPE_CIVIL_STATUS_CHANGE]
}

export const filterData = (DefaultRows, token) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        const imageUrl = getServiceUri() + 'civil-status-mtlb/face/' + row.id + "/" + token;
        row.image = (imageUrl != null)?imageUrl:"/public/avatar.png";
        let date = row.birthdate
        if ( date !== null)
        row.birthdate = date[2] + '-' + date[1] + '-' + date[0]; 
      newRows.push(row);
    }
    return newRows;
}

export const getErrorList = () => {
    let list = [];
    for (let i = 1; i < 18; i++) {
        let code = (i<10)?"E" + "0" + i : "E" + i
        list.push(code);
    }
    for (let i = 19; i < 23; i++) {
        let code = (i<10)?"E" + "0" + i : "E" + i
        list.push(code);
    }
    for (let i = 28; i < 32; i++) {
        let code = (i<10)?"E" + "0" + i : "E" + i
        list.push(code);
    }
    return list;
}

export const advancedSearchfields = [
    
    {name: "firstname", type: "text", x:1, y:2, layout:"col-md-6"},
    {name:"firstnameCondition", options: [
        {"name":"fuzzy","label":"Fuzzy"},
        {"name":"exact","label":"Exact"},
        {"name":"startsWith","label":"Startswith"},
    ], type: "radio", x:2, y:2, layout:"col-md-6 pt-5",defaultValue:"fuzzy"},
        
    {name: "secondname", type: "text", x:1, y:3, layout:"col-md-6"},
    {name:"secondnameCondition", options: [
        {"name":"fuzzy","label":"Fuzzy"},
        {"name":"exact","label":"Exact"},
        {"name":"startsWith","label":"Startswith"},
    ], type: "radio", x:2, y:3, layout:"col-md-6 pt-5",defaultValue:"fuzzy"},
    
/*         {name: "thirdname", type: "text", x:1, y: 4, layout:"col-md-6"},
    {name:"thirdnameCondition", options: [
        {"name":"fuzzy","label":"Fuzzy"},
        {"name":"exact","label":"Exact"},
        {"name":"startsWith","label":"Startswith"},
    ], type: "radio", x:2, y:4, layout:"col-md-6 pt-5",defaultValue:"fuzzy"}, */
    
        {name: "fourthname", type: "text", x:1, y: 5, layout:"col-md-6"},
        {name:"fourthnameCondition", options: [
        {"name":"fuzzy","label":"Fuzzy"},
        {"name":"exact","label":"Exact"},
        {"name":"startsWith","label":"Startswith"},
    ], type: "radio", x:2, y:5, layout:"col-md-6 pt-5",defaultValue:"fuzzy"},
    
    {name:"genderBox", label: "Gender", components: [
        {name: "male",label:"Male", type: "checkbox", x:1, y:1, layout:"col-md-3"},
    {name: "female",label:"Female", type: "checkbox", x:2, y:1, layout:"col-md-3"},
    ], type: "box", x:3, y:6, layout:"col-md-11 ms-2 mt-2"},//mx-auto
    {name:"birthBox", label: "Birth Date", components: [
        {name: "rangeBirthdate",label:"exactbirthdate", type: "checkbox", x:1, y:1, layout:"col-md-12"},
        {name: "exactBirthdate", type: "date", x:1, y: 2, layout:"col-md-6", display: (data)=>{
            return !data.rangeBirthdate;
        }},
        
        {name: "birthdateStart", type: "date", x:1, y: 3, layout:"col-md-6",display: (data)=>{
            return data.rangeBirthdate;
        }},
        {name: "birthdateEnd", type: "date", x:2, y: 3, layout:"col-md-6", display: (data)=>{
            return data.rangeBirthdate;
        }},
    ], type: "box", x:1, y:7, layout:"col-md-11 ms-2 mt-2 pt-1 pb-2"},

    {name:"birthBox", label: "Birth Place", components: [
        {name: "anyBornOutsideCountry", label:"anyBornOutsideCountry", type: "checkbox", x:1, y:1, layout:"col-md-12"},
        {name: "birthPlace", type: "custom", x:1, y:2, layout:"col-md-6",
            component: (name, disabled) => <GeoDataComponent name={name} disabled={disabled}/>,
            display: (data) => {return !data.anyBornOutsideCountry}
        },
    ], type: "box", x:2, y:9, layout:"col-md-11 ms-2 mt-2 pt-1 pb-2"}
];

export const transformAdvancedSearchData = (formData) => {
    const columns = [];
    
    const addColumn = (name, type, value, condition) => {
        columns.push({
        name,
        type,
        [type === 'date' ? 'dateValue' : type === 'boolean' ? 'booleanValue' : (type === 'text' ? 'textValue' : 'longValue')]: value,
        searchType: condition ? condition : null,
        });
    };
    (formData.firstname !== "") && addColumn('firstname', 'text', formData.firstname, formData.firstnameCondition);
    
    (formData.secondname !== "") && addColumn('secondname', 'text', formData.secondname, formData.secondnameCondition);
    
    (formData.thirdname !== "") && addColumn('thirdname', 'text', formData.thirdname, formData.thirdnameCondition);
    
    (formData.fourthname !== "") && addColumn('fourthname', 'text', formData.fourthname, formData.fourthnameCondition);
    addColumn('mtlbType', 'number', 6, null);
    addColumn('draft', 'boolean', false, null)
    if (formData.anyBornOutsideCountry)
        addColumn('birthPlace', 'text', '121', 'notStartsWith');
    else
        (formData.birthPlace) && addColumn('birthPlace', 'text', formData.birthPlace,formData.birthPlace? "startsWith" : null);
    const birthdateRange = formData.birthdateStart && formData.birthdateEnd
        ? {
                min: formData.birthdateStart ? formData.birthdateStart : null,
                max: formData.birthdateEnd ? formData.birthdateEnd : null,
            }
        : null;
    
    (birthdateRange != null || formData.exactBirthdate != null) && addColumn('birthdate', 'date', formData.exactBirthdate, null);
    if ((birthdateRange != null || formData.exactBirthdate != null))
        columns[columns.length - 1].dateRange = birthdateRange;
    
    const gender = [];
    formData.male ? gender.push("1") : null;
    formData.female ? gender.push("2") : null;
    if (gender.length)
        columns.push({
        name: 'gender',
        type: 'enum',
        enumValues: gender,
        });
    
    return { columns };
};