import React from "react";
import { v4 as uuidv4 } from 'uuid';
import { t } from "../../../auto/js/services";
import { StepperBrowser } from "../../../auto/js/browsers/StepperBrowser";

import { DeathApplicationTask } from "../tasks/DeathApplicationTask";
import { DeathServerApprovalTask } from "../tasks/DeathServerApprovalTask";
import { DeathApproveApplicationTask } from "../tasks/DeathApproveApplicationTask";
import { DeathApplicationRgistrationTask } from "../tasks/DeathApplicationRgistrationTask";
import { PrintDeathCertificateTask } from "../tasks/PrintDeathCertificateTask";

export const displayDeathApplicationManager = (gui, args) => {
  let steps = [
    t`DeathApplication`,
    t`DeathServerApproval`,
    t`DeathApproveApplication`,
    t`DeathApplicationRgistration`,
    t`PrintDeathCertificate`,
  ];

  class DeathApplicationManagerStepperBrowser extends StepperBrowser {
    constructor(props) {
      super(props)
      this.tasks.push(new DeathApplicationTask(0, steps[0], this, args));
      this.tasks.push(new DeathServerApprovalTask(1, steps[1], this, args));
      this.tasks.push(new DeathApproveApplicationTask(2, steps[2], this, args));
      this.tasks.push(new DeathApplicationRgistrationTask(3, steps[3], this, args));
      this.tasks.push(new PrintDeathCertificateTask(4, steps[4], this, args));
      this.start();
    }

  }

  const createDeathApplicationManagerFlowBrowser = (gui) => {
    let uuid = uuidv4();
    return {
        uuid,
        view: () => <DeathApplicationManagerStepperBrowser gui={gui} steps={steps} welcome={() => <></>}/>
    }
  }
  gui.goTo(createDeathApplicationManagerFlowBrowser, null, null);
}