import React from 'react';

import { loadFormData } from "../../auto/js/forms/CivilRecordForm";
import { createFormComponent } from "../../auto/js/widgets";
import { civilRecordFormfields } from "./forms/CivilRecordFormCommon";
import { rest } from '../../auto/js/services';
import { showNotification } from '../../auto/js/utils';
import { getServiceUri } from '../../auto/js/metadata';
import { TypeOfAcquisition } from '../../auto/js/metadata/TypeOfAcquisition';
import { loadHistoryFormData } from './forms/CivilRecordHistoryCommon';

const viewVitalRecord = (args) => {
	let CivilRecordForm = createFormComponent(civilRecordFormfields);
	return <CivilRecordForm loadData={async () => loadFormData(args.id)} readOnly buttons={() => null}/>

}

const viewVitalRecordHistory = (args) => { 
	let CivilRecordForm = createFormComponent(civilRecordFormfields);
	return <CivilRecordForm loadData={async () => loadHistoryFormData(args.id)} readOnly buttons={() => null}/>

}

export const subcommandOptions = {
	viewvr: {
	  id: 'number'
	},
	viewvrh: {
		id: 'number'
	  }
};

export const commandHooks = {
	viewvr: viewVitalRecord,
	viewvrh: viewVitalRecordHistory
}
