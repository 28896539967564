export const UNION_RECORD_ORDER_BY_ADDRESS = "ADDRESS";
export const UNION_RECORD_ORDER_BY_DATE_OF_MARRIAGE = "DATE_OF_MARRIAGE";
export const UNION_RECORD_ORDER_BY_FIRST_WITNESS_ID = "FIRST_WITNESS_ID";
export const UNION_RECORD_ORDER_BY_ID = "ID";
export const UNION_RECORD_ORDER_BY_NOTES = "NOTES";
export const UNION_RECORD_ORDER_BY_PARTNER1_FATHER_ID = "PARTNER1_FATHER_ID";
export const UNION_RECORD_ORDER_BY_PARTNER1_FATHER_NAME = "PARTNER1_FATHER_NAME";
export const UNION_RECORD_ORDER_BY_PARTNER1_FIRSTNAME = "PARTNER1_FIRSTNAME";
export const UNION_RECORD_ORDER_BY_PARTNER1_MOTHER_ID = "PARTNER1_MOTHER_ID";
export const UNION_RECORD_ORDER_BY_PARTNER1_MOTHER_NAME = "PARTNER1_MOTHER_NAME";
export const UNION_RECORD_ORDER_BY_PARTNER1_SURNAME = "PARTNER1_SURNAME";
export const UNION_RECORD_ORDER_BY_PARTNER1_VITAL_RECORD_ID = "PARTNER1_VITAL_RECORD_ID";
export const UNION_RECORD_ORDER_BY_PARTNER2_FATHER_ID = "PARTNER2_FATHER_ID";
export const UNION_RECORD_ORDER_BY_PARTNER2_FATHER_NAME = "PARTNER2_FATHER_NAME";
export const UNION_RECORD_ORDER_BY_PARTNER2_FIRSTNAME = "PARTNER2_FIRSTNAME";
export const UNION_RECORD_ORDER_BY_PARTNER2_MOTHER_ID = "PARTNER2_MOTHER_ID";
export const UNION_RECORD_ORDER_BY_PARTNER2_MOTHER_NAME = "PARTNER2_MOTHER_NAME";
export const UNION_RECORD_ORDER_BY_PARTNER2_SURNAME = "PARTNER2_SURNAME";
export const UNION_RECORD_ORDER_BY_PARTNER2_VITAL_RECORD_ID = "PARTNER2_VITAL_RECORD_ID";
export const UNION_RECORD_ORDER_BY_PLACE = "PLACE";
export const UNION_RECORD_ORDER_BY_RITES = "RITES";
export const UNION_RECORD_ORDER_BY_SECOND_WITNESS_ID = "SECOND_WITNESS_ID";
export const UNION_RECORD_ORDER_BY_UNION_TYPE = "UNION_TYPE";
export const UNION_RECORD_ORDER_BY_DATE_OF_ORDER = "DATE_OF_ORDER";
export const UNION_RECORD_ORDER_BY_DATE_OF_EFFECT = "DATE_OF_EFFECT";
export const UNION_RECORD_ORDER_BY_TIME_OF_MARRIAGE = "TIME_OF_MARRIAGE";
export const UNION_RECORD_ORDER_BY_TOUCHED = "TOUCHED";
export const UNION_RECORD_ORDER_BY_ANNULMENT_ORDER = "ANNULMENT_ORDER";
