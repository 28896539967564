import React, { useState } from "react";

import { pojoMetadata } from "../../../auto/js/metadata";
import { rest, t } from "../../../auto/js/services";
import { Observable } from "../../../auto/js/events/Observable";
import { formState } from "../../../auto/js/forms/FormState";
import { geoDataMetadataLoader } from "../metadata/GeoDataMetadataLoader";
import { SimpleAutoCompleteInput } from "../widgets/SimpleAutoCompleteInput";

const countryObservable = new Observable();
const districtObservable = new Observable();
const resetDistrictEvent = new Observable();

let resetList =[resetDistrictEvent,];
export const addressFields = [
 	{
    	title: "country", field: "country",
    	 editComponent: props => {
    		let [value, setValue] = useState({key: 121, value: "Nauru"});
    		const handleCountryChange = (value) => {
		        resetFieldsFromIndex(0);
		        if (value.key) {
		            countryObservable.publish(geoDataMetadataLoader.getChilds(value.key));
                    props.onChange(value);
		            setValue(value);
		        }
		        else {
		            countryObservable.publish([]);
		        }
   			}
			return (
	        	<SimpleAutoCompleteInput  name={"country"} options={() => geoDataMetadataLoader.getRootNodes()} handleChange={(data) => handleCountryChange(data)}   defaultValue={value} disabled={props.disabled}/>
	        )
        },
        render: rowData => <>{(rowData.country)?rowData.country.value:""}</>
    },
 	{
    	title: "district", field: "district",
    	 editComponent: props => {
    		let [options, setOptions] = useState(geoDataMetadataLoader.getChilds(121));
    		let [value, setValue] = useState(props.value);
    		const handleDistrictChange = (value) => {
		        resetFieldsFromIndex(1);
		        if (value.key) {
		            districtObservable.publish(geoDataMetadataLoader.getChilds(value.key));
                    props.onChange(value);
		            setValue(value);
		        }
		        else {
		            countryObservable.publish([]);
		        }
   			}
   			if (props.rowData.country && props.rowData.country != null && options === "") {
        		setOptions(geoDataMetadataLoader.getChilds(props.rowData.country.key))
    		}
			return (
	        	<SimpleAutoCompleteInput  name={"district"} options={() => options} handleChange={(data) => handleDistrictChange(data)} observable={countryObservable} reset={resetDistrictEvent}  defaultValue={value} disabled={props.disabled}/>
	        )
        },
        render: rowData => <>{(rowData.district)?rowData.district.value:""}</>
    },
    {title: "fromDate", field: "fromDate", type:"date"},
    {title: "toDate", field: "toDate", type:"date"},
]

export const buildAddressData = async (query, id) => {
    let filter = query;
    let data;
    filter["address"] = {civilStatusMtlbId: id};
    filter["orderBy"] = null;
    filter.orderDirection = null;
    filter.offset = query.page * query.pageSize;
    if (query.search && query.search!='') {
        pojoMetadata["address"].columns.forEach(element => {
            if(element.type=='text'){
                filter["address"][element.key]= query.search;
            }
        });
        filter["and"] = false;
    }
    return await getAddressData(filter).then(response => {
        data = filterData(response);
        data.forEach(element => {
            loadGeoData(element);
        });
        formState.setAddressList(data);
        return countAddressData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
    });
}

export const getAddressData = async (filter) => {
    return rest.search('address', filter);
}

export const loadGeoData = async (element) => {
    if (element.areaId != null) {
        let geographicData = getGeographicDataFromLocation(element.areaId);
        element.country = geographicData.country;
        element.district = geographicData.district;
    }
}

export const countAddressData = async (filter) => {
    return await rest.count('address', filter);
}

export const filterData = (DefaultRows) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        let fromDate = row.fromDate;
        if ( fromDate !== null) {
            let date = new Date(fromDate[0]+ "/" + fromDate[1] + "/" + fromDate[2]);
            date.setTime(date.getTime() + 60 * 60 * 1000);
            row.fromDate = date;
        }
        let toDate = row.toDate
        if ( toDate !== null) {
            let date = new Date(toDate[0]+ "/" +toDate[1] + "/" + toDate[2]);
            date.setTime(date.getTime() + 60 * 60 * 1000);
            row.toDate = date;
        }
        newRows.push(row);
    }
    return newRows;
}

export const getAddressEditables = (id) => {
    let editables = {}
    editables.onRowAdd = newData =>
        new Promise((resolve, reject) => {

            let dto = pojoMetadata['address'].form2dto(newData);
            dto["civilStatusMtlbId"] = id;
            form2Dto(dto, newData);
            try {
                return rest.create('address', dto).then(() => resolve());
            } catch (err) {
                alert(err);
            }
        }),
        editables.onRowUpdate = (newData, oldData) =>
            new Promise((resolve, reject) => {
                let dto = pojoMetadata['address'].form2dto(newData);
                newData.id = oldData.id;
            	form2Dto(dto, newData);
                try {
                    return rest.update('address', dto).then(() => {
                        formState.addToAddressList(newData)
                        resolve()}).catch((e) => console.table(e));

                } catch (err) {
                    alert(err);
                }
            }),
        editables.onRowDelete = oldData =>
            new Promise((resolve, reject) => {
                try {
                    return rest.purge('address', oldData.id).then(() => resolve());
                } catch (err) {
                    alert(err);
                }
            })
    return editables;
}

let getGeographicDataFromLocation = (areaId) => {
    let geoComponents = areaId.split(".");
    let geoResult = {};
    geoResult.country = (geoComponents.length >= 1) ? {
        key: (geoDataMetadataLoader.areas[geoComponents.slice(0, 1).join(".")])
            ? geoDataMetadataLoader.areas[geoComponents.slice(0, 1).join(".")].id
            : "",
        value: (geoDataMetadataLoader.areas[geoComponents.slice(0, 1).join(".")])
            ? geoDataMetadataLoader.areas[geoComponents.slice(0, 1).join(".")].name
            : ""
    } : {};
    geoResult.district = (geoComponents.length >= 2) ? {
        key: (geoDataMetadataLoader.areas[geoComponents.slice(0, 2).join(".")])
            ? geoDataMetadataLoader.areas[geoComponents.slice(0, 2).join(".")].id
            : "",
        value: (geoDataMetadataLoader.areas[geoComponents.slice(0, 2).join(".")])
            ? geoDataMetadataLoader.areas[geoComponents.slice(0, 2).join(".")].name
            : ""
    } : {};
    return geoResult;
}

export const form2Dto = (dto, form) => {
		if (form.district?.key)
			dto["areaId"] = geoDataMetadataLoader.getAreaId(form.district.key);
		else if(form.country?.key)
        	dto["areaId"] = geoDataMetadataLoader.getAreaId(form.country.key);
}
const resetFieldsFromIndex = (index) => {
    for (let i = index; i < resetList.length; i++) {
        resetList[i].publish();
    }
}