import React from "react";
import { getServiceUri } from "../../../../auto/js/metadata";
import { REGISTRAR_ORDER_BY_ID } from "../../../../auto/js/metadata/RegistrarOrderBy";
import { rest } from "../../../../auto/js/services";


export const registrarListColumns = [
    {title:"Face", field:"image",  render: rowData => <img src={rowData.image} onError={(e)=>{e.target.onerror = null; e.target.src="/public/avatar.png"}} style={{width: 40, borderRadius: '50%', height: 40}}/>},
    {title: "ID", field: "vitalRecordId"},
    {title: "First Name", field: "firstname"},
    {title: "Family Name", field: "fourthname"}
]

export const getOrderBy = () => {
    return REGISTRAR_ORDER_BY_ID;
}

export const filterData = async (defaultRows, token) => {
    const newRows = [];
    for (let i in defaultRows) {
        let row = defaultRows[i];
        await rest.read('vital-record', row.vitalRecordId).then(r => {
            if (r.faceId != null) {
                const imageUrl = getServiceUri() + 'face/image/' + r.faceId + "/" + token;
                row.image = (imageUrl != null)?imageUrl:"/public/avatar.png";
            } else
                row.image = "/public/avatar.png";
            row.firstname = r.firstname;
            row.fourthname = r.fourthname;
        })
        newRows.push(row);
    }
    return newRows;
}