import React from "react";
import _ from 'lodash';
import { getServiceUri, metadataLoader, pojoMetadata } from "../../../../auto/js/metadata";
import { rest } from "../../../../auto/js/services/RestClient";
import { t } from '../../../../auto/js/services/i18ndb';
import { showNotification } from "../../../../auto/js/utils";
import { dto2form, form2dto } from "../../../../main/js/forms/idCardApplication/IdCardApplicationFormCommon";

export const saveIdCardApplicationForm = async (formData) => {
	let data = _.clone(formData);
    let dto = pojoMetadata['id-mtlb'].form2dto(data);
    form2dto(formData, dto);
    try {
		return rest.request(getServiceUri() + 'id-mtlb/new-document', 'POST', dto)
    } catch (err) {
        alert(err);
    }
}

export const loadIdCardApplicationFormData = async (id) => {
	return await rest.read('id-mtlb', id).then(response => {
		let form = dto2form(response);
		let tagFilter = {and: true};
		tagFilter['id-mtlb-tag'] = {idMtlbId: id};
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error");
		return rest.search('id-mtlb-tag', tagFilter).then(tags => {
			form['tags'] = tags
			if (tags.status)
				showNotification(response.message.split('Detail: ')[1], "error");
			return form;
		})
	})
}

export const updatePendingIdCardApplicationForm = async (formData) => {
	let data = _.clone(formData);
	let dto = pojoMetadata['id-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'id-mtlb/pending/update', 'POST', dto);
    } catch (err) {
        alert(err);
    }
}

export const updateIdCardApplicationForm = async (formData) => {
	let data = _.clone(formData);
	const dto = pojoMetadata['id-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'id-mtlb/edit-document', 'POST', dto);
    } catch (err) {
        alert(err);
    }
}

export const deleteIdCardApplicationForm = async (id) => {
    try {
        return rest.delete('id-mtlb', id);
    } catch (err) {
        alert(err);
    }
}

export const updateRejectedIdCardApplicationForm = async (formData) => {
	//TODO: Move form2dto from pojo metadata
	let data = _.clone(formData);
	let dto = pojoMetadata['id-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'id-mtlb/rejected/update', 'POST', dto);
    } catch (err) {
        alert(err);
    }
}

export const rejectIdCardApplicationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'id-mtlb/pending/reject', 'POST', id);
    } catch (err) {
        alert(err);
    }
}

export const rejectReadyIdCardApplicationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'id-mtlb/ready/reject', 'POST', id);
    } catch (err) {
        alert(err);
    }
}

export const approveReadyIdCardApplicationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'id-mtlb/ready/approve', 'POST', id);
    } catch (err) {
        alert(err);
    }
}
