import React from "react";
import {v4 as uuidv4} from 'uuid';
import { createFormComponent } from '../../../auto/js/widgets/FormComponent';
import { rest } from "../../../auto/js/services/RestClient";
import { getServiceUri, pojoMetadata } from "../../../auto/js/metadata";



const fingerprintsFields = [
    {name: "leftThumbImage", type:"view", x:1, y:1, layout:"col-md-6"},
	{name: "leftIndexFingerImage", type:"view", x:2, y:1, layout:"col-md-6"},
	{name: "leftMiddleFingerImage", type:"view", x:1, y:2, layout:"col-md-6"},
	{name: "leftRingFingerImage", type:"view", x:2, y:2, layout:"col-md-6"},
	{name: "leftPinkyImage", type:"view", x:1, y:19, layout:"col-md-6"},
	{name: "rightThumbImage", type:"view", x:2, y:19, layout:"col-md-6"},
	{name: "rightIndexFingerImage", type:"view", x:1, y:20, layout:"col-md-6"},
	{name: "rightMiddleFingerImage", type:"view", x:2, y:20, layout:"col-md-6"},
	{name: "rightRingFingerImage", type:"view", x:1, y:21, layout:"col-md-6"},
	{name: "rightPinkyImage", type:"view", x:2, y:21, layout:"col-md-6"}
];

export class BiometricDataComponent extends React.Component {
	constructor(props) {
		super(props);
    }
	render() {
        let CivilRecordFingerprintsForm = createFormComponent(fingerprintsFields);
		return (
		<>
            <CivilRecordFingerprintsForm key={this.props.key} readOnly loadData={async () => loadFormData(this.props.id)} buttons={() => null}/>
		</>
		)
	}

}


export const displayCivilRecordBiometricData = (gui, id) => {
	const readCivilRecordBiometricData = (onFinish) => (id) => {
		let uuid = uuidv4();
		return { 
			uuid, view: () => <BiometricDataComponent key={uuid} id={id} />
		}
	}
    gui.goTo(readCivilRecordBiometricData(), id)
}

const loadFormData = async (id) => {
    let filter = {};
    let data;
    filter["fingerprints"] = {vitalRecordId: id};
    filter["orderBy"] = null;
    filter.orderDirection = null;
    filter.offset = null;
    return await getData(filter).then(response => {
        if (response.length)
            return loadFingerprint(response[response.length - 1].id)
        else 
            return loadEmptyFingerprint();
    });
}

const getData = async (filter) => {
    return rest.search('fingerprints', filter)
}

const loadFingerprint = async (id) => {
    let token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	let form = {};
    form['leftThumbImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: getServiceUri() + 'fingerprints/' + 'left-thumb/' + id + '/' + token, isEmpty: true, width:70, height:70}
    form['leftIndexFingerImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: getServiceUri() + 'fingerprints/' + 'left-index-finger/' + id + '/' + token, isEmpty: true, width:70, height:70}
    form['leftMiddleFingerImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: getServiceUri() + 'fingerprints/' + 'left-middle-finger/' + id + '/' + token, isEmpty: true, width:70, height:70}
    form['leftRingFingerImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: getServiceUri() + 'fingerprints/' + 'left-ring-finger/' + id + '/' + token, isEmpty: true, width:70, height:70}
    form['leftPinkyImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: getServiceUri() + 'fingerprints/' + 'left-pinky/' + id + '/' + token, isEmpty: true, width:70, height:70}
    form['rightThumbImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: getServiceUri() + 'fingerprints/' + 'right-thumb/' + id + '/' + token, isEmpty: true, width:70, height:70}
    form['rightIndexFingerImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: getServiceUri() + 'fingerprints/' + 'right-index-finger/' + id + '/' + token, isEmpty: true, width:70, height:70}
    form['rightMiddleFingerImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: getServiceUri() + 'fingerprints/' + 'right-middle-finger/' + id + '/' + token, isEmpty: true, width:70, height:70}
    form['rightRingFingerImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: getServiceUri() + 'fingerprints/' + 'right-ring-finger/' + id + '/' + token, isEmpty: true, width:70, height:70}
    form['rightPinkyImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: getServiceUri() + 'fingerprints/' + 'right-pinky/' + id + '/' + token, isEmpty: true, width:70, height:70}
  return form;
}

const loadEmptyFingerprint = async () => {
	let form = {};
    form['leftThumbImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: 'public/finger-not-found.png', isEmpty: true, width:70, height:70}
    form['leftIndexFingerImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: 'public/finger-not-found.png', isEmpty: true, width:70, height:70}
    form['leftMiddleFingerImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: 'public/finger-not-found.png', isEmpty: true, width:70, height:70}
    form['leftRingFingerImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: 'public/finger-not-found.png', isEmpty: true, width:70, height:70}
    form['leftPinkyImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: 'public/finger-not-found.png', isEmpty: true, width:70, height:70}
    form['rightThumbImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: 'public/finger-not-found.png', isEmpty: true, width:70, height:70}
    form['rightIndexFingerImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: 'public/finger-not-found.png', isEmpty: true, width:70, height:70}
    form['rightMiddleFingerImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: 'public/finger-not-found.png', isEmpty: true, width:70, height:70}
    form['rightRingFingerImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: 'public/finger-not-found.png', isEmpty: true, width:70, height:70}
    form['rightPinkyImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: 'public/finger-not-found.png', isEmpty: true, width:70, height:70}
  return form;
}
