import React from "react";
import { useField } from "formik";
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { RadioGroup } from "@material-ui/core";
import { t } from "../services";

export function RadioComponent(props) {
  const [field, meta, helpers] = useField(props.name);
  const { value } = meta;
  const { setValue } = helpers;

  if (!value && props.defaultValue)
    setValue(props.defaultValue)

  const handleChange = event => {
    setValue(event.target.value);
  };

  return (
    <div>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
      {props.options.map(option => (
        <FormControlLabel
          key={option.name}
          control={<Radio
            onChange={handleChange}
            color="primary"
            checked={value === option.name}
          />}
          defaultValue={(props.defaultValue)?props.defaultValue: ""}
          value={option.name}
          label={t(option.label)}
          style={{ left: (props.language === "ar") ? '24.5rem' : '0', marginTop: '10px' }}
          disabled={props.disabled}
        />
      ))}
      </RadioGroup>
    </div>
  );
}