export const Tribe = {
	"DEIBOE": 1
	,
	"EAMWIDAMIT": 2
	,
	"EAMWIDARA": 3
	,
	"EAMWIT": 4
	,
	"EAMGUM": 5
	,
	"EANO": 6
	,
	"EMEO": 7
	,
	"EORARU": 8
	,
	"IRUTSI": 9
	,
	"IRUWA": 10
	,
	"IWI": 11
	,
	"RANIBOK": 12
	,
	"FOREIGN_BORN": 13
	
}
Object.freeze(Tribe);
const toTypeMap = {};
Object.keys(Tribe).forEach(key => {
	toTypeMap[Tribe[key]] = key;
});
export function getTribe (value) {
	return toTypeMap[value];
}

export function getAllTribe () {
    return Object.keys(Tribe);
}

export const TRIBE_DEIBOE = "DEIBOE";
export const TRIBE_EAMWIDAMIT = "EAMWIDAMIT";
export const TRIBE_EAMWIDARA = "EAMWIDARA";
export const TRIBE_EAMWIT = "EAMWIT";
export const TRIBE_EAMGUM = "EAMGUM";
export const TRIBE_EANO = "EANO";
export const TRIBE_EMEO = "EMEO";
export const TRIBE_EORARU = "EORARU";
export const TRIBE_IRUTSI = "IRUTSI";
export const TRIBE_IRUWA = "IRUWA";
export const TRIBE_IWI = "IWI";
export const TRIBE_RANIBOK = "RANIBOK";
export const TRIBE_FOREIGN_BORN = "FOREIGN_BORN";
