import React from "react";
import * as Yup from 'yup';
import { buildEmptyObject } from "../../../../auto/js/widgets/FormComponent";
import { getServiceUri, pojoMetadata } from "../../../../auto/js/metadata";
import { MTLB_TYPE_ID_CARD } from "../../../../auto/js/metadata/MtlbType";
import { rest, t } from "../../../../auto/js/services";
import { showNotification } from "../../../../auto/js/utils";

import { PRINT_EVENT} from './../../../../auto/js/events/Gui';
import { MTLB_STATUS_ARCHIVED } from "../../../../auto/js/metadata/MtlbStatus";
import  {IdCard}  from './../../../../auto/js/forms/IdCard';
import {style} from './../../../../auto/js/forms/IdCardStyleObj';
import { setContextualOptions } from '../../../../auto/js/widgets/RibbonTab'; 
import { ADDRESS_ORDER_BY_PROVINCE_NAME } from "../../../../auto/js/metadata/AddressOrderBy";
import { PersonComponent } from "../../../../auto/js/widgets/PersonComponent";
import { loadPersonData } from "../../utils";
import { DOCUMENT_TYPE_ID_CARD } from "../../../../auto/js/metadata/DocumentType";
import { AdvancedSearchPersonComponent } from "../../widgets/AdvancedSearchPersonComponent";

export const idCardApplicationFields = [
	{name: "tags", type:"tags", x:1, y:1, layout:"col-md-12", },
	{name:"image", type:"image", x:1, y:2, layout:"col-md-12"},
	{name: "photo", type: "validation", x:1, y:3, layout:"col-md-12",
	"validation":  Yup.string().nullable().default(undefined).when(["image"],
		(image) => {
			if (image.isEmpty)
				return Yup.string().nullable().default(undefined).required('Photo is Required')
		})
	},
	{name: "vitalRecordId", type: "custom", x:1, y: 5, layout:"col-md-12", component: (name, disabled) => <AdvancedSearchPersonComponent name={name} readOnly={true} loadData={loadPersonData} label={"Citizen Details"} disabledPhoto={true}/>},
    {name: "notes", type: "text", x:1, y:7, layout:"col-md-12"}
];

export const saveIdCardApplicationForm = async (formData) => {
	let data = _.clone(formData);
    let dto = pojoMetadata['id-mtlb'].form2dto(data);
    dto.mtlbType = MTLB_TYPE_ID_CARD;
	dto.vitalRecordId = formData.id;
	dto.id = null;
	if (!formData.image.isEmpty) {
		let base64Image = formData.image.url;
		let faceMimeType = base64Image.substring(base64Image.indexOf(':') + 1, base64Image.indexOf(';'))
		let base64 = base64Image.substr(base64Image.indexOf(',') + 1);
		dto.face = base64;
		dto.faceMimeType = faceMimeType;
	}
    try {
        return rest.request(getServiceUri() + 'apply/create-id-mtlb', 'POST', dto)
    } catch (err) {
        alert(err);
    }
}

const printIdCard = (data) => () => {
	console.log(data);
	let dto = {};
	dto.vitalRecordId = data.id;
	dto.face = data.image;
	dto.documentType = DOCUMENT_TYPE_ID_CARD;
	rest.stringResponseRequest(getServiceUri() + 'apply/issue-document', 'POST', dto).then((response) => {
		data.documentId = response;
		const printable = {
			content:<IdCard data={data}/>,
			style:{style}.style,
			copyParentStyle:false
		}
		PRINT_EVENT.publish(printable);
	})
}
const filterData = (DefaultRows) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];     
        let fromDate = row.fromDate
        if ( fromDate !== null) {
            let date = new Date(fromDate[0]+ "/" +fromDate[1] + "/" + fromDate[2]);
            date.setTime(date.getTime() + 60 * 60 * 1000)
            row.fromDate = date;
        }
        let toDate = row.toDate
        if ( toDate !== null) {
            let date = new Date(toDate[0]+ "/" +toDate[1] + "/" + toDate[2]);
            date.setTime(date.getTime() + 60 * 60 * 1000)
            row.toDate = date;
        }
      newRows.push(row);
    }
    return newRows;
}
 const buildAddressData = async (query, id) => {
    let filter = query;
    let data;
    filter["address"] = {vitalRecordId: id};
    filter["orderBy"] = ADDRESS_ORDER_BY_PROVINCE_NAME;
    filter.orderDirection = null;
    filter.offset = query.page * query.pageSize;
    if (query.search && query.search!='') {
        pojoMetadata["address"].columns.forEach(element => {
            if(element.type=='text'){
                filter["address"][element.key]= query.search;
            }
        });
        filter["and"] = false;
    }
    return await getData(filter).then(response => {
		if (response.length) {
		    let areaId = response[0].areaId;
		    return  areaId;
        }
        else
            return ""
    });
}

const getData = async (filter) => {
    return await rest.search('address', filter)
}	

export const loadIdCardApplicationFormData = async (id) => {
	return await rest.read('id-mtlb', id).then(response => {
		let form = response;
		let face = null;
		if (response.face != null) {
			let mimeType = response['faceMimeType'];
			face = "data:".concat(mimeType, ";base64,", response.face)			
		}	
		form['image'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (face != null)?face: '/public/avatar.png', isEmpty: true};
		let tagFilter = {and: true};
		tagFilter['id-mtlb-tag'] = {idMtlbId: id};
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error")
		
		return rest.search('id-mtlb-tag', tagFilter).then(async tags => {
			form['tags'] = tags
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error");

				if (form['mtlbStatus'] == MTLB_STATUS_ARCHIVED) {

					let vitalRecordId = form['vitalRecordId'];
					rest.read('vital-record', vitalRecordId).then(async print => {
						print['image'] = form['face'];
						let address = null;
							try 
							{
								address =  await buildAddressData({},vitalRecordId);
							} 
							catch (error) 
							{	
							}		
							print['address'] = address; 
						setContextualOptions({
						"id-database": {
							submenu: {
								"cr-form": {
									options: {
										"print-id": { 
											label: "Print", do:printIdCard(print)
										},
									}
								}
							}
						}
					})
				})
				}
			return form;
		})
	})
}

export const updatePendingIdCardApplicationForm = async (formData) => {
	let dto = pojoMetadata['id-mtlb'].form2dto(formData);
	if (!formData.image.isEmpty) {
		let base64Image = formData.image.url;
		let faceMimeType = base64Image.substring(base64Image.indexOf(':') + 1, base64Image.indexOf(';'))
		let base64 = base64Image.substr(base64Image.indexOf(',') + 1);
		dto.face = base64;
		dto.faceMimeType = faceMimeType;
	}
    try {
        return rest.request(getServiceUri() + 'id-mtlb/pending/update', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Updated Id Card Application`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const updateRejectedIdCardApplicationForm = async (formData) => {
	let dto = pojoMetadata['id-mtlb'].form2dto(formData);
	if (!formData.image.isEmpty) {
		let base64Image = formData.image.url;
		let faceMimeType = base64Image.substring(base64Image.indexOf(':') + 1, base64Image.indexOf(';'))
		let base64 = base64Image.substr(base64Image.indexOf(',') + 1);
		dto.face = base64;
		dto.faceMimeType = faceMimeType;
	}
    try {
        return rest.request(getServiceUri() + 'id-mtlb/rejected/update', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Reopened Id Card Application`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const rejectIdCardApplicationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'id-mtlb/pending/reject', 'POST', id).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Rejected Id Card Application`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const buildIdCardApplicationFormEmptyObject  = () => {
    const empty = buildEmptyObject(idCardApplicationFields);
    empty['image'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url:'/public/avatar.png', isEmpty: true};
	return empty;
}

export const updateIdCardApplicationForm = async (formData) => {
	let dto = pojoMetadata['id-mtlb'].form2dto(formData);
	if (!formData.image.isEmpty) {
		let base64Image = formData.image.url;
		let faceMimeType = base64Image.substring(base64Image.indexOf(':') + 1, base64Image.indexOf(';'))
		let base64 = base64Image.substr(base64Image.indexOf(',') + 1);
		dto.face = base64;
		dto.faceMimeType = faceMimeType;
	}
	dto.draft = false;
    try {
        return rest.update('id-mtlb', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Created ID Card Application`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const rejectReadyIdCardApplicationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'id-mtlb/ready/reject', 'POST', id).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Rejected Id Card Application`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const approveReadyIdCardApplicationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'id-mtlb/ready/approve', 'POST', id).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Approved Id Card Application`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const deleteIdCardApplicationForm = async (id) => {
    try {
        return rest.delete('id-mtlb', id);
    } catch (err) {
        alert(err);
    }
}
export const idCardContent =
    {  
        mainTitle: "Nauru National ID Card",
        mainSubTitle: "Carte d'identité nationale de Nauru",
    };

	export const form2dto = (formData, dto) => {
		if (!formData.image.isEmpty) {
			let base64Image = formData.image.url;
			let faceMimeType = base64Image.substring(base64Image.indexOf(':') + 1, base64Image.indexOf(';'))
			let base64 = base64Image.substr(base64Image.indexOf(',') + 1);
			dto.face = base64;
			dto.faceMimeType = faceMimeType;
		}
	}

	export const dto2form = (dto) => {
		let form = dto;
		let face = null;
		if (dto.face != null) {
			let mimeType = dto['faceMimeType'];
			face = "data:".concat(mimeType, ";base64,", dto.face)      
		}
		form['image'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (face != null)?face: '/public/avatar.png', isEmpty: (face == null)};
		
		
		if (form['mtlbStatus'] == MTLB_STATUS_ARCHIVED) {

			let vitalRecordId = form['vitalRecordId'];
			rest.read('vital-record', vitalRecordId).then(async print => {
				print['image'] = form['face'];
				let address = null;
					try 
					{
						address =  await buildAddressData({},vitalRecordId);
					} 
					catch (error) 
					{	
					}		
					print['address'] = address; 
				setContextualOptions({
				"id-database": {
					submenu: {
						"cr-form": {
							options: {
								"print-id": { 
									label: "Print", do:printIdCard(print)
								},
							}
						}
					}
				}
			})
		})
		}
		form['leftThumbImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (getFormFinger(dto.leftThumb) != null)?getFormFinger(dto.leftThumb): '/public/finger-not-found.png', isEmpty: true, width:70, height:70}
		form['leftIndexFingerImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (getFormFinger(dto.leftIndexFinger) != null)?getFormFinger(dto.leftIndexFinger): '/public/finger-not-found.png', isEmpty: true, width:70, height:70}
		form['leftMiddleFingerImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (getFormFinger(dto.leftMiddleFinger) != null)?getFormFinger(dto.leftMiddleFinger): '/public/finger-not-found.png', isEmpty: true, width:70, height:70}
		form['leftRingFingerImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (getFormFinger(dto.leftRingFinger) != null)?getFormFinger(dto.leftRingFinger): '/public/finger-not-found.png', isEmpty: true, width:70, height:70}
		form['leftPinkyImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (getFormFinger(dto.leftPinky) != null)?getFormFinger(dto.leftPinky): '/public/finger-not-found.png', isEmpty: true, width:70, height:70}
		form['rightThumbImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (getFormFinger(dto.rightThumb) != null)?getFormFinger(dto.rightThumb): '/public/finger-not-found.png', isEmpty: true, width:70, height:70}
		form['rightIndexFingerImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (getFormFinger(dto.rightIndexFinger) != null)?getFormFinger(dto.rightIndexFinger): '/public/finger-not-found.png', isEmpty: true, width:70, height:70}
		form['rightMiddleFingerImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (getFormFinger(dto.rightMiddleFinger) != null)?getFormFinger(dto.rightMiddleFinger): '/public/finger-not-found.png', isEmpty: true, width:70, height:70}
		form['rightRingFingerImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (getFormFinger(dto.rightRingFinger) != null)?getFormFinger(dto.rightRingFinger): '/public/finger-not-found.png', isEmpty: true, width:70, height:70}
		form['rightPinkyImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (getFormFinger(dto.rightPinky) != null)?getFormFinger(dto.rightPinky): '/public/finger-not-found.png', isEmpty: true, width:70, height:70}
		return form;
	}

	export const newIdCardApplicationFormForm2Dto =  (formData, dto) => {
		dto.mtlbType = MTLB_TYPE_ID_CARD;
		dto.vitalRecordId = formData.id;
		dto.id = null;
		dto.face = null;
		dto.faceMimeType = null;
		formData.image.isEmpty = true;
	}

	const getFormFinger = (fingerData) => {
		let finger = null;
		if (fingerData !== null) {
			let mimeType = "image/png"
			finger = "data:".concat(mimeType, ";base64,", fingerData)
		}
		return finger;
	}