import * as React from 'react';
import QRCode from "react-qr-code";
import {t} from "../services";
import {idCardContent} from "../../../main/js/forms/idCardApplication/IdCardApplicationFormCommon";
import { geoDataMetadataLoader } from '../metadata/GeoDataMetadataLoader';

const formatDate = (date) => {
let d = new Date(date[0], date[1]-1, date[2]);
return d.toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
}

export class IdCard extends React.Component {
constructor(props) {
super(props);
this.data = props.data;
}

// @ts-ignore
render() {
let birthdate = this.data.birthdate? formatDate(this.data.birthdate):'Unknown';
var currentdate = new Date();
var datetime = currentdate.getDate() + "/"
+ (currentdate.getMonth() + 1) + "/"
+ currentdate.getFullYear() + " @ "
+ currentdate.getHours() + ":"
+ currentdate.getMinutes() + ":"
+ currentdate.getSeconds();
var expiredate = currentdate.getDate() + "/"
+ (currentdate.getMonth() + 1) + "/"
+ (currentdate.getFullYear()+ 5);

let fullname = '';
fullname += this.data.firstname ? this.data.firstname + ' ' : '';
fullname += this.data.secondname ? this.data.secondname + ' ' : '';
fullname += this.data.fourthname ? this.data.fourthname + ' ' : '';
let address = [];
if (this.data.address) {
	let addressComponents = this.data.address.split(".");
	let place = this.data.address;
	address.push(
		(geoDataMetadataLoader.getArea(place))?t(geoDataMetadataLoader.getArea(place).name + " "):t("INVALID DATA ")
	)
	for (let i = 0; i < addressComponents.length - 1 ; i ++) {
		let parentAreaId = place.substring(0, place.lastIndexOf("."));
		place = parentAreaId;
		address.push(
			(geoDataMetadataLoader.getArea(parentAreaId))?t(geoDataMetadataLoader.getArea(parentAreaId).name + " "):t("INVALID DATA")
		)                
	}
}
else
	address.push(<p>{t`Unknown`}</p>);

return (
<div class="print-page">

	<div class="page">
		<div class="header">
			<div class="passport-photo">
				<img src={`data:image/jpeg;base64,${this.data.image}`}  width="100%" height="100%" alt=""/>
			</div>
			<div class="">
				<QRCode value={fullname + "\nCertificate issued on " + datetime + ' - Signed by Director Andy Calo'} size={70}/>
			</div>
		</div>
		<div class="pattern">
		</div>
		<div class="bodycontent">
			<h1>{idCardContent.mainTitle}</h1>
			<h2 class="title">{idCardContent.mainSubTitle}</h2>
			<h2 class="id-number">{this.data.id}</h2>
			<h3 class="name">{fullname}</h3>
			<p class="dob">{birthdate}</p>
			<p class="address">
				{address}
			</p>
			<p class="dob">{t`Document ID`}: {this.data.documentId}</p>
			<p class="dob">{t`Expires:`} {expiredate}</p>
		</div>
	</div>
</div>
)
}
}