import React from "react";
import { getServiceUri } from "../../../../auto/js/metadata";
import { rest } from "../../../../auto/js/services";
import { loadPersonData } from "../../utils"
import { AdvancedSearchPersonComponent } from "../../widgets/AdvancedSearchPersonComponent"

export const registrarFields = [
    {name: "vitalRecordId", type: "custom", x:1, y: 1, layout:"col-md-12", component: (name, disabled) => <AdvancedSearchPersonComponent name={name} label={"Search Record"} readOnly={disabled} loadData={loadPersonData}/>},
	{name: "signature", type: "file", x:1, y:3, layout:"col-md-12", 
		uploadUrl: (id) => getUploadUrl(id),
		previewUrl: (id) => getPreviewUrl(id),
		loadData: async (id) => loadSignatureAttachment(id), 
		handleDelete:(id) => handleSignatureDelete(id), 
		handleClick: (id) => handleSignatureClick(id),
		updateFileData: (data) => updateFileData(data),
		label: "Signature"
	},
]

const getUploadUrl = (id) => {
	return getServiceUri() + 'registrar/signature' + '/' + id;
}

const getPreviewUrl = (id) => {
	return getServiceUri() + 'registrar/signature' + '/preview/' + id;
}

const updateFileData = (data) => {
	let filter = {name: data.fileName, description: data.description};
	rest.request(getServiceUri() + 'registrar/signature' + '/' + data.id, "PUT", filter);
}

const handleSignatureClick = async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	window.location = getServiceUri() + 'registrar/signature' + '/' + id + '/' + token;
};

const handleSignatureDelete = (id) => {
	rest.delete('registrar/signature', id).then(() => {
		DELETE_ATTACHMENT_EVENT.publish(id)
	});
};

const loadSignatureAttachment = async (id) => {
	let filter = {and: true};
	filter['registrar-signature'] = {};
	filter['registrar-signature']['registrarId'] = id;
	return rest.search('registrar/signature', filter)
}

export const form2dto = (form, dto) => {

}

export const dto2form = (dto) => {
    return dto;
}
