import React from 'react';
import { formState } from '../../../auto/js/forms/FormState';
import { loadDeathRegistrationFormData } from '../forms/deathRegistration/DeathRegistrationFormCommon';
import { rest } from '../../../auto/js/services';
import { getServiceUri } from '../../../auto/js/metadata';
import { displayReadDeathRecordForm } from '../../../auto/js/forms/DeathRecordForm';


export const process = (manager) => {
    loadDeathRegistrationFormData(formState.getState().civilStatusMtlbId).then((response) => {
        let filter = {and: true};
        filter['death-record-document'] = {vitalRecordId: response.vitalRecordId};
        rest.request(getServiceUri() + '/' + 'death-record/search-document/', 'POST', filter).then(dr => {
            if (dr.length)
                manager.gui.goTo(displayReadDeathRecordForm(manager.complete), dr[0].id, null)
        })
    })
}