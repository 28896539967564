import React from "react";
import { Chip, TextField, Autocomplete } from '@mui/material';
import RemoveCircleOutlineSharp from '@mui/icons-material/RemoveCircleOutlineSharp';
import { useField } from "formik";

/**
 * Transforms the input as described in the api to the form required by the
 * GUI components
 * 
 * @param allOptions The options object as provided to the Widget
 * @param list The list of IDs of the options displayed as chips
 * @returns An array of objects in the form {id: number, label: str}
 *    that stores the options that are referenced in the list
 */
function preprocessInput(allOptions, list) {
    let options = [];

    if (list != null)
        list = list.map((elem) => String(elem));
    else
        list = [];
    for (const [key, value] of Object.entries(allOptions)) {
      if (!list.includes(key)) {
        options.push({
            "id": parseInt(key),
            "label": value
        });
      }
    }

    return options;
}

/**
 * 
 * @param options object in the form {id1: label1, id2: label2...} representing all possible options
 * for the auto complete
 * @param props must contain a "name" property
 */
function AutocompleteListSelector(props) {
    const [field, meta, helpers] = useField(props.name);

    const value = meta.value;
    const setValue = helpers.setValue;

    return (
        <div>
           <Autocomplete
            className={props.layout}
            id="tags-standard"
            readOnly={props.readOnly}
            filterSelectedOptions
            options={preprocessInput(props.options, value)}
            getOptionLabel={option => option.label}
            onChange={(e, newValue) => {
              setValue([...value, newValue.id]);
            }}
            aria-label={props.label}
            renderTags={() => {}}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                placeholder={props.label}
                margin="normal"
                fullWidth
              />
            )}
          />
          <div className="selected-tags">
            {
              value.map((obj, index) => {
                return <Chip
                    key={index}
                    label={props.options[obj]}
                    deleteIcon={<RemoveCircleOutlineSharp />}
                    onDelete={() => {
                      const updatedChips = value.filter(entry => entry !== obj);
                      setValue(updatedChips);
                    }}
                />
              })
            }
          </div>
        </div>
      )
}

export default AutocompleteListSelector;