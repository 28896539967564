import React from "react";
import {v4 as uuidv4} from 'uuid';
import { createFormComponent } from '../../../auto/js/widgets/FormComponent';
import { rest } from "../../../auto/js/services";
import { showNotification } from "../../../auto/js/utils";
import { certificateStyle } from "./MarriageCertificateStyleObj";
import { divorceCertificateStyle } from "./DivorceCertificateStyleObj";

import { Section } from "../widgets/Section";
import { PersonComponent } from "../../../auto/js/widgets/PersonComponent";
import { loadPersonData } from "../utils";
import { GeoDataComponent } from "../../../auto/js/widgets/GeoDataComponent";
import { setContextualOptions } from "../../../auto/js/widgets";
import { MarriageCertificate } from "./MarriageCertificate";
import { PRINT_EVENT } from "../../../auto/js/events/Gui";
import { DivorceCertificate } from "./DivorceCertificate";
import { getServiceUri } from "../../../auto/js/metadata";

const gender = {1:"MALE", 2:"FEMALE"};
const maritalStatus = {1:"SINGLE", 2:"MARRIED", 3:"DIVORCED", 4:"WIDOWED"};
const regime = {1:"Civil Marriage", 2:"Religious Marriage", 3: "Custom Marriage"};
	
const fields = [
    {name: "tags", type:"tags", x:1, y:1, layout:"col-md-12"},
    {name: "id", type: "number", x:1, y:2, layout:"col-md-12"},
    {name: "marriageDetails", type: "custom", x:1, y:3, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
    {name: "declaredDate", type: "date", x:1, y: 4, layout:"col-md-6"},
    {name: "timeOfMarriage", type: "time", x:2, y:4, layout:"col-md-6"},
    {name: "rites", type: "text", x:1, y: 5, layout:"col-md-6"},
    {name: "address", type: "custom", x:1, y:6, layout:"col-md-12", component: (name, disabled) => <GeoDataComponent name={name} disabled={disabled}/>},
    {name: "wifeDetails", type: "custom", x:1, y:7, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
    {name: "partner2VitalRecordId", type: "custom", x:1, y:8, layout:"col-md-12", component: (name, disabled) => <PersonComponent name={name} readOnly={disabled} loadData={loadPersonData}/>},
    {name: "husbandDetails", type: "custom", x:1, y:10, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
    {name: "partner1VitalRecordId", type: "custom", x:1, y:11, layout:"col-md-12", component: (name, disabled) => <PersonComponent name={name} readOnly={disabled} loadData={loadPersonData}/>},
    {name: "witnessDetails", type: "custom", x:1, y:13, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
    {name: "firstWitnessId", type: "custom", x:1, y:14, layout:"col-md-12", component: (name, disabled) => <PersonComponent name={name} readOnly={disabled}loadData={loadPersonData}/>},
    {name: "secondWitnessId", type: "custom", x:1, y:16, layout:"col-md-12", component: (name, disabled) => <PersonComponent name={name} readOnly={disabled} loadData={loadPersonData}/>},
    {name: "dateOfEffect", type: "date",  x:1, y:17, layout:"col-md-6"},
    {name: "dateOfOrder", type: "date",  x:2, y:17, layout:"col-md-6"},
    {name: "Extras", type: "custom", x:1, y:19, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
    {name: "notes", type: "text", x:1, y:20, layout:"col-md-12"}
	];

export const displayUnionRecordForm = (onFinish) => (id) => {
	let UnionRecordForm = createFormComponent(fields);
	let uuid = uuidv4();
	return {
		uuid, view: () => <UnionRecordForm key={uuid} loadData={async () => loadFormData(id)} readOnly={true} buttons={() => null}/>
	};
}

const loadFormData = async (id) => {
    let token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
    return await rest.read('union-record', id).then(response => {
		let form = response;
        if (form.timeOfMarriage !== null)
            form.timeOfMarriage = new Date().setHours(form.timeOfMarriage[0], form.timeOfMarriage[1]);
		if (response.status)
            showNotification(response.message.split('Detail: ')[1], "error");
            let tagFilter = {and: true};
            tagFilter['union-record-tag'] = {unionRecordId: id};
        if (response.dateOfEffect != null) {
            rest.read('divorce-certificate/read', id).then(async data => {
                setContextualOptions({
                "marriage-records": {
                    submenu: {
                        other: {
                            options: {
                                "marriage-certificate": { 
                                    label: "Certificate", do: printDivorceCertificate(data, token)
                                },
                            }
                        }
                    }
                }
            })});
        } 
        else
            rest.read('marriage-certificate/read', id).then(async data => {
                setContextualOptions({
                "marriage-records": {
                    submenu: {
                        other: {
                            options: {
                                "marriage-certificate": { 
                                    label: "Certificate", do: printMarriageCertificate(data)
                                },
                            }
                        }
                    }
                }
            })});
        return rest.search('union-record-tag', tagFilter).then(tags => {
            form['tags'] = tags
            if (tags.status)
                showNotification(response.message.split('Detail: ')[1], "error");
            return form;
        })
	})
}

const printMarriageCertificate = (data) => () => {
	const printable = {
				content:<MarriageCertificate data={data}/>,
				style:{certificateStyle}.certificateStyle,
				copyParentStyle:false
	}
	PRINT_EVENT.publish(printable);
}

const printDivorceCertificate = (data, token) => () => {
    if (data.registrarSignatureId != null)
		data.signatureUrl = getServiceUri() + 'registrar' + '/signature/' + data.registrarSignatureId + '/' + token;
	const printable = {
				content:<DivorceCertificate data={data}/>,
				style:{divorceCertificateStyle}.divorceCertificateStyle,
				copyParentStyle:false
	}
	PRINT_EVENT.publish(printable);
}
