import React, { useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { MTLB_STATUS_ARCHIVED, MTLB_STATUS_ASSIGNED, MTLB_STATUS_MANUAL_CHECK, MTLB_STATUS_NOT_ASSIGNED, MTLB_STATUS_READY_FOR_APPROVAL } from "../../../../auto/js/metadata/MtlbStatus";
import { MTLB_TYPE_DIGITALIZATION } from "../../../../auto/js/metadata/MtlbType";
import { OPEN_VIEW_EVENT } from "../../../../auto/js/events/Gui";
import { getServiceUri, pojoMetadata } from "../../../../auto/js/metadata";
import { rest, t } from "../../../../auto/js/services";
import { displayReadDigitalizationSearchForm } from "../../forms/digitalizationApplication/DigitalizationSearchForm";
import { createTableComponent } from "../../../../auto/js/widgets/TableComponent";
import { CIVIL_STATUS_MTLB_ORDER_BY_ID } from "../../../../auto/js/metadata/CivilStatusMtlbOrderBy";
import Facets from "../../../../auto/js/widgets/Facets";
import { Checkbox, FormControlLabel } from "@mui/material";
import { displayReadApprovedDigitalizationApplicationForm } from "../../forms/digitalizationApplication/ApprovedDigitalizationApplicationForm";
import { displayReadReadyDigitalizationApplicationForm } from "../../forms/digitalizationApplication/ReadyDigitalizationApplicationForm";
import { displayReadPendingDigitalizationApplicationForm } from "../../forms/digitalizationApplication/PendingDigitalizationApplicationForm";
import { whoami } from "../../../../auto/js/users/UserInfo";

const fields = [
    { title: "formId", field: "id" },
    { title: "firstname", field: "firstname" },
    { title: "secondname", field: "secondname" },
    { title: "thirdname", field: "thirdname" },
    { title: "fourthname", field: "fourthname" },
    { title: "birthdate", field: "birthdate" }
];

const DigitalizationListPage = ({ uuid, gui }) => {
    const [approved, setApproved] = useState(false);
    const [assigned, setAssigned] = useState(false);
    const [unassigned, setUnassigned] = useState(false);
    const [ready, setReady] = useState(false);
    const [pending, setPending] = useState(false);
    const [selectAllStatus, setSelectAllStatus] = useState(false);
    let DigitalizationList = createTableComponent(fields);

    const onFacetChange = (key, value) => {
        switch (key) {
            case ("approved"):
                setApproved(value.target.checked);
                break;
            case ("assigned"):
                setAssigned(value.target.checked);
                break;
            case ("unassigned"):
                setUnassigned(value.target.checked);
                break;
            case ("ready"):
                setReady(value.target.checked);
                break;
            case ("pending"):
                setPending(value.target.checked);
                break;
            case ("selectAllStatus"):
                setSelectAllStatus(value.target.checked);
                setApproved(value.target.checked);
                setAssigned(value.target.checked);
                setUnassigned(value.target.checked);
                setReady(value.target.checked);
                setPending(value.target.checked);
                break;

        }
    }

    const buildData = async (query) => {
        let filter = query;
        let data;
        let mtlbStatusList = [];
        if (approved)
            mtlbStatusList.push(MTLB_STATUS_ARCHIVED);
        if (assigned)
            mtlbStatusList.push(MTLB_STATUS_ASSIGNED);
        if (ready)
            mtlbStatusList.push(MTLB_STATUS_READY_FOR_APPROVAL);
        if (unassigned)
            mtlbStatusList.push(MTLB_STATUS_NOT_ASSIGNED);
        if (pending)
            mtlbStatusList.push(MTLB_STATUS_MANUAL_CHECK);
        if (!approved && !assigned && !ready && !unassigned && !pending)
            mtlbStatusList = [MTLB_STATUS_ARCHIVED, MTLB_STATUS_ASSIGNED, MTLB_STATUS_READY_FOR_APPROVAL, MTLB_STATUS_NOT_ASSIGNED, MTLB_STATUS_MANUAL_CHECK]
        filter["civil-status-mtlb"] = { mtlbStatusList: (whoami().roles.includes('REGISTRAR') || whoami().roles.includes('ALL')) ? mtlbStatusList : [MTLB_STATUS_ASSIGNED], mtlbTypeList: [MTLB_TYPE_DIGITALIZATION] };
        filter["orderBy"] = CIVIL_STATUS_MTLB_ORDER_BY_ID;
        filter.orderDirection = null;
        filter.offset = query.page * query.pageSize;
        if (query.search && query.search != '') {
            pojoMetadata["civil-status-mtlb"].columns.forEach(element => {
                if (element.type == 'text') {
                    filter["civil-status-mtlb"][element.key] = query.search;
                }
            });
            filter["and"] = false;
        }
        return await getCivilRecordData(filter).then(response => {
            data = filterData(response);
            return countData(filter).then((count) => { return { data: data, totalCount: count, page: query.page } })
        });
    }

    const buildFacets = (key, value, label, handleChange) => {
        return (
            <>
                <FormControlLabel
                    control={<Checkbox
                        checked={value}
                        onChange={(event) => handleChange(key, event)}
                        color="primary"
                        value={value}
                    />
                    }
                    label={label}
                    style={{ width: '100%' }}
                />
            </>
        )
    }

    return (
        <>
            {(whoami().roles.includes('REGISTRAR') || whoami().roles.includes('ALL')) ?
                <div>
                    <Facets title={t`Application Status`} facets={[{ key: "selectAllStatus", value: selectAllStatus, separator: true }, { key: "approved", value: approved }, { key: "assigned", value: assigned }, { key: "unassigned", value: unassigned }, { key: "ready", value: ready }, { key: "pending", value: pending }]} facetsComponents={(key, value) => buildFacets(key, value, t(key), onFacetChange)} />
                    <DigitalizationList key={uuid} loadData={async (query) => buildData(query)} actions={getTableActions(gui)} />
                </div>
                : <DigitalizationList key={uuid} loadData={async (query) => buildData(query)} actions={getTableActions(gui)} />
            }
        </>

    )
}

export const displayDigitalizationApplicationList = (gui) => {
    const readDigitalizationApplicationList = () => () => {
        let uuid = uuidv4();
        return {
            uuid, view: () => <DigitalizationListPage key={uuid} gui={gui} />
        };
    }
    gui.goTo(readDigitalizationApplicationList())
}

const getCivilRecordData = async (filter) => {
    if (whoami().roles.includes('REGISTRAR') || whoami().roles.includes('ALL'))
        return await rest.request(getServiceUri() + "civil-status-mtlb/search-by-mtlb-type-and-status/", "POST", filter);
    else if (whoami().roles.includes('DIGITALIZER'))
        return await rest.request(getServiceUri() + "civil-status-mtlb/digitalization/search", "POST", filter);
}

const countData = async (filter) => {
    if (whoami().roles.includes('REGISTRAR') || whoami().roles.includes('ALL'))
        return await rest.request(getServiceUri() + "civil-status-mtlb/count/search-by-mtlb-type-and-status/", "POST", filter);
    else if (whoami().roles.includes('DIGITALIZER'))
        return await rest.request(getServiceUri() + "civil-status-mtlb/digitalization/count", "POST", filter);
}

const getTableActions = (gui) => {
    let actions = [];
    actions.push(
        {
            icon: () => <VisibilityIcon />,
            tooltip: t`View`,
            onClick: (event, rowData) => {
                switch (rowData.mtlbStatus) {
                    case (MTLB_STATUS_ARCHIVED):
                        displayReadApprovedDigitalizationApplicationForm(gui, rowData.id);
                        break;
                    case (MTLB_STATUS_READY_FOR_APPROVAL):
                        displayReadReadyDigitalizationApplicationForm(gui, rowData.id);
                        break;
                    case (MTLB_STATUS_ASSIGNED):
                        displayReadDigitalizationSearchForm(gui, rowData.id);
                        break;
                    case (MTLB_STATUS_NOT_ASSIGNED):
                        displayReadDigitalizationSearchForm(gui, rowData.id);
                        break;
                    case (MTLB_STATUS_MANUAL_CHECK):
                        displayReadPendingDigitalizationApplicationForm(gui, rowData.id);
                        break;
                }
            }
        }
    )
    return actions;
}

export const filterData = (DefaultRows) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        let date = row.birthdate
        if (date !== null)
            row.birthdate = date[2] + '-' + date[1] + '-' + date[0];
        newRows.push(row);
    }
    return newRows;
}