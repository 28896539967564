import React from "react";

import { GeoDataComponent } from "../../../auto/js/widgets/GeoDataComponent";
import { Separator } from "../forms/CivilRecordFormCommon";

export const getErrorList = () => {
    let list = [];
	list.push("E19");
	list.push("E22");
    return list;
}

export const advancedSearchfields = [
    {name: "exactId", type: "number", x:1, y: 1, layout:"col-md-4"},
    {name: "idSeparator", type: "custom", x:1, y:2, layout:"col-md-12", component: (name, disabled) => <Separator />},
    
    {name: "firstname", type: "text", x:1, y:2, layout:"col-md-6"},
    {name:"firstnameCondition", options: [
        {"name":"fuzzy","label":"Fuzzy"},
        {"name":"exact","label":"Exact"},
        {"name":"startsWith","label":"Startswith"},
    ], type: "radio", x:2, y:2, layout:"col-md-6 pt-5", defaultValue:"fuzzy"},
        
    {name: "secondname", type: "text", x:1, y:3, layout:"col-md-6"},
    {name:"secondnameCondition", options: [
        {"name":"fuzzy","label":"Fuzzy"},
        {"name":"exact","label":"Exact"},
        {"name":"startsWith","label":"Startswith"},
    ], type: "radio", x:2, y:3, layout:"col-md-6 pt-5", defaultValue:"fuzzy"},
    
/*         {name: "thirdname", type: "text", x:1, y: 4, layout:"col-md-6"},
    {name:"thirdnameCondition", options: [
        {"name":"fuzzy","label":"Fuzzy"},
        {"name":"exact","label":"Exact"},
        {"name":"startsWith","label":"Startswith"},
    ], type: "radio", x:2, y:4, layout:"col-md-6 pt-5", defaultValue:"fuzzy"}, */
    
        {name: "fourthname", type: "text", x:1, y: 5, layout:"col-md-6"},
        {name:"fourthnameCondition", options: [
        {"name":"fuzzy","label":"Fuzzy"},
        {"name":"exact","label":"Exact"},
        {"name":"startsWith","label":"Startswith"},
    ], type: "radio", x:2, y:5, layout:"col-md-6 pt-5", defaultValue:"fuzzy"},
    
    {name:"genderBox", label: "Gender", components: [
        {name: "male",label:"Male", type: "checkbox", x:1, y:1, layout:"col-md-3"},
    {name: "female",label:"Female", type: "checkbox", x:2, y:1, layout:"col-md-3"},
    ], type: "box", x:3, y:6, layout:"col-md-11 ms-2 mt-2"},//mx-auto
    {name:"deathBox", label: "Death Date", components: [
        {name: "rangedeathdate",label:"exactdeathdate", type: "checkbox", x:1, y:1, layout:"col-md-12"},
        {name: "exactdeathdate", type: "date", x:1, y: 2, layout:"col-md-6", display: (data)=>{
            return !data.rangedeathdate;
        }},
        
        {name: "deathdateStart", type: "date", x:1, y: 3, layout:"col-md-6",display: (data)=>{
            return data.rangedeathdate;
        }},
        {name: "deathdateEnd", type: "date", x:2, y: 3, layout:"col-md-6", display: (data)=>{
            return data.rangedeathdate;
        }},
    ], type: "box", x:1, y:7, layout:"col-md-11 ms-2 mt-2 pt-1 pb-2"},

    {name:"deathBox", label: "death Place", components: [
        {name: "deathPlace", type: "custom", x:1, y:2, layout:"col-md-6",
            component: (name, disabled) => <GeoDataComponent name={name} disabled={disabled}/>,
            display: (data) => {return !data.anyBornOutsideCountry}
        },
    ], type: "box", x:2, y:9, layout:"col-md-11 ms-2 mt-2 pt-1 pb-2"}
];

export const transformAdvancedSearchData = (formData) => {
    const columns = [];
    
    const addColumn = (name, type, value, condition) => {
        columns.push({
        name,
        type,
        [type === 'date' ? 'dateValue' : (type === 'text' ? 'textValue' : 'longValue')]: value,
        searchType: condition ? condition : null,
        });
    };
    (formData.firstname !== "") && addColumn('firstname', 'text', formData.firstname, formData.firstnameCondition);
    
    (formData.secondname !== "") && addColumn('secondname', 'text', formData.secondname, formData.secondnameCondition);
    
    (formData.thirdname !== "") && addColumn('thirdname', 'text', formData.thirdname, formData.thirdnameCondition);
    
    (formData.fourthname !== "") && addColumn('fourthname', 'text', formData.fourthname, formData.fourthnameCondition);
    
    (formData.exactId !== "") && addColumn('vitalRecordId', 'number', formData.exactId, null);
    (formData.deathPlace) && addColumn('deathPlace', 'text', formData.deathPlace,formData.deathPlace? "startsWith" : null);
    const deathdateRange = formData.deathdateStart && formData.deathdateEnd
        ? {
                min: formData.deathdateStart ? formData.deathdateStart : null,
                max: formData.deathdateEnd ? formData.deathdateEnd : null,
            }
        : null;
    
    (deathdateRange != null || formData.exactdeathdate != null) && addColumn('dateOfDeath', 'date', formData.exactdeathdate, null);
    if ((deathdateRange != null || formData.exactdeathdate != null))
        columns[columns.length - 1].dateRange = deathdateRange;
    
    const gender = [];
    formData.male ? gender.push("1") : null;
    formData.female ? gender.push("2") : null;
    if (gender.length)
        columns.push({
        name: 'gender',
        type: 'enum',
        enumValues: gender,
        });
    
    return { columns };
};