import * as React from 'react';
import QRCode from "react-qr-code";
import {deathCertificateContent} from "./DeathRecordFormCommon.jsx";
import { geoDataMetadataLoader } from '../../../auto/js/metadata/GeoDataMetadataLoader';
import { t } from '../../../auto/js/services';

const formatDate = (date) => {
	if (date != null) {
		let d = new Date(date[0], date[1]-1, date[2]);
		return d.toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"});
	} else
		return t`Unknown`;
}

const formatTime = (time) => {
	if (time !== null) {
		let h = (time[0] < 0)? "0" + time[0] : time[0];
		let m = (time[0] < 0)? "0" + time[1] : time[1];
		return h + ":" + m;
	} else
		return t`Unkown Time`
}

const formatLocation = (location) => {
	let birthPlace = [];
	if (location != null) {
		let birthPlaceComponents = location.split(".");
		let place = location;
		birthPlace.push(
			(geoDataMetadataLoader.getArea(place))?t(geoDataMetadataLoader.getArea(place).name + " "):t("INVALID DATA ")
		)
		for (let i = 0; i < birthPlaceComponents.length - 1 ; i ++) {
			let parentAreaId = place.substring(0, place.lastIndexOf("."));
			place = parentAreaId;
			birthPlace.push(
				(geoDataMetadataLoader.getArea(parentAreaId))?t(geoDataMetadataLoader.getArea(parentAreaId).name + " "):t("INVALID DATA ")
			)                
		}
	}
	else
		birthPlace.push(t`Unknown Place`)
	return birthPlace;
}

export class DeathCertificate extends React.Component {
	constructor(props) {
		super(props);
		this.data = props.data;
	}

	// @ts-ignore
	render() {
		let dateOfDeath = this.data.dateOfDeath? formatDate(this.data.dateOfDeath):'Unknown';
		var currentdate = new Date();
		var datetime = currentdate.getDate() + "/"
			+ (currentdate.getMonth() + 1) + "/"
			+ currentdate.getFullYear() + " @ "
			+ currentdate.getHours() + ":"
			+ currentdate.getMinutes() + ":"
			+ currentdate.getSeconds();
		let fullname = '';
		fullname += this.data.firstname ? this.data.firstname + ' ' : '';
		fullname += this.data.secondname ? this.data.secondname + ' ' : '';
		fullname += this.data.fourthname ? this.data.fourthname + ' ' : '';

		let registrarFullName = '';
		if (this.data.registrarId != null) {
			registrarFullName += this.data.registrarFirstName ? this.data.registrarFirstName + ' ' : '';
			registrarFullName += this.data.registrarFourthName ? this.data.registrarFourthName + ' ' : '';
		}

		let childGender = (this.data.gender == 1)?'MALE':(this.data.gender == 2)?'FEMALE': t`Unknown`;
		let children = [];
		if (this.data.children != null)
			this.data.children.forEach(child => {
				children.push(
					<>{child.name} {child.gender} {child.age} <br/></> 
				)
			});
		return (

			<div className="page">
				<div className="header">
					<div className="headercol1">
						<h2 className="lefttext"> {deathCertificateContent.leftTitle1}<br/>
							{deathCertificateContent.leftTitle2}</h2></div>
					<div className="headercol2 logos">

					</div>
					<div className="headercol3">
						<h2 className="righttext">{deathCertificateContent.rightTitle1}<br/>{deathCertificateContent.rightTitle2}</h2>
					</div>
					<div className="headercol4">

					</div>
					<div className="headercol5">
						<h1>{deathCertificateContent.mainTitle}</h1>
						<h2>{deathCertificateContent.mainSubTitle}</h2>
					</div>
					{/* <div className="headercol6">
						<h2 className="righttext idnumber1">{t`Death ID Number`}: {this.data.id}</h2>
					</div> */}
				</div>
				<div className="bodycontent">
					<div class="bodyrow">
						<h1 class="lefttext" style={{margin:"4mm 0 0 0"}}>Death details:</h1>
						<p class="bottom" style={{margin:"0 0 4mm 0"}}><span class="details"><strong>Date: </strong>{dateOfDeath}</span> <span  class="details"><strong>Time: </strong>{formatTime(this.data.timeOfDeath)} </span><span class="details"><strong>Place: </strong>{formatLocation(this.data.deathPlace)}</span><span class="details"><strong>Certificate Number: </strong>{this.data.id}</span></p>
					</div>
					<div class="bodycol1">
			<h1 class="lefttext" style={{marginBottom:"0px"}}>Deceased</h1>
			<div class="nestedcol1">
			  <p>Family Name<br/>
				First Name(s)<br/>
				Other Name<br/>
				Sex<br/>
				National ID Number
				</p>
				
				<p>
				Date of birth <br/>
				Location of Birth<br/>
					&nbsp;<br/>
				&nbsp;<br/>
				Occupation<br/>
				</p>
				<p>
				Usual Residence<br/>
				&nbsp;<br/>
				&nbsp;<br/>
				</p>
				<p>
				Mother's Name<br/>
				Father's Name<br/>
				</p>
			</div>
			<div class="nestedcol2">
				<p>{this.data.fourthname}<br/>
				{this.data.firstname}<br/>
				{(this.data.thirdname !== null)?this.data.thirdname: " "}<br/>
				{childGender}<br/>
				{this.data.vitalRecordId}
				</p>
				
				<p>
				{formatDate(this.data.birthdate)} <br/>
				{formatLocation(this.data.birthPlace)}<br/>
				&nbsp;<br/>
				{(this.data.occupation != null)?this.data.occupation:" "}<br/>
				</p>
				<p>
				{formatLocation(this.data.address)}<br/>
				&nbsp;<br/>
				</p>
				<p>
				{(this.data.motherName !== null)?this.data.motherName: " "}<br/>
				{(this.data.fatherName !== null)?this.data.fatherName: " "}<br/>
				</p>
			</div>
			 </div>
			 
		<div class="bodycol2">
			<h1 class="lefttext">Family of deceased</h1>
			<div class="nestedcol1">
			  <p>Name of Spouse<br/>
				National ID Number
				</p>
			  <p>Date of Marriage<br/>
				</p>
				<p>
				Location of Marriage<br/>
					&nbsp;<br/>
				&nbsp;<br/>
				</p>
				<p>
				Surviving Children
			  </p>

		  </div>
			<div class="nestedcol2">
				<p>{(this.data.spouseName !== null)?this.data.spouseName: " "}<br/>
				{(this.data.spouseId !== null)?this.data.spouseId: " "}<br/>
				</p>
				<p>
				{formatDate(this.data.marriageDate)}<br/>
				</p>
				<p>
				{(this.data.marriageLocation !== null)?this.data.marriageLocation: " "}<br/>
				&nbsp;<br/>
				&nbsp;<br/>
				</p>
				<p>
				{children}
				</p>
				
		  </div>
			 </div>
		<div class="bodycol3">
			<h1 class="lefttext">Declaration</h1>
			{(this.data.declarant)?
				<>
				<div className="nestedcol1">
				<p>{t`Full Name`}<br/>
					{t`National ID Number`}<br/>
					{t`Date`}<br/>
					{t`Place`}<br/>
				</p>
				<p>
					{t`Officer`}<br/>
					{t`Position`}<br/>
				</p>
			</div>
			<div className="nestedcol2">
				<p> {declarantFullName} <br/>
					{this.data.declarantId}<br/>
					{declarantDate}<br/>
					<br/>
				</p>
				<p>
					<br/>
					<br/>
				</p>
			</div>
			</>:
			<p>{t`No Declarant Found`}<br/></p>}
			
		</div>
	  <div class="bodycol4">

			  <h3>Remarks</h3>
			  <p class="bottom">
				</p>
		   </div>
		
		<div class="bodycol6">
		  <div class="official">
				<div class="qr-code"><QRCode value={fullname + "\nCertificate issued on " + datetime + " - Signed by Director " + registrarFullName} size={80}/></div>
				<img src={this.data.signatureUrl}/>
			</div>
		  <p class="lefttext idnumber1">Certified to be a true copy issued without alteration at {"Nauru"} on {datetime}</p><p style={{marginTop:"3.5mm", lineHeight:"10pt"}}> <strong>{registrarFullName}<br/>Registrar General</strong></p>
		
        </div>
				</div>
			</div>

		)
	}
}