import { pojoMetadata } from "../../../auto/js/metadata";
import { EMAIL_ORDER_BY_EMAIL } from "../../../auto/js/metadata/EmailOrderBy";
import { rest } from "../../../auto/js/services";

export const emailFields = [
    {title: "email", field: "email"}
]

export const buildEmailData = async (query, id) => {
    let filter = query;
    let data;
    filter["email"] = {civilStatusMtlbId: id};
    filter["orderBy"] = EMAIL_ORDER_BY_EMAIL;
    filter.orderDirection = null;
    filter.offset = query.page * query.pageSize;
    if (query.search && query.search!='') {
        pojoMetadata["email"].columns.forEach(element => {
            if(element.type=='text'){
                filter["email"][element.key]= query.search;
            }
        });
        filter["and"] = false;
    }
    return await getEmailData(filter).then(response => {
        data = response;
        return countEmailData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
    });
}

export const getEmailData = async (filter) => {
    return await rest.search('email', filter)
}

export const countEmailData = async (filter) => {
        return await rest.count('email', filter);
}

export const getEmailEditables = (id) => {
    let editables = {}
    editables.onRowAdd = newData =>
        new Promise((resolve, reject) => {
            let dto = pojoMetadata['email'].form2dto(newData);
            dto["civilStatusMtlbId"] = id;       
            try {
                return rest.create('email', dto).then(() => resolve());
            } catch (err) {
                alert(err);
            }        
        })
    editables.onRowDelete = oldData =>
        new Promise((resolve, reject) => {
            try {
                return rest.purge('email', oldData.id).then(() => resolve());
            } catch (err) {
                alert(err);
            }   
        })
    return editables;
}