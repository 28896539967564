import React from "react";
import { getServiceUri } from "../../../../auto/js/metadata";
import { ID_MTLB_ORDER_BY_VITAL_RECORD_ID } from "../../../../auto/js/metadata/IdMtlbOrderBy";
import { MTLB_TYPE_ID_CARD } from "../../../../auto/js/metadata/MtlbType";
import { rest } from "../../../../auto/js/services";


export const idCardApplicationListColumns = [
    {title:"image", field:"image",  render: rowData => <img src={rowData.image} onError={(e)=>{e.target.onerror = null; e.target.src="/public/avatar.png"}} style={{width: 40, borderRadius: '50%', height: 40}}/>},
    {title: "vitalRecordId", field: "vitalRecordId"},
    {title: "firstname", field: "firstname"},
    {title: "secondname", field: "secondname"},
    // {title: "thirdname", field: "thirdname"},
    {title: "fourthname", field: "fourthname"},
    {title: "mtlbLocation", field: "mtlbLocation"},
];

export const getData = async (filter) => {
    return await rest.request(getServiceUri() + "id-mtlb/search-by-id-mtlb-type-and-status/", "POST", filter);
}

export const countData = async (filter) => {
    return await rest.request(getServiceUri() + "id-mtlb/count/search-by-id-mtlb-type-and-status/", "POST", filter);
}

export const getOrderBy = () => {
    return ID_MTLB_ORDER_BY_VITAL_RECORD_ID
}

export const getMtlbTypeList = () => {
    return [MTLB_TYPE_ID_CARD]
}

export const filterData = (DefaultRows, token) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        let face = null;
        /* if (row.face != null) {
			let mimeType = row['faceMimeType'];
			face = "data:".concat(mimeType, ";base64,", row.face);		
		} */
        const imageUrl = getServiceUri() + 'id-mtlb/face/' + row.id + "/" + token;
        row.image = (imageUrl != null)?imageUrl:"/public/avatar.png";
      newRows.push(row);
    }
    return newRows;
}

export const getErrorList = () => {
    let list = [];
    list.push("E19");
    list.push("E22");
    return list;
}