import React from "react";
import { v4 as uuidv4 } from 'uuid';
import { t } from "../../../auto/js/services";
import { StepperBrowser } from "../../../auto/js/browsers/StepperBrowser";

import { ValidateAddressTask } from "../tasks/ValidateAddressTask";
import { IdCardApplicationTask } from "../tasks/IdCardApplicationTask";
import { IdCardServerApprovalTask } from "../tasks/IdCardServerApprovalTask";
import { IdCardApproveApplicationTask } from "../tasks/IdCardApproveApplicationTask";
import { IdCardApplicationRgistrationTask } from "../tasks/IdCardApplicationRgistrationTask";
import { PrintIdCardTask } from "../tasks/PrintIdCardTask";

export const displayIdCardApplicationManager = (gui, args) => {
  let steps = [
    t`ValidateAddress`,
    t`IdCardApplication`,
    t`IdCardServerApproval`,
    t`IdCardApproveApplication`,
    t`IdCardApplicationRgistration`,
    t`PrintIdCard`,
  ];

  class IdCardApplicationManagerStepperBrowser extends StepperBrowser {
    constructor(props) {
      super(props)
      this.tasks.push(new ValidateAddressTask(0, steps[0], this, args));
      this.tasks.push(new IdCardApplicationTask(1, steps[1], this, args));
      this.tasks.push(new IdCardServerApprovalTask(2, steps[2], this, args));
      this.tasks.push(new IdCardApproveApplicationTask(3, steps[3], this, args));
      this.tasks.push(new IdCardApplicationRgistrationTask(4, steps[4], this, args));
      this.tasks.push(new PrintIdCardTask(5, steps[5], this, args));
      this.start();
    }

  }

  const createIdCardApplicationManagerFlowBrowser = (gui) => {
    let uuid = uuidv4();
    return {
        uuid,
        view: () => <IdCardApplicationManagerStepperBrowser gui={gui} steps={steps} welcome={() => <></>}/>
    }
  }
  gui.goTo(createIdCardApplicationManagerFlowBrowser, null, null);
}