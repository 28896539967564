import * as React from 'react';
import { formatDateTime } from "../utils";
import { Button } from '@material-ui/core';

function sortDates(a,b){
    var dateA = a.timestamp !== null
        ? new Date(a.timestamp[0], a.timestamp[1]-1, a.timestamp[2], a.timestamp[3], a.timestamp[4], (a.timestamp[5])?a.timestamp[5]:"0").getTime()
        : new Date(1970, 1, 1, 0, 0, 0, 0).getTime();

    var dateB = b.timestamp !== null
        ? new Date(b.timestamp[0], b.timestamp[1]-1, b.timestamp[2], b.timestamp[3], b.timestamp[4], (b.timestamp[5])?b.timestamp[5]:"0").getTime()
        : new Date(1970, 1, 1, 0, 0, 0, 0).getTime();

	return dateA < dateB ? 1 : -1;  
}; 

export class HistoryGrid extends React.Component {
	constructor(props) {
		super(props);
		this.list = props.list;
	}

    openPersonTab = (id) => {
     	var currentUrl = window.location.href;
        var url;
        if (currentUrl.includes("#")) {
            var parts = currentUrl.split("#");
            url = parts[0] + '#' + 'viewvrh:id=' + id;
        } 
        else
            url = window.location.hash + '#' + 'viewvrh:id=' + id;
        window.open(url, '_blank');
    }

	history = (data) => {
		let timestamp = data.timestamp? formatDateTime(data.timestamp):'Unknown';
	return (
        <Button className="link-button" onClick={() => this.openPersonTab(data.hsno)}><tr><td><b> {timestamp}</b></td></tr></Button>
		)
	}

	render() {
		let rows = [];
		this.list.sort(sortDates).forEach(element => {
			rows.push(this.history(element));
		});
		
		return (
			<table>
				<tbody>
					{rows}
				</tbody>
			</table>
		)
	}
}