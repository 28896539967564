import React from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { OPEN_VIEW_EVENT } from "../../../../auto/js/events/Gui";
import { rest, t } from "../../../../auto/js/services";
import { createTableComponent } from "../../../../auto/js/widgets/TableComponent";
import { displayReadTranslationsForm } from "../../forms/metadata/TranslationsForm";
import { getServiceUri } from "../../../../auto/js/metadata";
import { I18N_ORDER_BY_MESSAGE_ID } from "../../../../auto/js/metadata/I18nOrderBy";
import { showNotification } from "../../utils";
import { OPEN_CONFIRMATION_DIALOG } from "../../../../auto/js/events/Gui";

const fields = [
    {title: "messageId", field: "messageId"},
    {title: "value", field: "value"},
    {title: "localeId", field: "localeId"},
];

export const displayTranslationsList = (gui) => {
	const readTranslationsList = () => {
	    let TranslationsList = createTableComponent(fields);
	    let uuid = uuidv4();
		return {
			uuid, view: () => <TranslationsList gui={gui} key={uuid} loadData={async (query) => buildData(query)} actions={getTableActions(gui)} editable={getTableEditable(gui)}/>
		};
	};
	gui.goTo(readTranslationsList);
}

const buildData = async (query) => {
    let filter = query;
    let data;
    filter["i18n"] = {};
    filter["orderBy"] = I18N_ORDER_BY_MESSAGE_ID ;
    filter.orderDirection = null;
    filter.offset = query.page * query.pageSize;
    if (query.search && query.search!='') {
        fields.forEach(element => {
            filter["i18n"][element.field] = query.search;
        });
        filter["and"] = false;
    }
    return await getTranslationsData(filter).then(response => {
        data = response;
        return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
        });
}

const countData = async (filter) => {
    return await rest.count("i18n", filter);
}

const getTranslationsData = async (filter) => {
    return await rest.search("i18n", filter);
}

const getTableEditable = (gui) => {
    let editables = {};
    editables.onRowDelete = rowData =>
    new Promise((resolve, reject) => {
        try {
            return rest.delete('i18n', rowData.id).then((response) =>
            {
                if (response.status != 200 || response.status != 201 || response.status != 204) {
                    showNotification(response.message?.split('Detail: ')[1], "error");
                    reject();
                }
                else {
                    showNotification(t`Deleted Translation`, "success");
                }
            })
        } catch (err) {
            alert(err);
            reject();
        }
    }).then(() => displayTranslationsList(gui));
    return editables;
}

const getTableActions = (gui) => {
    let actions = [];
    actions.push(
        {
            icon: () => <VisibilityIcon/>,
                tooltip: t`Edit`,
                onClick: (event, rowData) => {
                    gui.goTo(displayReadTranslationsForm((options) => displayConfirmationDialog(options, gui)), rowData.id);
                }
        }
    )
    return actions;
}
export const displayConfirmationDialog = (options, gui) => {
	let data = {title: "Confirmation", message: options.message, onClick: () => gui.goTo(displayTranslationsList(gui))};
	OPEN_CONFIRMATION_DIALOG.publish(data);
}
