import React from "react";

import {getServiceUri, pojoMetadata} from "../../../auto/js/metadata";
import {rest, t} from "../../../auto/js/services";
import {ISSUED_DOCUMENTS_ORDER_BY_ID} from "../../../auto/js/metadata/IssuedDocumentsOrderBy";
import {DOCUMENT_TYPE_ID_CARD} from "../../../auto/js/metadata/DocumentType";

export const issuedIdCardColumns = [
    {title: "documentId", field: "documentId"},
    {title: "vitalRecordId", field: "vitalRecordId"},
    {title: "Name", field: "fullName"},
    {title: "issuedDate", field: "issuedDate"},
    {title: "expires", field: "expires"},
    {title: "cancelledDate", field: "cancelledDate"}
];

export const buildData = async (query) => {
    let filter = query;
    let data;
    filter["issued-documents"] = {documentTypeList: [DOCUMENT_TYPE_ID_CARD]};
    filter["orderBy"] = ISSUED_DOCUMENTS_ORDER_BY_ID;
    filter.orderDirection = null;
    filter.offset = query.page * query.pageSize;
    if (query.search && query.search != '') {
        pojoMetadata["issued-documents"].columns.forEach(element => {
            if (element.type == 'text') {
                filter["issued-documents"][element.key] = query.search;
            }
        });
        filter["and"] = false;
    }
    return await getIssuedDocumentData(filter).then(response => {
        data = filterData(response)
        return countData(filter).then((count) => {
            return {data: data, totalCount: count, page: query.page}
        })
    });
}
const getIssuedDocumentData = async (filter) => {
    return await rest.request(getServiceUri() + "issued-documents/search-by-document-type/", "POST", filter);
}
const countData = async (filter) => {
    return await rest.request(getServiceUri() + "issued-documents/count/search-by-document-type/", "POST", filter);
}
const filterData = (DefaultRows) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        let issuedDate = row.issuedDate
        if (issuedDate !== null)
            row.issuedDate = issuedDate[2] + '-' + issuedDate[1] + '-' + issuedDate[0];
        let expires = row.expires
        if (expires !== null)
            row.expires = expires[2] + '-' + expires[1] + '-' + expires[0];
        let cancelledDate = row.cancelledDate
        if (cancelledDate !== null)
            row.cancelledDate = cancelledDate[2] + '-' + cancelledDate[1] + '-' + cancelledDate[0];
        newRows.push(row);
    }
    return newRows;
}

function formatId(eId) {
    if (eId.length < 9) {
        let diff = 9 - eId.length
        while (diff !== 0) {
            eId = "0" + eId;
            diff = diff - 1;
        }
    }
    let newString = eId.slice(0, 3) + '-' + eId.slice(3);
    let finalForm = newString.slice(0, 7) + '-' + newString.slice(7)
    return finalForm
}

export const getErrorList = () => {
    let list = [];
    list.push("E19");
    list.push("E22");
    return list;
}