
export const style = `
:root {
--bleed: 0cm;
--margin: 0.5cm;
}

* {
box-sizing: border-box;
}
* {
-webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
color-adjust: exact !important;                 /* Firefox 48 – 96 */
print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
}

@page { margin: 0; }

.print-page body {
margin: 0 auto;
padding: 0;
background: rgb(204, 204, 204);
display: flex;
flex-direction: column;
}

.print-page .page {
display: flex;
position: relative;
height: 53.98mm;
width: 86.5mm;
font-size: 8pt;
margin: 5mm auto;
padding: calc(var(--bleed) + var(--margin));
box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
background: white;
font-family: montserrat;
background-image: url(https://cr.nr.chahinehamila.com/public/printed-id-fingerprint.svg);
background-repeat: no-repeat;
background-size: contain;
background-position: top right;
}

.print-page .page::after {
position: absolute;
content: '';
bottom: 1mm;
right: 4mm!important;
width: 27mm!important;
height: 9mm!important;
margin: 0;
pointer-events: none;
z-index: 9999;
background-image: url(https://cr.nr.chahinehamila.com/public/logos-id.svg);
background-repeat: no-repeat;
}

.print-page h1 {
font-size: 10pt;
font-weight: bold !important;
text-align: left;
margin: 0mm;
letter-spacing: -0.5px;
}
.print-page h2.title {
font-size:7pt;
font-weight:bold !important;
text-align: left;
margin: 0mm;
letter-spacing: -0.5px;
}
.print-page h2.id-number {
font-weight: extra-bold !important;
font-size:20pt;
margin: 1mm 0mm 0.5mm 0;
}
.print-page h2 {
font-size:9pt;
font-weight:bold !important;
text-align: left;
letter-spacing: -0.25px;
}
.print-page h3.name {
font-size:8.5pt;
font-weight:bold !important;
margin: 0 !important;
text-align: left;
}
.print-page p.dob {
font-size:7pt;
font-weight:normal !important;
margin: 0 !important;
text-align: left;
}
.print-page p {
font-size:7pt;
font-weight:normal;
line-height: 9pt;
margin: 1.5mm 0mm;
}
.print-page .lefttext {
text-align: left;
}
.print-page .lefttext {
text-align: left;
}

.print-page .righttext {
text-align: right;
}

.print-page .header {
margin: calc(0mm - var(--margin))!important;
background-color: #0e9038;
padding: 5mm;
border-right: 0.75mm solid #000000;
height:53.98mm;
flex-basis: 28mm;
width:28mm;
}

.print-page .pattern {
margin: calc(0mm - var(--margin))!important;
background-image: url( https://cr.nr.chahinehamila.com/public/printed-ID-card-pattern.svg);
background-repeat: no-repeat;
flex-basis: 5mm;
position: absolute;
left:32.75mm;
width:5mm;
height:53.98mm;
}

.print-page .bodycontent {
flex-basis: 53.5mm;
width:44.5mm;
height:44.98mm;
position: absolute;
left:35.75mm;
}

.print-page .logos {
background-image: url(https://cr.nr.chahinehamila.com/public/logos-id.png);
background-repeat: no-repeat;
background-size: contain;
}

.print-page .passport-photo {
width: 18mm;
height:23mm;
max-width: 18mm;
max-height: 23mm;
margin: 0 auto 3.5mm auto;
background-color: black;
border: 0.75mm solid #000000;
overflow: hidden !important;
}

.print-page .passport-photo img {
width: 18mm;
max-width: 18mm;
height: auto;
margin:0 auto !important;
padding: 0 !important;
position: relative;
top:-0.5mm;
text-align: center;
}

.print-page .qr-code {
width: 18mm;
height:18mm;
margin: 0 auto;
background-color: #ffffff;
border: 0.75mm solid #000000;
padding:1.25mm;
}

.print-page .qr-code img {
width: 100%;
height: auto;
}

@media screen {

}

@media print {
.print-page .page {
margin: 0;
overflow: hidden;
}

.print-page * {
-webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
color-adjust: exact !important;                 /* Firefox 48 – 96 */
print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
}
}

`;