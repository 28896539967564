export const UNION_MTLB_ORDER_BY_ADDRESS = "ADDRESS";
export const UNION_MTLB_ORDER_BY_DECLARED_DATE = "DECLARED_DATE";
export const UNION_MTLB_ORDER_BY_DRAFT = "DRAFT";
export const UNION_MTLB_ORDER_BY_ID = "ID";
export const UNION_MTLB_ORDER_BY_MTLB_STATUS = "MTLB_STATUS";
export const UNION_MTLB_ORDER_BY_MTLB_STATUS_DATA = "MTLB_STATUS_DATA";
export const UNION_MTLB_ORDER_BY_MTLB_TYPE = "MTLB_TYPE";
export const UNION_MTLB_ORDER_BY_NOTES = "NOTES";
export const UNION_MTLB_ORDER_BY_PARTNER1_BIRTH_PLACE = "PARTNER1_BIRTH_PLACE";
export const UNION_MTLB_ORDER_BY_PARTNER1_FATHER_ID = "PARTNER1_FATHER_ID";
export const UNION_MTLB_ORDER_BY_PARTNER1_FATHER_NAME = "PARTNER1_FATHER_NAME";
export const UNION_MTLB_ORDER_BY_PARTNER1_FIRSTNAME = "PARTNER1_FIRSTNAME";
export const UNION_MTLB_ORDER_BY_PARTNER1_INDIVIDUAL_MELANESIAN_NAME = "PARTNER1_INDIVIDUAL_MELANESIAN_NAME";
export const UNION_MTLB_ORDER_BY_PARTNER1_MARITAL_STATUS = "PARTNER1_MARITAL_STATUS";
export const UNION_MTLB_ORDER_BY_PARTNER1_MOTHER_ID = "PARTNER1_MOTHER_ID";
export const UNION_MTLB_ORDER_BY_PARTNER1_MOTHER_NAME = "PARTNER1_MOTHER_NAME";
export const UNION_MTLB_ORDER_BY_PARTNER1_OCCUPATION = "PARTNER1_OCCUPATION";
export const UNION_MTLB_ORDER_BY_PARTNER1_RESIDENCE_ADDRESS = "PARTNER1_RESIDENCE_ADDRESS";
export const UNION_MTLB_ORDER_BY_PARTNER1_SURNAME = "PARTNER1_SURNAME";
export const UNION_MTLB_ORDER_BY_PARTNER1_VITAL_RECORD_ID = "PARTNER1_VITAL_RECORD_ID";
export const UNION_MTLB_ORDER_BY_PARTNER2_BIRTH_PLACE = "PARTNER2_BIRTH_PLACE";
export const UNION_MTLB_ORDER_BY_PARTNER2_FATHER_ID = "PARTNER2_FATHER_ID";
export const UNION_MTLB_ORDER_BY_PARTNER2_FATHER_NAME = "PARTNER2_FATHER_NAME";
export const UNION_MTLB_ORDER_BY_PARTNER2_FIRSTNAME = "PARTNER2_FIRSTNAME";
export const UNION_MTLB_ORDER_BY_PARTNER2_INDIVIDUAL_MELANESIAN_NAME = "PARTNER2_INDIVIDUAL_MELANESIAN_NAME";
export const UNION_MTLB_ORDER_BY_PARTNER2_MARITAL_STATUS = "PARTNER2_MARITAL_STATUS";
export const UNION_MTLB_ORDER_BY_PARTNER2_MOTHER_ID = "PARTNER2_MOTHER_ID";
export const UNION_MTLB_ORDER_BY_PARTNER2_MOTHER_NAME = "PARTNER2_MOTHER_NAME";
export const UNION_MTLB_ORDER_BY_PARTNER2_OCCUPATION = "PARTNER2_OCCUPATION";
export const UNION_MTLB_ORDER_BY_PARTNER2_RESIDENCE_ADDRESS = "PARTNER2_RESIDENCE_ADDRESS";
export const UNION_MTLB_ORDER_BY_PARTNER2_SURNAME = "PARTNER2_SURNAME";
export const UNION_MTLB_ORDER_BY_PARTNER2_VITAL_RECORD_ID = "PARTNER2_VITAL_RECORD_ID";
export const UNION_MTLB_ORDER_BY_PLACE = "PLACE";
export const UNION_MTLB_ORDER_BY_RITES = "RITES";
export const UNION_MTLB_ORDER_BY_UNION_REGIME = "UNION_REGIME";
export const UNION_MTLB_ORDER_BY_WITNESS1_BIRTH_PLACE = "WITNESS1_BIRTH_PLACE";
export const UNION_MTLB_ORDER_BY_WITNESS1_FIRSTNAME = "WITNESS1_FIRSTNAME";
export const UNION_MTLB_ORDER_BY_WITNESS1_OCCUPATION = "WITNESS1_OCCUPATION";
export const UNION_MTLB_ORDER_BY_WITNESS1_RESIDENCE_ADDRESS = "WITNESS1_RESIDENCE_ADDRESS";
export const UNION_MTLB_ORDER_BY_WITNESS1_SURNAME = "WITNESS1_SURNAME";
export const UNION_MTLB_ORDER_BY_WITNESS1_VITAL_RECORD_ID = "WITNESS1_VITAL_RECORD_ID";
export const UNION_MTLB_ORDER_BY_WITNESS2_BIRTH_PLACE = "WITNESS2_BIRTH_PLACE";
export const UNION_MTLB_ORDER_BY_WITNESS2_FIRSTNAME = "WITNESS2_FIRSTNAME";
export const UNION_MTLB_ORDER_BY_WITNESS2_OCCUPATION = "WITNESS2_OCCUPATION";
export const UNION_MTLB_ORDER_BY_WITNESS2_RESIDENCE_ADDRESS = "WITNESS2_RESIDENCE_ADDRESS";
export const UNION_MTLB_ORDER_BY_WITNESS2_SURNAME = "WITNESS2_SURNAME";
export const UNION_MTLB_ORDER_BY_WITNESS2_VITAL_RECORD_ID = "WITNESS2_VITAL_RECORD_ID";
export const UNION_MTLB_ORDER_BY_TIME_OF_MARRIAGE = "TIME_OF_MARRIAGE";
export const UNION_MTLB_ORDER_BY_DATE_OF_ORDER = "DATE_OF_ORDER";
export const UNION_MTLB_ORDER_BY_DATE_OF_EFFECT = "DATE_OF_EFFECT";
export const UNION_MTLB_ORDER_BY_ANNULLMENT_ORDER = "ANNULLMENT_ORDER";
export const UNION_MTLB_ORDER_BY_UNION_RECORD_ID = "UNION_RECORD_ID";
export const UNION_MTLB_ORDER_BY_UPLOADED = "UPLOADED";
export const UNION_MTLB_ORDER_BY_TOUCHED = "TOUCHED";
export const UNION_MTLB_ORDER_BY_UUID = "UUID";
