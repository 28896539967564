import React from "react";
import {v4 as uuidv4} from 'uuid';
import { createFormComponent } from '../../../auto/js/widgets/FormComponent';
import {  CAN_I_SWITCH } from '../../../auto/js/events/Gui';
import { rest } from "../../../auto/js/services/RestClient";
import { t } from "../../../auto/js/services/i18ndb";
import { AlertDialog } from "../../../auto/js/widgets";
import { pojoMetadata } from "../../../auto/js/metadata";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createTableComponent } from "../../../auto/js/widgets/TableComponent";

import { addressFields, countAddressData, filterData, form2Dto, getAddressData, loadGeoData } from "../../../auto/js/lists/AddressList";
import { emailFields, getEmailData, countEmailData } from "../../../auto/js/lists/EmailList";
import { phoneNumberFields, getPhoneData, countPhoneData } from "../../../auto/js/lists/PhoneNumberList";
import { EMAIL_ORDER_BY_EMAIL } from "../../../auto/js/metadata/EmailOrderBy";
import { PHONE_NUMBER_ORDER_BY_PHONE_NUMBER } from "../../../auto/js/metadata/PhoneNumberOrderBy";

export class AdministrativeUpdateApplicationFormComponent extends React.Component {
	constructor(props) {
		super(props);
    }

	render() {
        let AddressList = createTableComponent(addressFields);
        let EmailList = createTableComponent(emailFields);
        let PhoneNumberList = createTableComponent(phoneNumberFields);
	
		return (
		<>
            {(this.props.onFinish) && <button onClick={() => this.props.onFinish()} style={{ minWidth: '5rem' }} type="submit"> {t`Next`} </button>}
			<Accordion defaultExpanded>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                    <Typography>{t`Address`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <AddressList key={this.props.key} loadData={async (query) => buildAddressData(query, this.props.id)}  editable={getAddressEditables(this.props.id)}/>
                </AccordionDetails>
            </Accordion>
			<Accordion defaultExpanded>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                    <Typography>{t`Email`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <EmailList key={this.props.key} loadData={async (query) => buildEmailData(query, this.props.id)}  editable={getEmailEditables(this.props.id)}/>
                </AccordionDetails>
            </Accordion>
			<Accordion defaultExpanded>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                    <Typography>{t`PhoneNumber`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <PhoneNumberList key={this.props.key} loadData={async (query) => buildPhoneNumberData(query, this.props.id)}  editable={getPhoneNumberEditables(this.props.id)}/>
                </AccordionDetails>
            </Accordion>
		</>
		)
	}

}

export const readCivilRecordAdminInfo = (onFinish) => (id) => {
    let uuid = uuidv4();
    return { 
        uuid, view: () => <AdministrativeUpdateApplicationFormComponent key={uuid} id={id} onFinish={onFinish} />
    }
}

export const displayCivilRecordAdminInfo = (gui, id) => {
    gui.goTo(readCivilRecordAdminInfo(), id)
}

const buildAddressData = async (query, id) => {
    let filter = query;
    let data;
    filter["address"] = {vitalRecordId: id};
    filter["orderBy"] = null;
    filter.orderDirection = null;
    filter.offset = query.page * query.pageSize;
    if (query.search && query.search!='') {
        pojoMetadata["address"].columns.forEach(element => {
            if(element.type=='text'){
                filter["address"][element.key]= query.search;
            }
        });
        filter["and"] = false;
    }
    return await getAddressData(filter).then(response => {
        data = filterData(response);
        data.forEach(element => {
            loadGeoData(element);
        });
        return countAddressData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
    });
}

const getAddressEditables = (id) => {
    let editables = {}
    editables.onRowAdd = newData =>
        new Promise((resolve, reject) => {

            let dto = pojoMetadata['address'].form2dto(newData);
            dto["vitalRecordId"] = id;
            form2Dto(dto, newData);
            try {
                return rest.create('address', dto).then(() => resolve());
            } catch (err) {
                alert(err);
            }
        }),
        editables.onRowUpdate = (newData, oldData) =>
            new Promise((resolve, reject) => {
                let dto = pojoMetadata['address'].form2dto(newData);
                newData.id = oldData.id;
            	form2Dto(dto, newData);
                try {
                    return rest.update('address', dto).then(() => {
                        resolve()}).catch((e) => console.table(e))

                } catch (err) {
                    alert(err);
                }
            }),
        editables.onRowDelete = oldData =>
            new Promise((resolve, reject) => {
                try {
                    return rest.purge('address', oldData.id).then(() => resolve());
                } catch (err) {
                    alert(err);
                }
            })
    return editables;
}

export const buildEmailData = async (query, id) => {
    let filter = query;
    let data;
    filter["email"] = {vitalRecordId: id};
    filter["orderBy"] = EMAIL_ORDER_BY_EMAIL;
    filter.orderDirection = null;
    filter.offset = query.page * query.pageSize;
    if (query.search && query.search!='') {
        pojoMetadata["email"].columns.forEach(element => {
            if(element.type=='text'){
                filter["email"][element.key]= query.search;
            }
        });
        filter["and"] = false;
    }
    return await getEmailData(filter).then(response => {
        data = response;
        return countEmailData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
    });
}

const buildPhoneNumberData = async (query, id) => {
    let filter = query;
    let data;
    filter["phone-number"] = {vitalRecordId: id};
    filter["orderBy"] = PHONE_NUMBER_ORDER_BY_PHONE_NUMBER;
    filter.orderDirection = null;
    filter.offset = query.page * query.pageSize;
    if (query.search && query.search!='') {
        pojoMetadata["phone-number"].columns.forEach(element => {
            if(element.type=='text'){
                filter["phone-number"][element.key]= query.search;
            }
        });
        filter["and"] = false;
    }
    return await getPhoneData(filter).then(response => {
        data = response;
        return countPhoneData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
    });
}

const getEmailEditables = (id) => {
    let editables = {}
    editables.onRowAdd = newData =>
        new Promise((resolve, reject) => {
            let dto = pojoMetadata['email'].form2dto(newData);
            dto["vitalRecordId"] = id;       
            try {
                return rest.create('email', dto).then(() => resolve());
            } catch (err) {
                alert(err);
            }        
        })
    editables.onRowDelete = oldData =>
        new Promise((resolve, reject) => {
            try {
                return rest.purge('email', oldData.id).then(() => resolve());
            } catch (err) {
                alert(err);
            }   
        })
    return editables;
}

const getPhoneNumberEditables = (id) => {
    let editables = {}
    editables.onRowAdd = newData =>
        new Promise((resolve, reject) => {
            let dto = pojoMetadata['phone-number'].form2dto(newData);
            dto["vitalRecordId"] = id;       
            try {
                return rest.create('phone-number', dto).then(() => resolve());
            } catch (err) {
                alert(err);
            }        
        })
    editables.onRowDelete = oldData =>
        new Promise((resolve, reject) => {
            try {
                return rest.purge('phone-number', oldData.id).then(() => resolve());
            } catch (err) {
                alert(err);
            }   
        })
    return editables;
}