import React from "react";

import * as Yup from 'yup';

import '../pages/printable.css';
import { AddressComponent } from "../../../auto/js/widgets/AddressComponent";
import { Section } from "../widgets/Section";
import { PersonComponent } from "../../../auto/js/widgets/PersonComponent";
import { loadPersonData } from "../utils";
import { MentionsGrid } from "../../../auto/js/widgets/MentionsGrid";
import { GeoDataComponent } from "../../../auto/js/widgets/GeoDataComponent";
import { CountryAutoCompleteInput } from "../widgets/CountryAutoCompleteInput";
import { geoDataMetadataLoader } from "../../../auto/js/metadata/GeoDataMetadataLoader";
import AutocompleteListSelector from "../widgets/AutocompleteListSelector";
import { rest, t } from "../../../auto/js/services";
import { swapObject } from "../../../auto/js/utils";
import { TypeOfAcquisition } from "../../../auto/js/metadata/TypeOfAcquisition";
import { AdvancedSearchPersonComponent } from "../widgets/AdvancedSearchPersonComponent";
import { getServiceUri } from "../../../auto/js/metadata";
import { DELETE_ATTACHMENT_EVENT } from "../../../auto/js/events/Gui";
import { Tribe } from "../../../auto/js/metadata/Tribe";

const gender = { 1: "MALE", 2: "FEMALE" };
const maritalStatus = { 1: "SINGLE", 2: "MARRIED", 3: "DIVORCED", 4: "WIDOWED" };

export const civilRecordFormfields = [

    { name: "image", type: "image", x: 1, y: 2, layout: "col-md-12" },
    { name: "tags", type: "tags", x: 1, y: 1, layout: "col-md-12" },
    { name: "id", type: "number", x: 1, y: 3, layout: "col-md-12" },
    { name: "firstname", type: "text", x: 1, y: 4, layout: "col-md-6" },
    { name: "secondname", type: "text", x: 2, y: 4, layout: "col-md-6" },
    // { name: "thirdname", type: "text", x: 1, y: 5, layout: "col-md-6" },
    { name: "fourthname", type: "text", x: 2, y: 5, layout: "col-md-6" },
    { name: "fifthname", type: "text", x: 1, y: 6, layout: "col-md-12" },
    { name: "gender", type: "select", x: 1, y: 7, layout: "col-md-6", metadata: () => gender },
    { name: "maritalStatus", type: "select", x: 2, y: 7, layout: "col-md-6", metadata: () => maritalStatus },
    { name: "tribe", type: "select", x: 2, y: 8, layout: "col-md-6", metadata: () => swapObject(Tribe) },
    {
        name: "citizenshipBox", label: "Citizenship", components: [
            { name: "primaryCitizenship", type: "custom", x: 1, y: 1, layout: "col-md-6", component: (name, disabled) => <CountryAutoCompleteInput name={name} readOnly={disabled} default={"121"} /> },
            { name: "typeOfAcquisition", type: "select", x: 2, y: 1, layout: "col-md-6", metadata: () => swapObject(TypeOfAcquisition) },
            { name: "otherCitizenshipCsv", type: "custom", x: 1, y: 3, layout: "col-md-6", component: (name, disabled) => <AutocompleteListSelector options={geoDataMetadataLoader.getRootNodes()} name={name} readOnly={disabled} label={t`otherCitizenship`} /> },
        ], type: "box", x: 2, y: 9, layout: "col-md-11 ms-2 mt-2 pt-1 pb-2"
    },
    { name: "birthdate", type: "date", x: 1, y: 11, layout: "col-md-6" },
    { name: "birthTime", type: "time", x: 2, y: 11, layout: "col-md-6" },
    {
        name: "birthBox", label: "Birth Place", components: [
            { name: "birthPlace", type: "custom", x: 1, y: 1, layout: "col-md-12", component: (name, disabled) => <GeoDataComponent name={name} disabled={disabled} /> }
        ], type: "box", x: 2, y: 12, layout: "col-md-11 ms-2 mt-2 pt-1 pb-2"
    },
    {
        name: "originBox", label: "Village Of Origin", components: [
            { name: "villageOfOrigin", type: "custom", x: 1, y: 1, layout: "col-md-12", component: (name, disabled) => <GeoDataComponent name={name} disabled={disabled} /> },
        ], type: "box", x: 1, y: 12, layout: "col-md-11 ms-2 mt-2 pt-1 pb-2"
    },
    { name: "motherDetails", type: "custom", x: 1, y: 16, layout: "col-md-12", component: (name, disabled) => <Section name={name} /> },
    {
        name: "motherInfo", options: [
            { "name": "motherWithBirthRecord", "label": "motherWithBirthRecord" },
            { "name": "motherWithoutBirthRecord", "label": "motherWithoutBirthRecord" },
            { "name": "unknownMother", "label": "unknownMother" },
        ], type: "radio", x: 1, y: 17, layout: "col-md-12 pt-4"
    },
    {
        name: "motherId", label: "Search Mother", type: "custom", x: 1, y: 17, layout: "col-md-12",
        component: (name, disabled) => <AdvancedSearchPersonComponent name={name} label={"Search Mother"} readOnly={disabled} loadData={loadPersonData} />,
        display: (data) => { return (data.motherInfo === "motherWithBirthRecord" || data.motherId != null) ? true : false; }
    },
    {
        name: "reportedMotherName", type: "text", x: 2, y: 17, layout: "col-md-12",
        display: (data) => { return data.motherInfo === "motherWithoutBirthRecord" ? true : false; }
    },
    { name: "fatherDetails", type: "custom", x: 1, y: 18, layout: "col-md-12", component: (name, disabled) => <Section name={name} /> },
    {
        name: "fatherInfo", options: [
            { "name": "fatherWithBirthRecord", "label": "fatherWithBirthRecord" },
            { "name": "fatherWithoutBirthRecord", "label": "fatherWithoutBirthRecord" },
            { "name": "unknownFather", "label": "unknownFather" },
        ], type: "radio", x: 1, y: 20, layout: "col-md-12 pt-4"
    },
    {
        name: "fatherId", label: "Search Father", type: "custom", x: 1, y: 21, layout: "col-md-12",
        component: (name, disabled) => <AdvancedSearchPersonComponent name={name} label={"Search Father"} readOnly={disabled} loadData={loadPersonData} />,
        display: (data) => { return (data.fatherInfo === "fatherWithBirthRecord" || data.fatherId != null) ? true : false; }
    },
    {
        name: "reportedFatherName", type: "text", x: 2, y: 22, layout: "col-md-12",
        display: (data) => { return data.fatherInfo === "fatherWithoutBirthRecord" ? true : false; }
    },
    {
        name: "medicalNotificationFile", label: "Medical Notification", type: "file", x: 1, y: 25, layout: "col-md-12",
        uploadUrl: (id) => getUploadUrl(id),
        previewUrl: (id) => getPreviewUrl(id),
        loadData: async (id) => loadMedicalNotificationAttachment(id),
        handleDelete: (id) => handleMedicalNotificationDelete(id),
        handleClick: (id) => handleMedicalNotificationClick(id),
        updateFileData: (data) => updateFileData(data)
    },
    { name: "Mentions", type: "custom", x: 1, y: 28, layout: "col-md-12", component: (name, disabled) => <Section name={name} /> },
];

const getUploadUrl = (id) => {
    return getServiceUri() + 'vital-record/medical-notification-file' + '/' + id;
}

const getPreviewUrl = (id) => {
    return getServiceUri() + 'vital-record/medical-notification-file' + '/preview/' + id;
}

const updateFileData = (data) => {
    let filter = { name: data.fileName, description: data.description };
    rest.request(getServiceUri() + 'vital-record/medical-notification-file' + '/' + data.id, "PUT", filter);
}

const handleMedicalNotificationClick = async (id) => {
    const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
    window.location = getServiceUri() + 'vital-record/medical-notification-file' + '/' + id + '/' + token;
};

const handleMedicalNotificationDelete = (id) => {
    rest.delete('vital-record/medical-notification-file', id).then(() => {
        DELETE_ATTACHMENT_EVENT.publish(id)
    });
};

const loadMedicalNotificationAttachment = async (id) => {
    let filter = { and: true };
    filter['vital-record-medical-notification-file'] = {};
    filter['vital-record-medical-notification-file']['vitalRecordId'] = id;
    return rest.search('vital-record/medical-notification-file', filter)
}


export const birthCertificateContent =

{
    leftTitle1: "la république de Nauru",
    leftTitle2: "Enregistrement Civil et Gestion d’Identité",
    rightTitle1: "Republic of Nauru",
    rightTitle2: "Civil Registration and Identity Management",
    mainTitle: "Nauru Birth Registration Certificate",
    mainSubTitle: "(Civil Registration and Identity Management Act - 28/2021)",
    signedBy: "John Doe"
};

export const parentsSearchFormfields = [
    { name: "exactId", type: "number", x: 1, y: 1, layout: "col-md-4" },
    { name: "idSeparator", type: "custom", x: 1, y: 2, layout: "col-md-12", component: (name, disabled) => <Separator /> },

    { name: "firstname", type: "text", x: 1, y: 2, layout: "col-md-6" },
    {
        name: "firstnameCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 2, layout: "col-md-6 pt-5", defaultValue: "fuzzy"
    },

    { name: "secondname", type: "text", x: 1, y: 3, layout: "col-md-6" },
    {
        name: "secondnameCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 3, layout: "col-md-6 pt-5", defaultValue: "fuzzy"
    },

    /* { name: "thirdname", type: "text", x: 1, y: 4, layout: "col-md-6" },
    {
        name: "thirdnameCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 4, layout: "col-md-6 pt-5", defaultValue: "fuzzy"
    }, */

    { name: "fourthname", type: "text", x: 1, y: 5, layout: "col-md-6", defaultValue: "fuzzy" },
    {
        name: "fourthnameCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 5, layout: "col-md-6 pt-5", defaultValue: "fuzzy"
    },

    {
        name: "genderBox", label: "Gender", components: [
            { name: "male", label: "Male", type: "checkbox", x: 1, y: 1, layout: "col-md-3" },
            { name: "female", label: "Female", type: "checkbox", x: 2, y: 1, layout: "col-md-3" },
        ], type: "box", x: 3, y: 6, layout: "col-md-11 ms-2 mt-2"
    },//mx-auto
    {
        name: "birthBox", label: "Birth Date", components: [
            { name: "rangeBirthdate", label: "exactbirthdate", type: "checkbox", x: 1, y: 1, layout: "col-md-12" },
            {
                name: "exactBirthdate", type: "date", x: 1, y: 2, layout: "col-md-6", display: (data) => {
                    return !data.rangeBirthdate;
                }
            },

            {
                name: "birthdateStart", type: "date", x: 1, y: 3, layout: "col-md-6", display: (data) => {
                    return data.rangeBirthdate;
                }
            },
            {
                name: "birthdateEnd", type: "date", x: 2, y: 3, layout: "col-md-6", display: (data) => {
                    return data.rangeBirthdate;
                }
            },
        ], type: "box", x: 1, y: 7, layout: "col-md-11 ms-2 mt-2 pt-1 pb-2"
    },

    {
        name: "birthBox", label: "Birth Place", components: [
            { name: "anyBornOutsideCountry", label: "anyBornOutsideCountry", type: "checkbox", x: 1, y: 1, layout: "col-md-12" },
            {
                name: "birthPlace", type: "custom", x: 1, y: 2, layout: "col-md-6",
                component: (name, disabled) => <GeoDataComponent name={name} disabled={disabled} />,
                display: (data) => { return !data.anyBornOutsideCountry }
            },
        ], type: "box", x: 2, y: 9, layout: "col-md-11 ms-2 mt-2 pt-1 pb-2"
    }
];

export const birthRecordColumns = [
    { title: "image", field: "image", render: rowData => <img src={rowData.image} onError={(e) => { e.target.onerror = null; e.target.src = "/public/avatar.png" }} style={{ width: 40, borderRadius: '50%', height: 40 }} /> },
    { title: "id", field: "id" },
    { title: "firstname", field: "firstname" },
    { title: "secondname", field: "secondname" },
    // { title: "thirdname", field: "thirdname" },
    { title: "fourthname", field: "fourthname" },
    { title: "birthdate", field: "birthdate" },
];

export const Separator = () => {
    return (
        <>
            <div className="col-md-12">
                <hr style={{ borderTop: '2px solid #8cadab' }} />
            </div>
        </>
    );
};
export const LabelSeparator = ({ name }) => {
    return (
        <>
            <h3>{name}</h3>
        </>
    );
};

export const transformFormData = (formData) => {
    const columns = [];

    const addColumn = (name, type, value, condition) => {
        columns.push({
            name,
            type,
            [type === 'date' ? 'dateValue' : (type === 'text' ? 'textValue' : 'longValue')]: value,
            searchType: condition ? condition : null,
        });
    };
    (formData.firstname !== "") && addColumn('firstname', 'text', formData.firstname, formData.firstnameCondition);

    (formData.secondname !== "") && addColumn('secondname', 'text', formData.secondname, formData.secondnameCondition);

    /* (formData.thirdname !== "") && addColumn('thirdname', 'text', formData.thirdname, formData.thirdnameCondition); */

    (formData.fourthname !== "") && addColumn('fourthname', 'text', formData.fourthname, formData.fourthnameCondition);

    (formData.exactId !== "") && addColumn('id', 'number', formData.exactId, null);
    if (formData.anyBornOutsideCountry)
        addColumn('birthPlace', 'text', '121', 'notStartsWith');
    else
        (formData.birthPlace) && addColumn('birthPlace', 'text', formData.birthPlace, formData.birthPlace ? "startsWith" : null);
    const birthdateRange = formData.birthdateStart && formData.birthdateEnd
        ? {
            min: formData.birthdateStart ? formData.birthdateStart : null,
            max: formData.birthdateEnd ? formData.birthdateEnd : null,
        }
        : null;

    (birthdateRange != null || formData.exactBirthdate != null) && addColumn('birthdate', 'date', formData.exactBirthdate, null);
    if ((birthdateRange != null || formData.exactBirthdate != null))
        columns[columns.length - 1].dateRange = birthdateRange;

    const gender = [];
    formData.male ? gender.push("1") : null;
    formData.female ? gender.push("2") : null;
    if (gender.length)
        columns.push({
            name: 'gender',
            type: 'enum',
            enumValues: gender,
        });

    return { columns };
};