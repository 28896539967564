export const ADDRESS_ORDER_BY_CIVIL_STATUS_MTLB_ID = "CIVIL_STATUS_MTLB_ID";
export const ADDRESS_ORDER_BY_DEATH_RECORD_ID = "DEATH_RECORD_ID";
export const ADDRESS_ORDER_BY_FROM_DATE = "FROM_DATE";
export const ADDRESS_ORDER_BY_ID = "ID";
export const ADDRESS_ORDER_BY_LOCATION_ID = "LOCATION_ID";
export const ADDRESS_ORDER_BY_TO_DATE = "TO_DATE";
export const ADDRESS_ORDER_BY_AREA_ID = "AREA_ID";
export const ADDRESS_ORDER_BY_VITAL_RECORD_ID = "VITAL_RECORD_ID";
export const ADDRESS_ORDER_BY_ADDRESS_TYPE = "ADDRESS_TYPE";
export const ADDRESS_ORDER_BY_ADMINISTRATIVE_UPDATE_MTLB_ID = "ADMINISTRATIVE_UPDATE_MTLB_ID";
