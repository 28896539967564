import React from "react";

import { GeoDataComponent } from "../../../auto/js/widgets/GeoDataComponent";
import { AdvancedSearchPersonComponent } from "../widgets/AdvancedSearchPersonComponent";
import { Section } from "../widgets/Section";
import { rest } from "../../../auto/js/services";
import { setContextualOptions } from "../../../auto/js/widgets";
import { certificateStyle } from "./DeathCertificateStyleObj";
import { DeathCertificate } from "./DeathCertificate";
import { getServiceUri } from "../../../auto/js/metadata";
import { loadPersonData } from "../utils";
import { PRINT_EVENT } from "../../../auto/js/events/Gui";

export const deathRecordFields = [
    { name: "tags", type: "tags", x: 1, y: 1, layout: "col-md-12" },
	{name: "deceasedDetails", type: "custom", x:1, y:2, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
	{name: "vitalRecordId", type: "custom", x:1, y: 3, layout:"col-md-12", component: (name, disabled) => <AdvancedSearchPersonComponent name={name} label={"Search Record"} readOnly={disabled} loadData={loadPersonData}/>},
	{name: "deathDetails", type: "custom", x:1, y:4, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
	{name: "dateOfDeath", type: "date", x:1, y: 5, layout:"col-md-6"},
	{name: "timeOfDeath", type: "time", x:2, y: 5, layout:"col-md-6"},
	{name:"deathPlaceBox", label: "Death Place", components: [
		{name: "deathPlace", type: "custom", x:1, y:7, layout:"col-md-12", component: (name, disabled) => <GeoDataComponent name={name} disabled={disabled} default={"121"}/>},
	], type: "box", x:1, y:7, layout:"col-md-11 ms-2 mt-2 pt-1 pb-2"},
	{name:"burialPlaceBox", label: "Burial Place", components: [
		{name: "burialPlace", type: "custom", x:1, y:7, layout:"col-md-12", component: (name, disabled) => <GeoDataComponent name={name} disabled={disabled} default={"121"}/>},
	], type: "box", x:1, y:8, layout:"col-md-11 ms-2 mt-2 pt-1 pb-2"},
	{name: "deathNotificationFile", type: "file", x:1, y:10, layout:"col-md-12", 
		uploadUrl: (id) => getUploadUrl(id),
		previewUrl: (id) => getPreviewUrl(id),
		loadData: async (id) => loadDeathNotificationAttachment(id), 
		handleDelete:(id) => handleDeathNotificationDelete(id), 
		handleClick: (id) => handleDeathNotificationClick(id),
		updateFileData: (data) => updateFileData(data)
	},
	{name: "declarantDetails", type: "custom", x:1, y:11, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
	{name: "declarantId", type: "custom", x:1, y:12, layout:"col-md-12", component: (name, disabled) => <AdvancedSearchPersonComponent name={name} label={"Search Declarant"} readOnly={disabled} loadData={loadPersonData}/>},
	{name: "extras", type: "custom", x:1, y:13, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
	{name: "notes", type: "text", x:1, y: 14, layout:"col-md-12"}
];

const getUploadUrl = (id) => {
	return getServiceUri() + 'death-record/death-notification-file' + '/' + id;
}

const updateFileData = (data) => {
	let filter = {name: data.fileName, description: data.description};
	rest.request(getServiceUri() + 'death-record/death-notification-file' + '/' + data.id, "PUT", filter);
}

const getPreviewUrl = (id) => {
	return getServiceUri() + 'death-record/death-notification-file' + '/preview/' + id;
}

const handleDeathNotificationClick = async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	window.location = getServiceUri() + 'death-record/death-notification-file' + '/' + id + '/' + token;
};

const handleDeathNotificationDelete = (id) => {
	rest.delete('death-record/death-notification-file', id).then(() => {
		DELETE_ATTACHMENT_EVENT.publish(id)
	});
};

const loadDeathNotificationAttachment = async (id) => {
	let filter = {and: true};
	filter['death-record-death-notification-file'] = {};
	filter['death-record-death-notification-file']['deathRecordId'] = id;
	return rest.search('death-record/death-notification-file', filter)
}

export const deathCertificateContent = {   
    leftTitle1: "Gouvernement de République de Nauru",
    leftTitle2: "Enrégistrement Civil et Gestion d’Identité",
    rightTitle1: "Government of the Republic of Nauru",
    rightTitle2: "Civil Registration and Identity Management",
    mainTitle: "Nauru Death Certificate",
    mainSubTitle: "(Civil Registration and Identity Management Act - 28/2021)",
    signedBy: "Jeffrey Tila Langati Trief" 
};

const printDeathCertificate = (data, token) => () => {
	if (data.registrarSignatureId != null)
		data.signatureUrl = getServiceUri() + 'registrar' + '/signature/' + data.registrarSignatureId + '/' + token;
	const printable = {
				content:<DeathCertificate data={data}/>,
				style:{certificateStyle}.certificateStyle,
				copyParentStyle:false
	}
	PRINT_EVENT.publish(printable);
}

export const loadFormData = async (id) => {
	return await rest.read('death-record', id).then(async (response) => {
        let token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
		let form = response;
		let faceUrl = null;
		if (response.faceId != null) {
            const imageUrl = getServiceUri() + 'face/image/' + response.faceId + "/" + token;
			form['image'] = { x: 0.5, y: 0.5, scale: 1, rotate: 0, url: (imageUrl != null) ? imageUrl : '/public/avatar.png', isEmpty: false };
		} else
			form['image'] = { x: 0.5, y: 0.5, scale: 1, rotate: 0, url: '/public/avatar.png', isEmpty: true };
        if (response.timeOfDeath !== null) {
            form.timeOfDeath = new Date().setHours(response.timeOfDeath[0], response.timeOfDeath[1]);
            }
		let tagFilter = {and: true};
		tagFilter['death-record-tag'] = {deathRecordId: id};
		if (response.status)
            showNotification(response.message.split('Detail: ')[1], "error");
		return rest.search('death-record-tag', tagFilter).then(tags => {
			form['tags'] = tags
			if (tags.status)
            showNotification(response.message.split('Detail: ')[1], "error");
            rest.read('death-certificate/read/', id).then(async data => {
                setContextualOptions({
                "death-records": {
                    submenu: {
                        "actions": {
                            options: {
                                "death-certificate": { 
                                    label: "Print Death Certificate", do: printDeathCertificate(data, token)
                                }
                            },
							label: "Actions"
                        }
                    }
                }
            })});
			return form;
		})
	})
}