import * as React from 'react';
import { useField } from "formik";
import { Observable } from '../../../auto/js/events/Observable';
import { geoDataMetadataLoader } from '../../../auto/js/metadata/GeoDataMetadataLoader';
import { t } from '../../../auto/js/services';
import { SimpleAutoCompleteInput } from '../../../auto/js/widgets/SimpleAutoCompleteInput';

const countryObservable = new Observable();
const resetDistrictEvent = new Observable();

let resetList =[
resetDistrictEvent,
];
export function GeoDataComponent({ ...props }) {
    const [field, meta, helpers] = useField(props.name);
    const { value } = meta;
    const { setValue } = helpers;
    const [hideDistrict, setHideDistrict] = React.useState(true);
    let countryValue;
    const handleCountryChange = (value) => {
        if (value.key) {
            countryObservable.publish(geoDataMetadataLoader.getChilds(value.key))
            if (Object.keys(geoDataMetadataLoader.getChilds(value.key)).length)
                setHideDistrict(false);
            else
                setHideDistrict(true);
            setValue(geoDataMetadataLoader.getAreaId(value.key));
        }
        else {
            countryObservable.publish([])
            setHideDistrict(true);
        }
        resetFieldsFromIndex(0);
    }
    let districtValue;
    const handleDistrictChange = (value) => {
        if (value.key) {
            setValue(geoDataMetadataLoader.getAreaId(value.key));
        }
    }
    const showData = (value) => {
	    let addressComponents = value.split(".")
	    if (addressComponents[0]) {
	        let country = geoDataMetadataLoader.getArea(addressComponents[0]);
	        if (country) {
	        	countryValue = { key: country.id, value: country.name };
		        countryObservable.publish(geoDataMetadataLoader.getGeoLevel(country.id, 1))
		        if (hideDistrict)
		        	setHideDistrict(false);
	        }
	    }
	    if (addressComponents[1]) {
	        let district = geoDataMetadataLoader.getArea(addressComponents[0] + "." + addressComponents[1]);
	        if (district) {
	        	districtValue = { key: district.id, value: district.name };
	        }
	    }
	}
    
    if (value != null)
        showData(value);
    let style = (props.linear)?"row":"";
    return (
        <>
            <div class="mainform">
                <section class={"fullwidth " + style}>
                    	<SimpleAutoCompleteInput layout={(props.linear)?"6":""} name={"country"} options={() => geoDataMetadataLoader.getRootNodes()} label={t`country`} handleChange={(data) => handleCountryChange(data)}  defaultValue={countryValue} disabled={props.disabled}/>
                    	{(!hideDistrict ||districtValue) && <SimpleAutoCompleteInput layout={(props.linear)?"6":""} name={"district"} options={() => ""} label={t`district`} handleChange={(data) => handleDistrictChange(data)} observable={countryObservable} reset={resetDistrictEvent} defaultValue={districtValue} disabled={props.disabled}/>}
                </section>
            </div>
        </>
    )
}


const resetFieldsFromIndex = (index) => {
    for (let i = index; i < resetList.length; i++)
        resetList[i].publish()
}