import React, { useState } from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { OPEN_VIEW_EVENT } from "../../../../auto/js/events/Gui";
import { getServiceUri, pojoMetadata } from "../../../../auto/js/metadata";
import { getMtlbType, MtlbType } from "../../../../auto/js/metadata/MtlbType";
import { rest, t } from "../../../../auto/js/services";
import { createTableComponent } from "../../../../auto/js/widgets/TableComponent"
import { registrarListColumns, getMtlbTypeList, getOrderBy,  filterData, getErrorList, transformAdvancedSearchData, advancedSearchfields } from "../../../../main/js/lists/registrar/RegistrarListCommon"
import { Checkbox, FormControlLabel } from "@mui/material";
import Facets from "../../widgets/Facets";
import { displayReadRegistrarForm } from "../../forms/registrar/RegistrarForm";

const RegistrarListPage = ({uuid, gui, searchData}) => {
    const [advancedSearchData, setAdvancedSearchData] = useState(undefined);
    let RegistrarTable = createTableComponent(registrarListColumns);
    
       const buildData = async (query) => {
        let filter = query;
        let data;
        filter["registrar-document"] = {draft: false};
        filter['and'] = true;
        filter["orderBy"] = getOrderBy();
        if (advancedSearchData)
            filter["query"] = advancedSearchData;
        filter.orderDirection = null;
        filter.offset = query.page * query.pageSize;
        if (query.search && query.search!='') {
            pojoMetadata["registrar"].columns.forEach(element => {
                if(element.type=='text' ){
                    filter["registrar-document"][element.key]= query.search;
                }
            });
            filter['and'] = false;
            filter.fuzziness = "AUTO";
        }
        return await getData(filter).then(async response => {
            const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
            data = await filterData(response, token);
            return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
        });
    }
	const onAdvancedSearchSubmit = (data) => {
        let dto = transformAdvancedSearchData(data);
        setAdvancedSearchData(dto);
    }
    return (
        <div>
            <RegistrarTable key={uuid} loadData={async (query) => buildData(query)}  actions={getTableActions(gui)} advancedSearchFields={advancedSearchfields} onSubmit={onAdvancedSearchSubmit}/>
    	</div>
    )


}

export const displayRegistrarList = (gui) => {  
	const readRegistrarList = () => {
    let uuid = uuidv4();
		return {
			uuid, view: () => <RegistrarListPage gui={gui} key={uuid} />
		};
	}
	gui.goTo(readRegistrarList)
}
const getTableActions = (gui) => {
    let actions = [];
    actions.push(
        {
            icon: () => <VisibilityIcon/>,
                tooltip: t`Edit`,
                onClick: (event, rowData) => {
                    gui.goTo(displayReadRegistrarForm(), rowData.id, null);
                }
        }
    )
    return actions;   
}

export const getData = async (filter) => {
    return await rest.request(getServiceUri() + "registrar/search-document/", "POST", filter);
}

export const countData = async (filter) => {
    return await rest.request(getServiceUri() + "registrar/count-document/", "POST", filter);
}

export const displayConfirmationDialog = (options, gui) => {
	let data = {title: "Confirmation", message: options.message, onClick: () => gui.goTo(displayRegistrarList(gui))};
	OPEN_CONFIRMATION_DIALOG.publish(data);
}