import * as React from 'react';
import { useField } from "formik";
import { Observable } from '../../../auto/js/events/Observable';
import { geoMetadataLoader } from '../../../auto/js/metadata/GeoMetadataLoader';
import { t } from '../../../auto/js/services';
import { SimpleAutoCompleteInput } from '../../../auto/js/widgets/SimpleAutoCompleteInput';

const countryObservable = new Observable();
const resetDistrictEvent = new Observable();

let resetList =[resetDistrictEvent,];
export function AddressComponent({ ...props }) {
    const [field, meta, helpers] = useField(props.name);
    const { value } = meta;
    const { setValue } = helpers;
    const [hideDistrict, setHideDistrict] = React.useState(false);

    let countryValue;
    const handleCountryChange = (value) => {
        if (value.key) {
            countryObservable.publish(geoMetadataLoader.getGeoLevel(value.key, 3))
            if (Object.keys(geoMetadataLoader.getGeoLevel(value.key, 3)).length)
                setHideDistrict(false);
            else
                setHideDistrict(true);
            setValue(geoMetadataLoader.getAreaId(value.key));
        }
        else {
            countryObservable.publish([])
            setHideDistrict(true);
        }
        resetFieldsFromIndex(0);
    }
    let districtValue;
    const handleDistrictChange = (value) => {
        if (value.key) {
            setValue(geoMetadataLoader.getAreaId(value.key));
        }
    }
    
    const showData = (value) => {
	    let addressComponents = value.split(".")
	    if (addressComponents[0]) {
	        let country = geoMetadataLoader.getArea(addressComponents[0]);
	        countryValue = { key: country.id, value: country.name };
	        countryObservable.publish(geoMetadataLoader.getGeoLevel(country.id, 3))
	    }
	    if (addressComponents[3]) {
	        let district = geoMetadataLoader.getArea(addressComponents[0] + "." + addressComponents[1] + "." + addressComponents[2] + "." + addressComponents[3]);
	        districtValue = { key: district.id, value: district.name };
	    }
	}
    
    if (value != null)
        showData(value);
    let style = (props.linear)?"row":"";
    return (
        <>
            <div class="mainform">
                <section class={"fullwidth " + style}>
                    	<SimpleAutoCompleteInput layout={(props.linear)?"col-md-6":""} name={"country"} options={() => geoMetadataLoader.getRootNodes()} label={t`Country`} handleChange={(data) => handleCountryChange(data)}  defaultValue={countryValue} disabled={props.disabled}/>
                    	{(!hideDistrict || districtValue) && <SimpleAutoCompleteInput layout={(props.linear)?"col-md-6":""} name={"district"} options={() => ""} label={t`District`} handleChange={(data) => handleDistrictChange(data)} observable={countryObservable} reset={resetDistrictEvent} defaultValue={districtValue} disabled={props.disabled}/>}
                </section>
            </div>
        </>
    )
}


const resetFieldsFromIndex = (index) => {
    for (let i = index; i < resetList.length; i++)
        resetList[i].publish()
}