import React from "react";

import { buildEmptyObject } from "../../../../auto/js/widgets/FormComponent";
import { getServiceUri, metadataLoader, pojoMetadata } from "../../../../auto/js/metadata";
import { MTLB_TYPE_BIRTH_REGISTRATION } from "../../../../auto/js/metadata/MtlbType";
import { rest } from "../../../../auto/js/services/RestClient";
import { t } from '../../../../auto/js/services/i18ndb';
import { showNotification, swapObject } from "../../../../auto/js/utils";
import * as Yup from 'yup';
import { formState } from "../../../../auto/js/forms/FormState";
import { AddressComponent } from "../../../../auto/js/widgets/AddressComponent";
import { PersonComponent } from "../../../../auto/js/widgets/PersonComponent";
import { loadPersonData } from "../../utils";
import { GeoDataComponent } from "../../../../auto/js/widgets/GeoDataComponent";
import { geoDataMetadataLoader } from "../../../../auto/js/metadata/GeoDataMetadataLoader";
import { CountryAutoCompleteInput } from "../../widgets/CountryAutoCompleteInput";
// import { FileInput } from "../../widgets/FileInput";
import { DELETE_ATTACHMENT_EVENT, FILE_UPLOADED_EVENT } from "../../../../auto/js/events/Gui";
import { AdvancedSearchPersonComponent } from "../../widgets/AdvancedSearchPersonComponent";
import AutocompleteListSelector from "../../widgets/AutocompleteListSelector";
import { TypeOfAcquisition } from "../../../../auto/js/metadata/TypeOfAcquisition";
import { Tribe } from "../../../../auto/js/metadata/Tribe";

const gender = {1:"MALE", 2:"FEMALE"};
const maritalStatus = {1:"SINGLE", 2:"MARRIED", 3:"DIVORCED", 4:"WIDOWED"};

export const birthRegistrationFields = [
	{name: "tags", type:"tags", x:1, y:1, layout:"col-md-12"},
	{name: "childDetails", type: "custom", x:1, y:2, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
	{name: "image", type: "image", x: 1, y: 3, layout: "col-md-12" },
	{name: "firstname", type: "text", x:1, y:4, layout:"col-md-6",
		"validation": Yup.string().nullable().default(undefined).min(2, t`First name must be at least two characters long`).max(14, t`First name must be less than fifteen characters long`).required(t`First name is required`).matches(/[\p{Letter}\p{Mark}\s]+$/u, t`Only alphabets are allowed for this field `).matches(/^\p{Lu}.*$/u, t`First letter need to be Upper Case`)},
	{name: "secondname", type: "text", x:2, y:4, layout:"col-md-6",
		"validation": Yup.string().nullable().default(undefined).min(2, t`Middle name must be at least four characters long`).max(28, t`Middle name must be less than twenty eight characters long`).matches(/^\p{Lu}.*$/u, t`First letter need to be Upper Case`)},
	/* {name: "thirdname", type: "text", x:1, y: 5, layout:"col-md-6",
		"validation": Yup.string().nullable().default(undefined).min(2, t`Other name must be at least four characters long`).max(14, t`Other name must be less than fifteen characters long`).matches(/[\p{Letter}\p{Mark}\s]+$/u, t`Only alphabets are allowed for this field `).matches(/^\p{Lu}.*$/u, t`First letter need to be Upper Case`)}, */
	{name: "fourthname", type: "text", x:1, y: 5, layout:"col-md-6",
		"validation": Yup.string().nullable().default(undefined).min(2, t`Family name must be at least four characters long`).max(14, t`Family name must be less than fifteen characters long`).required(t`Family name is required`).matches(/[\p{Letter}\p{Mark}\s]+$/u, t`Only alphabets are allowed for this field `).matches(/^\p{Lu}.*$/u, t`First letter need to be Upper Case`)},
	{name: "fifthname", type: "text", x:2, y: 6, layout:"col-md-6",
		"validation": Yup.string().nullable().default(undefined).min(2, t`Marriage name must be at least four characters long`).max(14, t`Marriage name must be less than fifteen characters long`).matches(/[\p{Letter}\p{Mark}\s]+$/u, t`Only alphabets are allowed for this field `).matches(/^\p{Lu}.*$/u, t`First letter need to be Upper Case`)},
	{name: "gender", type: "select", x:1, y: 7, layout:"col-md-6", metadata: () => gender,
		"validation": Yup.string().nullable().default(undefined).required(t`Gender is required`)},
	{name: "birthdate", type: "date", x:1, y: 8, layout:"col-md-6",
		"validation": Yup.date().nullable().default(undefined).required('A date of birth is required')
	},
	{name: "birthTime", type: "time", x:2, y:8, layout:"col-md-6"},
	{name: "birthDayUnknown", type: "checkbox", x:1, y:9, layout:"col-md-4"},
    {name: "birthMonthUnknown", type: "checkbox", x:2, y:9, layout:"col-md-4"},
    {name: "birthYearUnknown", type: "checkbox", x:3, y:9, layout:"col-md-4"},
	{name: "disability", type: "checkbox", x:1, y:10, layout:"col-md-6"},
	{name: "tribe", type:"select", x:2, y:11, layout:"col-md-6", metadata: () => swapObject(Tribe)},
	{name:"citizenshipBox", label: "Citizenship", components: [
		{name: "primaryCitizenship", type:"custom", x:1, y:1, layout:"col-md-6", component: (name, disabled) => <CountryAutoCompleteInput name={name} readOnly={disabled} default={"121"}/>},
		{name: "typeOfAcquisition", type:"select", x:2, y:1, layout:"col-md-6", metadata: () => swapObject(TypeOfAcquisition)},
		{name: "otherCitizenshipCsv", type: "custom", x:1, y:3, layout: "col-md-6", component: (name, disabled) => <AutocompleteListSelector options={geoDataMetadataLoader.getRootNodes()} name={name} readOnly={disabled} label={t`otherCitizenship`} />},
	], type: "box", x:2, y:12, layout:"col-md-11 ms-2 mt-2 pt-1 pb-2"},
	{name:"birthBox", label: "Birth Place", components: [
       {name: "birthPlace", type: "custom", x:1, y:1, layout:"col-md-12", component: (name, disabled) => <GeoDataComponent name={name} disabled={disabled} default={"121"}/>}
    ], type: "box", x:2, y:13, layout:"col-md-11 ms-2 mt-2 pt-1 pb-2"},
	{name:"originBox", label: "Village Of Origin", components: [
		{name: "villageOfOrigin", type: "custom", x:1, y:1, layout:"col-md-12", component: (name, disabled) => <GeoDataComponent name={name} disabled={disabled} default={"121"}/>},
	], type: "box", x:1, y:14, layout:"col-md-11 ms-2 mt-2 pt-1 pb-2"},
	{name: "motherDetails", type: "custom", x:1, y:15, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
	{name:"motherInfo", options: [
        {"name":"motherWithBirthRecord","label":t`motherWithBirthRecord`},
        {"name":"motherWithoutBirthRecord","label":t`motherWithoutBirthRecord`},
		{"name":"unknownMother","label":t`unknownMother`},
    ], type: "radio", x:1, y:16, layout:"col-md-12 pt-4"},
	{name: "motherId", label: "Search Mother", type: "custom", x:1, y:17, layout:"col-md-12",
	 	component: (name, disabled) => <AdvancedSearchPersonComponent name={name} label={"Search Mother"} readOnly={disabled} loadData={loadPersonData}/>,
		display: (data) => {return (data.motherInfo === "motherWithBirthRecord" || data.motherId != null)?true: false;}},
	{name: "reportedMotherName", type: "text", x:2, y:18, layout:"col-md-12",
		display: (data) => {return data.motherInfo === "motherWithoutBirthRecord"?true: false;}
	},
	{name: "fatherDetails", type: "custom", x:1, y:19, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
	{name:"fatherInfo", options: [
        {"name":"fatherWithBirthRecord","label":t`fatherWithBirthRecord`},
        {"name":"fatherWithoutBirthRecord","label":t`fatherWithoutBirthRecord`},
		{"name":"unknownFather","label":t`unknownFather`},
    ], type: "radio", x:1, y:20, layout:"col-md-12 pt-4"},
	{name: "fatherId", label: "Search Father", type: "custom", x:1, y:21, layout:"col-md-12",
	 	component: (name, disabled) => <AdvancedSearchPersonComponent name={name} label={"Search Father"} readOnly={disabled} loadData={loadPersonData}/>,
		display: (data) => {return (data.fatherInfo === "fatherWithBirthRecord" || data.fatherId != null)?true: false;}},
	{name: "reportedFatherName", type: "text", x:2, y:22, layout:"col-md-12",
		display: (data) => {return data.fatherInfo === "fatherWithoutBirthRecord"?true: false;}
	},
	{name: "medicalNotification", type: "checkbox", x:1, y:24, layout:"col-md-12"},
	{name: "medicalNotificationFile", type: "file", x:1, y:25, layout:"col-md-12", 
		uploadUrl: (id) => getUploadUrl(id), 
		previewUrl: (id) => getPreviewUrl(id),
		loadData: async (id) => loadMedicalNotificationAttachment(id), 
		handleDelete:(id) => handleMedicalNotificationDelete(id), 
		handleClick: (id) => handleMedicalNotificationClick(id),
		display: (data) => {return data.medicalNotification},
		updateFileData: (data) => updateFileData(data)
	},
	{name: "witness1Id", type: "custom", x:1, y:26, layout:"col-md-12",
		"validation": Yup.string().nullable().default(undefined).when(["medicalNotification"],
			(medicalNotification) => {
				if (!medicalNotification) {
						return Yup.string().nullable().default(undefined).required('If no medical notification exists witness is required')
				}
			}
	), component: (name, disabled) => <AdvancedSearchPersonComponent name={name} label={"Search First Witness"} readOnly={disabled} loadData={loadPersonData}/>,
	display: (data) => {return !data.medicalNotification}
	}, 
    {name: "witness2Id", type: "custom", x:2, y:27, layout:"col-md-12",
		"validation": Yup.string().nullable().default(undefined).when(["medicalNotification"],
			(medicalNotification) => {
				if (!medicalNotification) {
					return Yup.string().nullable().default(undefined).required('If no medical notification exists witness is required')
			}
			}
		), component: (name, disabled) => <AdvancedSearchPersonComponent name={name} label={"Search Second Witness"} readOnly={disabled} loadData={loadPersonData}/>,
		display: (data) => {return !data.medicalNotification}
	},
	{name: "declarantDetails", type: "custom", x:1, y:28, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
	{name: "declarantId", type: "custom", x:1, y:29, layout:"col-md-12", component: (name, disabled) => <AdvancedSearchPersonComponent name={name} label={"Search Declarant"} readOnly={disabled} loadData={loadPersonData}/>},
];

const getUploadUrl = (id) => {
	return getServiceUri() + 'civil-status-mtlb/medical-notification-file' + '/' + id;
}

const getPreviewUrl = (id) => {
	return getServiceUri() + 'civil-status-mtlb/medical-notification-file' + '/preview/' + id;
}

const updateFileData = (data) => {
	let filter = {name: data.fileName, description: data.description};
	rest.request(getServiceUri() + 'civil-status-mtlb/medical-notification-file' + '/' + data.id, "PUT", filter);
}

const handleMedicalNotificationClick = async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	window.location = getServiceUri() + 'civil-status-mtlb/medical-notification-file' + '/' + id + '/' + token;
};
const handleMedicalNotificationDelete = (id) => {
	rest.delete('civil-status-mtlb/medical-notification-file', id).then(() => {
		DELETE_ATTACHMENT_EVENT.publish(id)
	});
};

const loadMedicalNotificationAttachment = async (id) => {
	let filter = {and: true};
	filter['civil-status-mtlb-medical-notification-file'] = {};
	filter['civil-status-mtlb-medical-notification-file']['civilStatusMtlbId'] = id;
	return rest.search('civil-status-mtlb/medical-notification-file', filter)
}

export const loadCivilStatusMtlbAttachment = async (id) => {
	let filter = {and: true};
	filter['civil-status-mtlb-attachment'] = {};
	filter['civil-status-mtlb-attachment']['civilStatusMtlbId'] = id;
	return rest.search('civil-status-mtlb/attachment', filter)
}

export const saveBirthRegistrationForm = async (formData) => {
	let dto = pojoMetadata['civil-status-mtlb'].form2dto(formData);
	dto.mtlbType = MTLB_TYPE_BIRTH_REGISTRATION;
	if (!formData.image.isEmpty) {
		let base64Image = formData.image.url;
		let faceMimeType = base64Image.substring(base64Image.indexOf(':') + 1, base64Image.indexOf(';'))
		let base64 = base64Image.substr(base64Image.indexOf(',') + 1);
		dto.face = base64;
		dto.faceMimeType = faceMimeType;
	}
	if (formData.middlename != null) {
		let names = formData.middlename.split(" ");
		dto.secondname = names[0]
		if (names.length > 1)
			dto.thirdname = names[1];
	}
	if (formData.birthCountry)
		dto.birthCountry = formData.birthCountry.key;
	try {
		return rest.request(getServiceUri() + 'apply/create-civil-status-mtlb', 'POST', dto)
	} catch (err) {
		alert(err);
	}
}

export const loadBirthRegistrationFormData = async (id) => {
	return await rest.read('civil-status-mtlb', id).then(response => {
		let form = response;
		let face = null;
		if (response.face != null) {
			let mimeType = response['faceMimeType'];
			face = "data:".concat(mimeType, ";base64,", response.face)
		}
		if (response.birthCountry != null) {
			let countryMetadata = metadataLoader.get('country');
			form.birthCountry = {key: response.birthCountry, value: countryMetadata[response.birthCountry]}
		}
		if (response.birthTime !== null)
			response.birthTime = new Date().setHours(response.birthTime[0], response.birthTime[1]);
		if (response.birthPlace == null)
			form.birthPlace = "121";
		form['image'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (face != null)?face: '/public/avatar.png', isEmpty: true};

		let tagFilter = {and: true};
		tagFilter['civil-status-mtlb-tag'] = {civilStatusMtlbId: id};
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error");
		return rest.search('civil-status-mtlb-tag', tagFilter).then(tags => {
			form['tags'] = tags
			if (tags.status)
				showNotification(response.message.split('Detail: ')[1], "error");
			return form;
		})
	})
}

export const updatePendingBirthRegistrationForm = async (formData) => {
	let dto = pojoMetadata['civil-status-mtlb'].form2dto(formData);
	form2dto(formData, dto);
	try {
		return rest.request(getServiceUri() + 'civil-status-mtlb/pending/update', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Updated Birth Registration`, "success");
		});
	} catch (err) {
		alert(err);
	}
}

export const updateBirthRegistrationForm = async (formData) => {
	let dto = pojoMetadata['civil-status-mtlb'].form2dto(formData);
	form2dto(formData, dto);
	try {
		return rest.update('civil-status-mtlb', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Created Birth Registration`, "success");
		});
	} catch (err) {
		alert(err);
	}
}

export const deleteBirthRegistrationForm = async (id) => {
	try {
		return rest.delete('civil-status-mtlb', id).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Deleted birth registration application`, "success");
		});
	} catch (err) {
		alert(err);
	}
}

export const updateRejectedBirthRegistrationForm = async (formData) => {
	//TODO: Move form2dto from pojo metadata
	let dto = pojoMetadata['civil-status-mtlb'].form2dto(formData);
	form2dto(formData, dto);
	try {
		return rest.request(getServiceUri() + 'civil-status-mtlb/rejected/update', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Updated Birth Registration`, "success");
		});
	} catch (err) {
		alert(err);
	}
}

export const rejectBirthRegistrationForm = async (id) => {
	try {
		return rest.request(getServiceUri() + 'civil-status-mtlb/pending/reject', 'POST', id);
	} catch (err) {
		alert(err);
	}
}

export const rejectReadyBirthRegistrationForm = async (id) => {
	try {
		return rest.request(getServiceUri() + 'civil-status-mtlb/ready/reject', 'POST', id);
	} catch (err) {
		alert(err);
	}
}

export const approveReadyBirthRegistrationForm = async (id) => {
	try {
		return rest.request(getServiceUri() + 'civil-status-mtlb/ready/approve', 'POST', id);
	} catch (err) {
		alert(err);
	}
}

export const buildBirthRegistrationFormEmptyObject  = () => {
	const empty = buildEmptyObject(birthRegistrationFields);
	empty['image'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url:'/public/avatar.png', isEmpty: true};
	empty['birthPlace'] = "121";
	return empty;
}
export const form2dto = (formData, dto) => {
	if (dto.mtlbType === null || !dto.mtlbType)
		dto.mtlbType = MTLB_TYPE_BIRTH_REGISTRATION;
	if (!formData.image.isEmpty) {
		let base64Image = formData.image.url;
		let faceMimeType = base64Image.substring(base64Image.indexOf(':') + 1, base64Image.indexOf(';'))
		let base64 = base64Image.substr(base64Image.indexOf(',') + 1);
		dto.face = base64;
		dto.faceMimeType = faceMimeType;
	}
	if (formData.birthCountry)
		dto.birthCountry = formData.birthCountry.key;
	if (formData.birthTime != null && typeof(formData.birthTime) != 'string') {
		const date = new Date(formData.birthTime)
		let birthHour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
		let birthMinute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
		dto.birthTime = birthHour + ":" + birthMinute;
	}
	if (formData.middlename != null) {
		let names = formData.middlename.split(" ");
		dto.secondname = names[0]
		if (names.length > 1)
			dto.thirdname = names[1];
	}
	if (formData.otherCitizenshipCsv !== null && formData.otherCitizenshipCsv) {
		let otherCitizenshipCsv = formData.otherCitizenshipCsv.join(',');
		dto.otherCitizenshipCsv = otherCitizenshipCsv;
	}
	if (formData.motherInfo == "unknownMother")
		dto.unknownMother = true;
	if (formData.fatherInfo == "unknownFather")
		dto.unknownFather = true;

	if (formData.typeOfAcquisition != null)
        dto.typeOfAcquisition = swapObject(TypeOfAcquisition)[formData.typeOfAcquisition]
	
	if (formData.tribe != null)
        dto.tribe = swapObject(Tribe)[formData.tribe]
}

export const dto2form = (dto) => {
	let form = dto;
  let face = null;
  if (dto.face != null) {
    let mimeType = dto['faceMimeType'];
    face = "data:".concat(mimeType, ";base64,", dto.face)      
  }
  if (dto.birthTime !== null) {
    dto.birthTime = new Date().setHours(dto.birthTime[0], dto.birthTime[1]);
  }

  if (dto.otherCitizenshipCsv == null || dto.otherCitizenshipCsv === "")
  	dto.otherCitizenshipCsv = []
  else {
	let values = [];
	let components = dto.otherCitizenshipCsv.split(",");
	components.forEach(element => {
		values.push(parseInt(element))
	});
	dto.otherCitizenshipCsv = values;
  }
 
  form['image'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (face != null)?face: '/public/avatar.png', isEmpty: (face == null)};
  
  	if (dto.motherId == null) {
		if (dto.reportedMotherName != null)
  			dto.motherInfo = "motherWithoutBirthRecord"
		else if (dto.unknownMother != null && dto.unknownMother)
			dto.motherInfo = "unknownMother"
		else
			dto.motherInfo = "motherWithoutBirthRecord"
	} else
		dto.motherInfo = "motherWithBirthRecord"

	if (dto.fatherId == null) {
		if (dto.reportedFatherName != null)
			dto.fatherInfo = "fatherWithoutBirthRecord"
		else if (dto.unknownFather != null && dto.unknownFather)
			dto.fatherInfo = "unknownFather"
		else
			dto.fatherInfo = "fatherWithoutBirthRecord"
	} else
		dto.fatherInfo = "fatherWithBirthRecord"

	if (dto.typeOfAcquisition != null)
        form.typeOfAcquisition = TypeOfAcquisition[dto.typeOfAcquisition]
	else
		form.typeOfAcquisition = 9

		
	if (dto.tribe != null)
		form.tribe = Tribe[dto.tribe]

		form['leftThumbImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (getFormFinger(dto.leftThumb) != null)?getFormFinger(dto.leftThumb): '/public/finger-not-found.png', isEmpty: true, width:70, height:70}
		form['leftIndexFingerImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (getFormFinger(dto.leftIndexFinger) != null)?getFormFinger(dto.leftIndexFinger): '/public/finger-not-found.png', isEmpty: true, width:70, height:70}
		form['leftMiddleFingerImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (getFormFinger(dto.leftMiddleFinger) != null)?getFormFinger(dto.leftMiddleFinger): '/public/finger-not-found.png', isEmpty: true, width:70, height:70}
		form['leftRingFingerImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (getFormFinger(dto.leftRingFinger) != null)?getFormFinger(dto.leftRingFinger): '/public/finger-not-found.png', isEmpty: true, width:70, height:70}
		form['leftPinkyImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (getFormFinger(dto.leftPinky) != null)?getFormFinger(dto.leftPinky): '/public/finger-not-found.png', isEmpty: true, width:70, height:70}
		form['rightThumbImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (getFormFinger(dto.rightThumb) != null)?getFormFinger(dto.rightThumb): '/public/finger-not-found.png', isEmpty: true, width:70, height:70}
		form['rightIndexFingerImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (getFormFinger(dto.rightIndexFinger) != null)?getFormFinger(dto.rightIndexFinger): '/public/finger-not-found.png', isEmpty: true, width:70, height:70}
		form['rightMiddleFingerImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (getFormFinger(dto.rightMiddleFinger) != null)?getFormFinger(dto.rightMiddleFinger): '/public/finger-not-found.png', isEmpty: true, width:70, height:70}
		form['rightRingFingerImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (getFormFinger(dto.rightRingFinger) != null)?getFormFinger(dto.rightRingFinger): '/public/finger-not-found.png', isEmpty: true, width:70, height:70}
		form['rightPinkyImage'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (getFormFinger(dto.rightPinky) != null)?getFormFinger(dto.rightPinky): '/public/finger-not-found.png', isEmpty: true, width:70, height:70}
  return form;
}

const getFormFinger = (fingerData) => {
	let finger = null;
	if (fingerData !== null) {
		let mimeType = "image/png"
		finger = "data:".concat(mimeType, ";base64,", fingerData)
	}
	return finger;
}

const Section = ({name}) => {
	return (
		<>
		<div class="col-md-12 sectiontitle">
			<h2 style={{ display: "inline" }}>{t(name)}</h2>
		</div>
		</>
	)
}

export const newBirthRegistrationFormForm2Dto = (form, dto) => {
	
}