import React from 'react';

import styled from 'styled-components';
import { createBrowserComponent } from "./Browser";
import { StepperComponent } from "./StepperComponent";

const Main = styled.main`
    position: relative;
    overflow: hidden;
    direction: ltr;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px
`;

export class StepperBrowser extends createBrowserComponent() {
    constructor(props) {
        super(props)
        this.state.activeStep = 0;
        this.tasks = [];
        this.gui = props.gui;
    }

    handleCompleteTask = (taskId) => {
        if (this.tasks.length > taskId + 1) {
          this.setActiveTask(taskId + 1);
          this.tasks[taskId + 1].start(this.handleCompleteTask);
        } 
        else
          this.gui.goTo(this.gui.readWelcomePage);
    };
  
    start = () => {
    this.tasks[0].start(this.handleCompleteTask);
    }

    getActiveTask = () => {
    return this.state.activeTask
    }

    setActiveTask = (activeTask) => {
    this.setState({activeStep: activeTask});
    }

    render() {
        return (
            <>
              <div className='browser-tabs-header'>
                {(this.props.stepperHeader) && this.props.stepperHeader}
                <StepperComponent steps={this.props.steps} activeStep={this.state.activeStep} />
              </div>
              <Main>
                {this.buildDesktop()}
              </Main>
          </>
        )
    }
}