import React from "react";

import { buildEmptyObject } from "../../../../auto/js/widgets/FormComponent";
import { getServiceUri, pojoMetadata } from "../../../../auto/js/metadata";
import { MTLB_TYPE_DIVORCE } from "../../../../auto/js/metadata/MtlbType";
import { rest, t } from "../../../../auto/js/services";
import { showNotification } from "../../../../auto/js/utils";
import { MTLB_STATUS_SUBMITTED } from "../../../../auto/js/metadata/MtlbStatus";

export const divorceRegistrationFields = [
    {name: "tags", type:"tags", x:1, y:1, layout:"col-md-12"},
    {name: "unionRecordId", type: "number", x:1, y:2, layout:"col-md-12"},
    {name: "dateOfEffect", type: "date", x:1, y:3, layout:"col-md-6"},
    {name: "dateOfOrder", type: "date", x:2, y:3, layout:"col-md-6"}
];

export const saveDivorceRegistrationForm = async (formData) => {
    let dto = pojoMetadata['union-mtlb'].form2dto(formData);
    dto.mtlbType = MTLB_TYPE_DIVORCE;
    try {
        return rest.request(getServiceUri() + 'union-mtlb', 'POST', dto)
    } catch (err) {
        alert(err);
    }
}

export const loadDivorceRegistrationFormData = async (id) => {
    return await rest.read('union-mtlb', id).then(response => {
        let form = response;
        let tagFilter = {and: true};
        tagFilter['union-mtlb-tag'] = {unionMtlbId: id};
        if (response.status)
            showNotification(response.message.split('Detail: ')[1], "error")
        
        return rest.search('union-mtlb-tag', tagFilter).then(tags => {
            form['tags'] = tags
            if (tags.status)
                showNotification(response.message.split('Detail: ')[1], "error");
            return form;
        })
    })
}

export const updatePendingDivorceRegistrationForm = async (formData) => {
    let dto = pojoMetadata['union-mtlb'].form2dto(formData);
    try {
        return rest.request(getServiceUri() + 'union-mtlb/pending/update', 'POST', dto).then((response) =>{
            if (response.status)
                showNotification(response.message.split('Detail: ')[1], "error")
            else
                showNotification(t`Updated Divorce Registration`, "success");
        });
    } catch (err) {
        alert(err);
    }
}

export const updateRejectedDivorceRegistrationForm = async (formData) => {
    let dto = pojoMetadata['union-mtlb'].form2dto(formData);
    try {
        return rest.request(getServiceUri() + 'union-mtlb/rejected/update', 'POST', dto).then((response) =>{
            if (response.status)
                showNotification(response.message.split('Detail: ')[1], "error")
            else
                showNotification(t`Reopened Divorce Registration`, "success");
        });
    } catch (err) {
        alert(err);
    }
}

export const rejectDivorceRegistrationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'union-mtlb/pending/reject', 'POST', id).then((response) =>{
            if (response.status)
                showNotification(response.message.split('Detail: ')[1], "error")
            else
                showNotification(t`Rejected Divorce Registration`, "success");
        });
    } catch (err) {
        alert(err);
    }
}

export const buildDivorceRegistrationFormEmptyObject  = () => {
    const empty = buildEmptyObject(divorceRegistrationFields);
    return empty;
}

export const updateDivorceRegistrationForm = async (formData) => {
    let dto = pojoMetadata['union-mtlb'].form2dto(formData);
    dto.mtlbStatus = MTLB_STATUS_SUBMITTED;
    dto.draft = false;
    try {
        return rest.update('union-mtlb', dto).then((response) =>{
            if (response.status)
                showNotification(response.message.split('Detail: ')[1], "error")
            else
                showNotification(t`Created Divorce Registration`, "success");
        });
    } catch (err) {
        alert(err);
    }
}

export const deleteDivorceRegistrationForm = async (id) => {
    try {
        return rest.delete('union-mtlb', id);
    } catch (err) {
        alert(err);
    }
}

export const form2dto = (formData, dto) => {
	dto.mtlbType = MTLB_TYPE_DIVORCE;
	if(formData.dateOfEffect != null && typeof(formData.dateOfEffect) != 'string') {
		let dateOfEffectAsDate = new Date(formData.dateOfEffect);
		dto.dateOfEffect = dateOfEffectAsDate.getFullYear() + "-" + ('0' + (dateOfEffectAsDate.getMonth() + 1)).slice(-2) + "-" + ('0' + dateOfEffectAsDate.getDate()).slice(-2);
	}
    if(formData.dateOfOrder != null && typeof(formData.dateOfOrder) != 'string') {
		let dateOfOrderAsDate = new Date(formData.dateOfOrder);
		dto.dateOfOrder = dateOfOrderAsDate.getFullYear() + "-" + ('0' + (dateOfOrderAsDate.getMonth() + 1)).slice(-2) + "-" + ('0' + dateOfOrderAsDate.getDate()).slice(-2);
	}
}

export const dto2form = (response) => {
	return response;
    
}

export const newDivorceRegistrationFormForm2Dto = (form, dto) => {
    
}