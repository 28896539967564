import React from "react";
import { getServiceUri } from "../../../../auto/js/metadata";
import { MTLB_TYPE_MARRIAGE } from "../../../../auto/js/metadata/MtlbType";
import { UNION_MTLB_ORDER_BY_PARTNER1_FIRSTNAME } from "../../../../auto/js/metadata/UnionMtlbOrderBy";
import { rest } from "../../../../auto/js/services";


export const marriageRegistrationListColumns = [
    {title: "partner1VitalRecordId", field: "partner1VitalRecordId"},
    {title: "partner1Firstname", field: "partner1Firstname"},
    {title: "partner1Surname", field: "partner1Surname"},
    {title: "partner2VitalRecordId", field: "partner2VitalRecordId"},
    {title: "partner2Firstname", field: "partner2Firstname"},
    {title: "partner2Surname", field: "partner2Surname"},
    {title: "place", field: "place"},
];


export const getData = async (filter) => {
    let searchEndpoint = (filter.query)?"union-mtlb/advanced-search":"union-mtlb/search-by-union-mtlb-type-and-status/";
    return await rest.request(getServiceUri() + searchEndpoint, "POST", filter);
}

export const countData = async (filter) => {
    let countEndpoint = (filter.query)?"union-mtlb/count/advanced-search":"union-mtlb/count/search-by-union-mtlb-type-and-status/";
    return await rest.request(getServiceUri() + countEndpoint, "POST", filter);
}

export const getOrderBy = () => {
    return UNION_MTLB_ORDER_BY_PARTNER1_FIRSTNAME
}

export const getMtlbTypeList = () => {
    return [MTLB_TYPE_MARRIAGE]
}

export const filterData = (DefaultRows) => {
    return DefaultRows;
}

export const getErrorList = () => {
    let list = [];
    for (let i = 23; i < 32; i++) {
        let code = "E" + i
        list.push(code);
    }
    return list;
}

export const advancedSearchfields = [ 
    {name: "partner1VitalRecordId", type: "number", x:1, y:1, layout:"col-md-6"},
    {name: "partner2VitalRecordId", type: "number", x:2, y: 1, layout:"col-md-6"},
    {name: "partner1Firstname", type: "text", x:1, y:2, layout:"col-md-6"},
    {name:"partner1FirstnameCondition", options: [
        {"name":"fuzzy","label":"Fuzzy"},
        {"name":"exact","label":"Exact"},
        {"name":"startsWith","label":"Startswith"},
    ], type: "radio", x:2, y:2, layout:"col-md-6 pt-5",defaultValue:"fuzzy"},
    {name: "partner1Surname", type: "text", x:1, y: 3, layout:"col-md-6"},
    {name:"partner1SurnameCondition", options: [
    {"name":"fuzzy","label":"Fuzzy"},
    {"name":"exact","label":"Exact"},
    {"name":"startsWith","label":"Startswith"},
    ], type: "radio", x:2, y:3, layout:"col-md-6 pt-5",defaultValue:"fuzzy"},
    {name: "partner2Firstname", type: "text", x:1, y:4, layout:"col-md-6"},
    {name:"partner2FirstnameCondition", options: [
        {"name":"fuzzy","label":"Fuzzy"},
        {"name":"exact","label":"Exact"},
        {"name":"startsWith","label":"Startswith"},
    ], type: "radio", x:2, y:4, layout:"col-md-6 pt-5",defaultValue:"fuzzy"},
    {name: "partner2Surname", type: "text", x:1, y: 5, layout:"col-md-6"},
    {name:"partner2SurnameCondition", options: [
    {"name":"fuzzy","label":"Fuzzy"},
    {"name":"exact","label":"Exact"},
    {"name":"startsWith","label":"Startswith"},
    ], type: "radio", x:2, y:5, layout:"col-md-6 pt-5",defaultValue:"fuzzy"},

    {name:"marriageBox", label: "Effect Date", components: [
        {name: "rangemarriagedate",label:"exactmarriagedate", type: "checkbox", x:1, y:1, layout:"col-md-12"},
        {name: "exactmarriagedate", type: "date", x:1, y: 2, layout:"col-md-6", display: (data)=>{
            return !data.rangemarriagedate;
        }},
        
        {name: "marriagedateStart", type: "date", x:1, y: 3, layout:"col-md-6",display: (data)=>{
            return data.rangemarriagedate;
        }},
        {name: "marriagedateEnd", type: "date", x:2, y: 3, layout:"col-md-6", display: (data)=>{
            return data.rangemarriagedate;
        }},
    ], type: "box", x:1, y:7, layout:"col-md-11 ms-2 mt-2 pt-1 pb-2"},
];

export const transformAdvancedSearchData = (formData) => {
    const columns = [];
    
    const addColumn = (name, type, value, condition) => {
        columns.push({
        name,
        type,
        [type === 'date' ? 'dateValue' : type === 'boolean' ? 'booleanValue' : (type === 'text' ? 'textValue' : 'longValue')]: value,
        searchType: condition ? condition : null,
        });
    };
    (formData.partner1VitalRecordId !== null) && addColumn('partner1VitalRecordId', 'number', formData.partner1VitalRecordId, null);
    (formData.partner2VitalRecordId !== null) && addColumn('partner2VitalRecordId', 'number', formData.partner2VitalRecordId, null);

    (formData.partner1Firstname !== "") && addColumn('partner1Firstname', 'text', formData.partner1Firstname, formData.partner1FirstnameCondition);
    
    (formData.partner2Firstname !== "") && addColumn('partner1Firstname', 'text', formData.partner2Firstname, formData.partner2FirstnameCondition);
    
    (formData.partner1Surname !== "") && addColumn('partner1Surname', 'text', formData.partner1Surname, formData.partner1SurnameCondition);
    
    (formData.partner2Surname !== "") && addColumn('partner2Surname', 'text', formData.partner2Surname, formData.partner2SurnameCondition);
    addColumn('mtlbType', 'number', 9, null);
    addColumn('draft', 'boolean', false, null);
    const marriagedateRange = formData.marriagedateStart && formData.marriagedateEnd
        ? {
                min: formData.marriagedateStart ? formData.marriagedateStart : null,
                max: formData.marriagedateEnd ? formData.marriagedateEnd : null,
            }
        : null;
    
    (marriagedateRange != null || formData.exactmarriagedate != null) && addColumn('declaredDate', 'date', formData.exactmarriagedate, null);
    if ((marriagedateRange != null || formData.exactmarriagedate != null))
        columns[columns.length - 1].dateRange = marriagedateRange;
    
    return { columns };
};