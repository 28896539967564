import React, { useEffect, useRef, useState } from "react";
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import { Dashboard } from '@uppy/react';

// Don't forget the CSS: core and the UI components + plugins you are using.
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/webcam/dist/style.min.css';
import { AttachmentsArea } from "../../../auto/js/widgets";
import { rest } from "../../../auto/js/services";
import { FILE_UPLOADED_EVENT } from "../../../auto/js/events/Gui";
import './file.css';

  
export const FileInput = (props) => {

    const uppy = new Uppy().use(XHRUpload, {}).use(ThumbnailGenerator, {
      thumbnailWidth: 100, // Adjust the thumbnail width as needed'
    });;

    uppy.getPlugin("XHRUpload").setOptions({
        endpoint: props.uploadUrl
    })
    
    uppy.on('file-added', (file) => {
        uppy.getPlugin("XHRUpload").setOptions({
          headers: {
            'Authorization': `Bearer ${rest.getBearerToken()}`,
          }
        })
      })
    uppy.on('thumbnail:generated', (file, preview) => {
      fetchBlobAsUint8Array(preview).then((blob) => {
        uppy.setFileMeta(file.id, {
          preview: blob
        });
        uppy.upload();
      })
    });
    uppy.on('upload-success', (file, response) => {
        FILE_UPLOADED_EVENT.publish({id: response.body.id, name: file.name});
    })

    return (
		<>
      <AttachmentsArea {...props}/>
{		(!props.readOnly)?<Dashboard uppy={uppy} restrictions={{ maxNumberOfFiles: 1 }} showSelectedFiles={false}/>:null
}		</>
	)
}

async function fetchBlobAsUint8Array(blobUrl) {
  const response = await fetch(blobUrl);
  return await response.blob();
}