import React from "react";
import {v4 as uuidv4} from 'uuid';

import { createFormComponent } from '../../widgets/FormComponent';
import { OPEN_VIEW_EVENT } from '../../events/Gui';
import { rest } from "../../services/RestClient";
import { t } from "../../services/i18ndb";
import { showNotification } from "../../utils";
import { getServiceUri } from "../../metadata";
import { updateDeathRegistrationForm, loadDeathRegistrationFormData } from "./DeathRegistrationApi";
import { deathRegistrationFields } from "../../../../main/js/forms/deathRegistration/DeathRegistrationFormCommon";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AttachmentsArea } from "../../widgets/Attachments";
import { Section } from "../../../../auto/js/widgets/Section";

import { createTableComponent } from "../../widgets/TableComponent";
import { loadCivilStatusMtlbAttachment } from "../../../../main/js/forms/deathRegistration/DeathRegistrationFormCommon";

let DeathRegistrationForm = createFormComponent(deathRegistrationFields);
	
class FormComponent extends React.Component {
	constructor(props) {
		super(props);
    }

	render() {
		return (
		<>
			<img alt="approved.png" src="/public/approved.png" style={{maxWidth: '130px'}}/>
			<DeathRegistrationForm key={this.props.key} loadData={async () => loadFormData(this.props.id)} readOnly onSubmit={() => null} id={this.props.id} buttons={() => null} />
            <Section name={t`Extras`} />
            <AttachmentsArea previewUrl={(id) => getPreviewUrl(id)} loadData={async () => loadCivilStatusMtlbAttachment(this.props.id)} handleClick={(id) => handleCivilStatusMtlbAttachmentClick(id)} readOnly/>
		</>
		)
	}

}

const handleCivilStatusMtlbAttachmentClick = async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	window.location = getServiceUri() + 'civil-status-mtlb/attachment' + '/' + id + '/' + token;
};
const getPreviewUrl = (id) => {
	return getServiceUri() + 'civil-status-mtlb/attachment' + '/preview/' + id;
}
	

export const displayReadApprovedDeathRegistrationForm = (onFinish) => (id) => {
	let uuid = uuidv4();
	return {
		uuid, view: () => <FormComponent key={uuid} id={id} />
	};
}

const loadFormData = async (id) => {
	return await loadDeathRegistrationFormData(id);
}
