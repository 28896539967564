import * as React from 'react';
import QRCode from "react-qr-code";
import {t} from "../../../auto/js/services";
import {birthCertificateContent} from "./CivilRecordFormCommon";
import { geoDataMetadataLoader } from '../../../auto/js/metadata/GeoDataMetadataLoader';
import { Tribe } from '../../../auto/js/metadata/Tribe';

const formatDate = (date) => {
	let d = new Date(date[0], date[1]-1, date[2]);
	return d.toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
}

const formatLocation = (location) => {
	let birthPlace = [];
	if (location != null) {
		let birthPlaceComponents = location.split(".");
		let place = location;
		birthPlace.push(
			(geoDataMetadataLoader.getArea(place))?t(geoDataMetadataLoader.getArea(place).name + " "):t("INVALID DATA ")
		)
		for (let i = 0; i < birthPlaceComponents.length - 1 ; i ++) {
			let parentAreaId = place.substring(0, place.lastIndexOf("."));
			place = parentAreaId;
			birthPlace.push(
				(geoDataMetadataLoader.getArea(parentAreaId))?t(geoDataMetadataLoader.getArea(parentAreaId).name + " "):t("INVALID DATA ")
			)                
		}
	}
	else
		birthPlace.push(<p>{t`Unknown Birth Place`}</p>)
	return birthPlace;
}

export class BirthCertificate extends React.Component {
	constructor(props) {
		super(props);
		this.data = props.data;
	}

	// @ts-ignore
	render() {
		let birthdate = this.data.birthdate? formatDate(this.data.birthdate):'Unknown';
		let motherBirthdate = this.data.motherBirthdate? formatDate(this.data.motherBirthdate):'Unknown';
		let fatherBirthdate = this.data.fatherBirthdate? formatDate(this.data.fatherBirthdate):'Unknown';
		let declarantDate = (this.data.timestamp && this.data.timestamp !== null)? formatDate(this.data.timestamp):'Unknown';
		var currentdate = new Date();
		var datetime = currentdate.getDate() + "/"
			+ (currentdate.getMonth() + 1) + "/"
			+ currentdate.getFullYear() + " @ "
			+ currentdate.getHours() + ":"
			+ currentdate.getMinutes() + ":"
			+ currentdate.getSeconds();
		let fullname = '';
		fullname += this.data.firstname ? this.data.firstname + ' ' : '';
		fullname += this.data.secondname ? this.data.secondname + ' ' : '';
		fullname += this.data.fourthname ? this.data.fourthname + ' ' : '';
		let declarantFullName = '';
		if (this.data.declarantId != null) {
			declarantFullName += this.data.declarantFirstname ? this.data.declarantFirstname + ' ' : '';
			declarantFullName += this.data.declarantSecondname ? this.data.declarantSecondname + ' ' : '';
			declarantFullName += this.data.declarantFourthname ? this.data.declarantFourthname + ' ' : '';
		}
		let witness1FullName = '';
		if (this.data.witness1Id != null) {
			witness1FullName += this.data.witness1Firstname ? this.data.witness1Firstname + ' ' : '';
			witness1FullName += this.data.witness1Secondname ? this.data.witness1Secondname + ' ' : '';
			witness1FullName += this.data.witness1Fourthname ? this.data.witness1Fourthname + ' ' : '';
		}
		let witness2FullName = '';
		if (this.data.witness2Id != null) {
			witness2FullName += this.data.witness2Firstname ? this.data.witness2Firstname + ' ' : '';
			witness2FullName += this.data.witness2Secondname ? this.data.witness2Secondname + ' ' : '';
			witness2FullName += this.data.witness2Fourthname ? this.data.witness2Fourthname + ' ' : '';
		}
		let registrarFullName = '';
		if (this.data.registrarId != null) {
			registrarFullName += this.data.registrarFirstName ? this.data.registrarFirstName + ' ' : '';
			registrarFullName += this.data.registrarFourthName ? this.data.registrarFourthName + ' ' : '';
		}
		let childGender = (this.data.gender == 1)?'MALE':'FEMALE';
		if (this.data.motherId == null) {
			if (this.data.reportedMotherName != null) {
				var names = this.data.reportedMotherName.split(" ")
				this.data.motherFirstname = names[0];
				if (names[2]) {
					this.data.motherThirdname = names[1];
					this.data.motherFourthname = names[2];
				}
				else if (names[1])
					this.data.motherFourthname = names[1]
			}
		}
		if (this.data.fatherId == null) {
			if (this.data.reportedFatherName != null) {
				var names = this.data.reportedFatherName.split(" ")
				this.data.fatherFirstname = names[0];
				if (names[2]) {
					this.data.fatherThirdname = names[1];
					this.data.fatherFourthname = names[2];
				}
				else if (names[1])
					this.data.fatherFourthname = names[1]
			}
		}
		return (
			<div className="page">
				<div className="header">
					<div className="headercol1">
						<h2 className="lefttext"> {birthCertificateContent.leftTitle1}<br/>
							{birthCertificateContent.leftTitle2}</h2></div>
					<div className="headercol2 logos">

					</div>
					<div className="headercol3">
						<h2 className="righttext">{birthCertificateContent.rightTitle1}<br/>{birthCertificateContent.rightTitle2}</h2>
					</div>
					<div className="headercol4">

					</div>
					<div className="headercol5">
						<h1>{birthCertificateContent.mainTitle}</h1>
						<h2>{birthCertificateContent.mainSubTitle}</h2>
					</div>
					<div className="headercol6">
						<h2 className="righttext idnumber1">{t`National ID Number`}: {this.data.id}</h2>
					</div>
				</div>
				<div className="bodycontent">
					<div className="bodycol1">
						<h1 className="lefttext">{t`Child`}</h1>
						<div className="nestedcol1">
							<p>{t`Surname`}<br/>
								{t`First Name(s)`}<br/>
								{t`Melanesian Name`}<br/>
								{t`Sex`}<br/>
								{t`Tribe`}<br/>
								{t`National ID Number`}<br/>
							</p>
							<p>
								{t`Date of birth`} <br/>
								{t`Time of birth`} <br/>
								{t`Birth Location`}<br/>
							</p>
							
						</div>
						<div className="nestedcol2">
							<p> {this.data.fourthname}<br/>
								{this.data.firstname}<br/>
								{this.data.thirdname}<br/>
								{childGender}<br/>
								{this.data.tribe}<br/>
								{this.data.id}<br/>
							</p>
							<p>
								{birthdate}<br/>
								{this.data.birthtime}<br/>
								{formatLocation(this.data.birthPlace)}<br/>
							</p>
							<p>
							</p>
						</div>
					</div>

					<div className="bodycol2">
						<h1 className="lefttext">{t`Mother`}</h1>
						<div className="nestedcol1">
							{(this.data.motherId != null)?
							<>
							<p>{t`Surname`}<br/>
								{t`First Name(s)`}<br/>
								{t`Other Name`}<br/>
								{t`National ID Number`}<br/>
								{t`Tribe`}<br/>
							</p>
							<p>
								{t`Date of birth`} <br/>
								{t`Birth Location`}<br/>
								{t`Citizenship`} <br/>
								{t`Parents of mother`}<br/>
							</p>
							<p>
								{t`Occupation`}
							</p></>:
							<>
							<p>
								{t`Reported Name(s)`}<br/>
							</p>
							</>}
						</div>
						<div className="nestedcol2">
							{(this.data.motherId != null)?
							<>
							<p>{this.data.motherFourthname}<br/>
								{this.data.motherFirstname}<br/>
								{this.data.motherThirdname}<br/>
								{this.data.motherId}<br/>
								{this.data.motherTribe}<br/>
							</p>
							<p>
								{motherBirthdate}<br/>
								{formatLocation(this.data.motherBirthPlace)}<br/>
								<br/>
								{this.data.grandFatherMotherFirstname} ,{this.data.grandMotherMotherFirstname}<br/>
								<br/>
							</p>
							<p>

							</p></>:
							<>
							<p>{this.data.reportedMotherName}<br/>
							</p>
							<p>

							</p></>}
						</div>
					</div>
					<div className="bodycol3">
						<h1 className="lefttext">{t`Father`}</h1>
						<div className="nestedcol1">
						{(this.data.fatherId != null)?
							<>
							<p>{t`Surname`}<br/>
								{t`First Name(s)`}<br/>
								{t`Other Name`}<br/>
								{t`National ID Number`}<br/>
								{t`Tribe`}<br/>
							</p>
							<p>
								{t`Date of birth`} <br/>
								{t`Birth Location`}<br/>
								{t`Citizenship`} <br/>
								{t`Parents of mother`}<br/>
							</p>
							<p>
								{t`Occupation`}
							</p></>:
							<>
							<p>
								{t`Reported Name(s)`}<br/>
							</p>
							</>}
						</div>
						<div className="nestedcol2">
							{(this.data.fatherId != null)?
							<>
							<p>{this.data.fatherFourthname}<br/>
								{this.data.fatherFirstname}<br/>
								{this.data.fatherThirdname}<br/>
								{this.data.fatherId}<br/>
								{this.data.fatherTribe}<br/>
							</p>
							<p>
								{fatherBirthdate}<br/>
								{formatLocation(this.data.fatherBirthPlace)}<br/>
								<br/>
								{this.data.grandFatherFatherFirstname} ,{this.data.grandMotherFatherFirstname}<br/>
								<br/>
							</p>
							<p>

							</p></>:
							<>
							<p>{this.data.reportedFatherName}<br/>
							</p>
							<p>

							</p></>}
						</div>
					</div>
					<div className="bodycol4">
						<h1 className="lefttext">{t`Witnesses`}</h1>
						<div className="nestedcol1">
							<h3>{t`Witness 1`}</h3>
							<p className="bottom">{t`Full Name`}<br/>
								{t`National ID Number`}
							</p>
							<h3>{t`Witness 2`}</h3>
							<p className="bottom">{t`Full Name`}<br/>
								{t`National ID Number`}
							</p>
						</div>
						<div className="nestedcol2">
							<h3>&nbsp;</h3>
							<p className="bottom">{witness1FullName}<br/>
								{this.data.witness1Id}
							</p>
							<h3>&nbsp;</h3>
							<p className="bottom">{witness2FullName}<br/>
								{this.data.witness2Id}
							</p>
						</div>
					</div>
					<div className="bodycol5">
						<h1 className="lefttext">{t`Declaration`}</h1>
						{(this.data.declarantId != null)?
							<>
							<div className="nestedcol1">
							<p>{t`Full Name`}<br/>
								{t`National ID Number`}<br/>
								{t`Date`}<br/>
								{t`Place`}<br/>
							</p>
						</div>
						<div className="nestedcol2">
							<p> {declarantFullName} <br/>
								{this.data.declarantId}<br/>
								{declarantDate}<br/>
								<br/>
							</p>
							<p>
								<br/>
								<br/>
							</p>
						</div>
						</>:
						<p>{t`No Declarant Found`}<br/></p>}
					</div>
					<div className="bodycol6">
						<div className="official">
							<div className="qr-code">
								<QRCode value={fullname + "\nCertificate issued on " + datetime + " - Signed by Director " + registrarFullName} size={80}/>
							</div>
							<img src={this.data.signatureUrl}/>
						</div>
						<p className="lefttext idnumber1">Certified to be a true copy issued without alteration
							at </p><p className="strongstyle"><strong>{registrarFullName}<br/>Registrar General</strong></p>

					</div>

				</div>
			</div>

		)
	}
}