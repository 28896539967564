export const pojoMetadata = {
			'occupation': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "description", name: "description", editable: true, resizable: true, type: "text" },
			   	{ key: "occupationId", name: "occupation_id", editable: true, resizable: true, type: "select", metadata: "occupation-metadata"  },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.description == "")
					data.description = null;
				return data
			}
		},
		'death-record-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "address", name: "address", editable: true, resizable: true, type: "number" },
			   	{ key: "birthCountry", name: "birth_country", editable: true, resizable: true, type: "number" },
			   	{ key: "birthDayUnknown", name: "birth_day_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthMonthUnknown", name: "birth_month_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthPlace", name: "birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "birthTime", name: "birth_time", editable: true, resizable: true, type: "time" },
			   	{ key: "birthTimeUnknown", name: "birth_time_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthYearUnknown", name: "birth_year_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthdate", name: "birthdate", editable: true, resizable: true, type: "date" },
			   	{ key: "cardId", name: "card_id", editable: true, resizable: true, type: "number" },
			   	{ key: "commonIdNumber", name: "common_id_number", editable: true, resizable: true, type: "text" },
			   	{ key: "dateOfDeath", name: "date_of_death", editable: true, resizable: true, type: "date" },
			   	{ key: "deathDayUnknown", name: "death_day_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathMonthUnknown", name: "death_month_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathTimeUnknown", name: "death_time_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathYearUnknown", name: "death_year_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "disability", name: "disability", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donor", name: "donor", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donorNotes", name: "donor_notes", editable: true, resizable: true, type: "text" },
			   	{ key: "faceId", name: "face_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fatherId", name: "father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fifthname", name: "fifthname", editable: true, resizable: true, type: "text" },
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "fourthname", name: "fourthname", editable: true, resizable: true, type: "text" },
			   	{ key: "gender", name: "gender", editable: true, resizable: true, type: "number" },
			   	{ key: "isDeleted", name: "is_deleted", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "maritalStatus", name: "marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "motherId", name: "mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "otherCitizenshipCsv", name: "other_citizenship_csv", editable: true, resizable: true, type: "text" },
			   	{ key: "primaryCitizenship", name: "primary_citizenship", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedFatherName", name: "reported_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedMotherName", name: "reported_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "secondname", name: "secondname", editable: true, resizable: true, type: "text" },
			   	{ key: "thirdname", name: "thirdname", editable: true, resizable: true, type: "text" },
			   	{ key: "timeOfDeath", name: "time_of_death", editable: true, resizable: true, type: "time" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "voter", name: "voter", editable: true, resizable: true, type: "checkbox" },
			],
			form2dto: (data) => {
				if(data.birthPlace == "")
					data.birthPlace = null;
				if(data.birthdate != null && data.birthdate instanceof Date)
					data.birthdate = data.birthdate.getFullYear() + "-" + ('0' + (data.birthdate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.birthdate.getDate()).slice(-2);
				if(data.commonIdNumber == "")
					data.commonIdNumber = null;
				if(data.dateOfDeath != null && data.dateOfDeath instanceof Date)
					data.dateOfDeath = data.dateOfDeath.getFullYear() + "-" + ('0' + (data.dateOfDeath.getMonth() + 1)).slice(-2) + "-" + ('0' + data.dateOfDeath.getDate()).slice(-2);
				if(data.donorNotes == "")
					data.donorNotes = null;
				if(data.fifthname == "")
					data.fifthname = null;
				if(data.firstname == "")
					data.firstname = null;
				if(data.fourthname == "")
					data.fourthname = null;
				if(data.notes == "")
					data.notes = null;
				if(data.otherCitizenshipCsv == "")
					data.otherCitizenshipCsv = null;
				if(data.reportedFatherName == "")
					data.reportedFatherName = null;
				if(data.reportedMotherName == "")
					data.reportedMotherName = null;
				if(data.secondname == "")
					data.secondname = null;
				if(data.thirdname == "")
					data.thirdname = null;
				return data
			}
		},
		'digitalization-queue-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "userId", name: "user_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				if(data.userId == "")
					data.userId = null;
				return data
			}
		},
		'occupation-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "description", name: "description", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "occupationId", name: "occupation_id", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.description == "")
					data.description = null;
				return data
			}
		},
		'removal-mtlb-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "afisId", name: "afis_id", editable: true, resizable: true, type: "number" },
			   	{ key: "faceImageType", name: "face_image_type", editable: true, resizable: true, type: "number" },
			   	{ key: "fingerImageType", name: "finger_image_type", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "numberOfAttachments", name: "number_of_attachments", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedId", name: "reported_id", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedLegacyId", name: "reported_legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "requestorEmail", name: "requestor_email", editable: true, resizable: true, type: "text" },
			   	{ key: "requestorFace", name: "requestor_face", editable: true, resizable: true, type: "blob" },
			   	{ key: "requestorFingerWhich", name: "requestor_finger_which", editable: true, resizable: true, type: "number" },
			   	{ key: "requestorFingerprint", name: "requestor_fingerprint", editable: true, resizable: true, type: "blob" },
			   	{ key: "requestorPhone", name: "requestor_phone", editable: true, resizable: true, type: "text" },
			   	{ key: "requestorVitalRecordId", name: "requestor_vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.notes == "")
					data.notes = null;
				if(data.reportedId == "")
					data.reportedId = null;
				if(data.reportedLegacyId == "")
					data.reportedLegacyId = null;
				if(data.requestorEmail == "")
					data.requestorEmail = null;
				if(data.requestorPhone == "")
					data.requestorPhone = null;
				return data
			}
		},
		'civil-status-mtlb-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "adoptiveFatherId", name: "adoptive_father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "adoptiveMotherId", name: "adoptive_mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "afisId", name: "afis_id", editable: true, resizable: true, type: "number" },
			   	{ key: "birthCountry", name: "birth_country", editable: true, resizable: true, type: "number" },
			   	{ key: "birthDayUnknown", name: "birth_day_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthMonthUnknown", name: "birth_month_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthPlace", name: "birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "birthTime", name: "birth_time", editable: true, resizable: true, type: "time" },
			   	{ key: "birthTimeUnknown", name: "birth_time_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthYearUnknown", name: "birth_year_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthdate", name: "birthdate", editable: true, resizable: true, type: "date" },
			   	{ key: "book", name: "book", editable: true, resizable: true, type: "number" },
			   	{ key: "courtAdoptionNumber", name: "court_adoption_number", editable: true, resizable: true, type: "text" },
			   	{ key: "dateOfDeath", name: "date_of_death", editable: true, resizable: true, type: "date" },
			   	{ key: "deathDayUnknown", name: "death_day_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathMonthUnknown", name: "death_month_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathTimeUnknown", name: "death_time_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathYearUnknown", name: "death_year_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "declarantId", name: "declarant_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deduplicationMtlbId", name: "deduplication_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "disability", name: "disability", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donor", name: "donor", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donorNotes", name: "donor_notes", editable: true, resizable: true, type: "text" },
			   	{ key: "face", name: "face", editable: true, resizable: true, type: "blob" },
			   	{ key: "faceMimeType", name: "face_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "fatherId", name: "father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fifthname", name: "fifthname", editable: true, resizable: true, type: "text" },
			   	{ key: "fingersMimeType", name: "fingers_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "fourthname", name: "fourthname", editable: true, resizable: true, type: "text" },
			   	{ key: "gender", name: "gender", editable: true, resizable: true, type: "number" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "leftIndexFinger", name: "left_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftMiddleFinger", name: "left_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftPinky", name: "left_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftRingFinger", name: "left_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftThumb", name: "left_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "maritalStatus", name: "marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "marriageCertificateNumberAdoptive", name: "marriage_certificate_number_adoptive", editable: true, resizable: true, type: "text" },
			   	{ key: "medicalNotification", name: "medical_notification", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "motherId", name: "mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatusData", name: "mtlb_status_data", editable: true, resizable: true, type: "json" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "numberOfAttachments", name: "number_of_attachments", editable: true, resizable: true, type: "number" },
			   	{ key: "otherCitizenshipCsv", name: "other_citizenship_csv", editable: true, resizable: true, type: "text" },
			   	{ key: "page", name: "page", editable: true, resizable: true, type: "number" },
			   	{ key: "primaryCitizenship", name: "primary_citizenship", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedFatherName", name: "reported_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedId", name: "reported_id", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedLegacyId", name: "reported_legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedMotherName", name: "reported_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "rightIndexFinger", name: "right_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightMiddleFinger", name: "right_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightPinky", name: "right_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightRingFinger", name: "right_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightThumb", name: "right_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "secondname", name: "secondname", editable: true, resizable: true, type: "text" },
			   	{ key: "signature", name: "signature", editable: true, resizable: true, type: "blob" },
			   	{ key: "signatureMimeType", name: "signature_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "sixthname", name: "sixthname", editable: true, resizable: true, type: "text" },
			   	{ key: "thirdname", name: "thirdname", editable: true, resizable: true, type: "text" },
			   	{ key: "timeOfDeath", name: "time_of_death", editable: true, resizable: true, type: "time" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "tribe", name: "tribe", editable: true, resizable: true, type: "number" },
			   	{ key: "unknownFather", name: "unknown_father", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "villageOfOriginAdoptive", name: "village_of_origin_adoptive", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "witness1Id", name: "witness1_id", editable: true, resizable: true, type: "number" },
			   	{ key: "witness2Id", name: "witness2_id", editable: true, resizable: true, type: "number" },
			   	{ key: "year", name: "year", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.birthPlace == "")
					data.birthPlace = null;
				if(data.birthdate != null && data.birthdate instanceof Date)
					data.birthdate = data.birthdate.getFullYear() + "-" + ('0' + (data.birthdate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.birthdate.getDate()).slice(-2);
				if(data.courtAdoptionNumber == "")
					data.courtAdoptionNumber = null;
				if(data.dateOfDeath != null && data.dateOfDeath instanceof Date)
					data.dateOfDeath = data.dateOfDeath.getFullYear() + "-" + ('0' + (data.dateOfDeath.getMonth() + 1)).slice(-2) + "-" + ('0' + data.dateOfDeath.getDate()).slice(-2);
				if(data.donorNotes == "")
					data.donorNotes = null;
				if(data.faceMimeType == "")
					data.faceMimeType = null;
				if(data.fifthname == "")
					data.fifthname = null;
				if(data.fingersMimeType == "")
					data.fingersMimeType = null;
				if(data.firstname == "")
					data.firstname = null;
				if(data.fourthname == "")
					data.fourthname = null;
				if(data.marriageCertificateNumberAdoptive == "")
					data.marriageCertificateNumberAdoptive = null;
				if(data.notes == "")
					data.notes = null;
				if(data.otherCitizenshipCsv == "")
					data.otherCitizenshipCsv = null;
				if(data.reportedFatherName == "")
					data.reportedFatherName = null;
				if(data.reportedLegacyId == "")
					data.reportedLegacyId = null;
				if(data.reportedMotherName == "")
					data.reportedMotherName = null;
				if(data.secondname == "")
					data.secondname = null;
				if(data.signatureMimeType == "")
					data.signatureMimeType = null;
				if(data.sixthname == "")
					data.sixthname = null;
				if(data.thirdname == "")
					data.thirdname = null;
				if(data.villageOfOriginAdoptive == "")
					data.villageOfOriginAdoptive = null;
				if(data.year == "")
					data.year = null;
				return data
			}
		},
		'e-id-mtlb-tag': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "eIdMtlbId", name: "e_id_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'removal-mtlb-tag': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "removalMtlbId", name: "removal_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'legacy-id-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "legacyId", name: "legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "newId", name: "new_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.legacyId == "")
					data.legacyId = null;
				return data
			}
		},
		'province': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'face-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "idMtlbId", name: "id_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "image", name: "image", editable: true, resizable: true, type: "blob" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				return data
			}
		},
		'face-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "idMtlbId", name: "id_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "image", name: "image", editable: true, resizable: true, type: "blob" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				return data
			}
		},
		'rvfour-marriage-mtlb': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "annex", name: "annex", editable: true, resizable: true, type: "text" },
			   	{ key: "dateOfMarriage", name: "date_of_marriage", editable: true, resizable: true, type: "date" },
			   	{ key: "husbandId", name: "husband_id", editable: true, resizable: true, type: "number" },
			   	{ key: "marriageLocation", name: "marriage_location", editable: true, resizable: true, type: "text" },
			   	{ key: "marriagePlace", name: "marriage_place", editable: true, resizable: true, type: "text" },
			   	{ key: "marriageRites", name: "marriage_rites", editable: true, resizable: true, type: "text" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "timeOfMarriage", name: "time_of_marriage", editable: true, resizable: true, type: "time" },
			   	{ key: "wifeId", name: "wife_id", editable: true, resizable: true, type: "number" },
			   	{ key: "witness1Id", name: "witness1_id", editable: true, resizable: true, type: "number" },
			   	{ key: "witness2Id", name: "witness2_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.annex == "")
					data.annex = null;
				if(data.dateOfMarriage != null && data.dateOfMarriage instanceof Date)
					data.dateOfMarriage = data.dateOfMarriage.getFullYear() + "-" + ('0' + (data.dateOfMarriage.getMonth() + 1)).slice(-2) + "-" + ('0' + data.dateOfMarriage.getDate()).slice(-2);
				if(data.marriageLocation == "")
					data.marriageLocation = null;
				if(data.marriagePlace == "")
					data.marriagePlace = null;
				if(data.marriageRites == "")
					data.marriageRites = null;
				return data
			}
		},
		'user-preferences': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "userId", name: "user_id", editable: true, resizable: true, type: "text" },
			   	{ key: "value", name: "value", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.key == "")
					data.key = null;
				if(data.userId == "")
					data.userId = null;
				if(data.value == "")
					data.value = null;
				return data
			}
		},
		'reg-area': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "constituencyId", name: "constituency_id", editable: true, resizable: true, type: "number" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'vital-record-note': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "addedBy", name: "added_by", editable: true, resizable: true, type: "text" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "timestamp", name: "timestamp", editable: true, resizable: true, type: "timestampz" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.addedBy == "")
					data.addedBy = null;
				if(data.content == "")
					data.content = null;
				if(data.timestamp != null && data.timestamp instanceof Date)
					data.timestamp = data.timestamp.getFullYear() + "-" + ('0' + (data.timestamp.getMonth() + 1)).slice(-2) + "-" + ('0' + data.timestamp.getDate()).slice(-2);
				return data
			}
		},
		'occupation-metadata-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'administrative-update-mtlb-attachment-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "administrativeUpdateMtlbId", name: "administrative_update_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'job-mtlb': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "timestamp", name: "timestamp", editable: true, resizable: true, type: "timestampz" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.name == "")
					data.name = null;
				if(data.timestamp != null && data.timestamp instanceof Date)
					data.timestamp = data.timestamp.getFullYear() + "-" + ('0' + (data.timestamp.getMonth() + 1)).slice(-2) + "-" + ('0' + data.timestamp.getDate()).slice(-2);
				return data
			}
		},
		'rvfour-birth-mtlb-tag': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "rvfourBirthMtlbId", name: "rvfour_birth_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'death-record-attachment-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "description", name: "description", editable: true, resizable: true, type: "text" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "preview", name: "preview", editable: true, resizable: true, type: "blob" },
			],
			form2dto: (data) => {
				if(data.description == "")
					data.description = null;
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'i18n': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "domainId", name: "domain_id", editable: true, resizable: true, type: "text" },
			   	{ key: "localeId", name: "locale_id", editable: true, resizable: true, type: "text" },
			   	{ key: "messageId", name: "message_id", editable: true, resizable: true, type: "text" },
			   	{ key: "value", name: "value", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.domainId == "")
					data.domainId = null;
				if(data.localeId == "")
					data.localeId = null;
				if(data.messageId == "")
					data.messageId = null;
				if(data.value == "")
					data.value = null;
				return data
			}
		},
		'vital-record-attachment-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'states-metadata': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "regionId", name: "region_id", editable: true, resizable: true, type: "select", metadata: "regions-metadata"  },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'migration-queue': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				return data
			}
		},
		'regions-metadata': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "countryId", name: "country_id", editable: true, resizable: true, type: "number" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'rvfour-id-card-mtlb': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "annex", name: "annex", editable: true, resizable: true, type: "text" },
			   	{ key: "cancelledDate", name: "cancelled_date", editable: true, resizable: true, type: "date" },
			   	{ key: "cancelledReason", name: "cancelled_reason", editable: true, resizable: true, type: "text" },
			   	{ key: "expires", name: "expires", editable: true, resizable: true, type: "date" },
			   	{ key: "idCardNumber", name: "id_card_number", editable: true, resizable: true, type: "text" },
			   	{ key: "issuedDate", name: "issued_date", editable: true, resizable: true, type: "date" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.annex == "")
					data.annex = null;
				if(data.cancelledDate != null && data.cancelledDate instanceof Date)
					data.cancelledDate = data.cancelledDate.getFullYear() + "-" + ('0' + (data.cancelledDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.cancelledDate.getDate()).slice(-2);
				if(data.cancelledReason == "")
					data.cancelledReason = null;
				if(data.expires != null && data.expires instanceof Date)
					data.expires = data.expires.getFullYear() + "-" + ('0' + (data.expires.getMonth() + 1)).slice(-2) + "-" + ('0' + data.expires.getDate()).slice(-2);
				if(data.idCardNumber == "")
					data.idCardNumber = null;
				if(data.issuedDate != null && data.issuedDate instanceof Date)
					data.issuedDate = data.issuedDate.getFullYear() + "-" + ('0' + (data.issuedDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.issuedDate.getDate()).slice(-2);
				return data
			}
		},
		'e-id-mtlb': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "birthdate", name: "birthdate", editable: true, resizable: true, type: "date" },
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "face", name: "face", editable: true, resizable: true, type: "blob" },
			   	{ key: "faceMimeType", name: "face_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "gender", name: "gender", editable: true, resizable: true, type: "number" },
			   	{ key: "lastname", name: "lastname", editable: true, resizable: true, type: "text" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "password", name: "password", editable: true, resizable: true, type: "password" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.birthdate != null && data.birthdate instanceof Date)
					data.birthdate = data.birthdate.getFullYear() + "-" + ('0' + (data.birthdate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.birthdate.getDate()).slice(-2);
				if(data.faceMimeType == "")
					data.faceMimeType = null;
				if(data.firstname == "")
					data.firstname = null;
				if(data.lastname == "")
					data.lastname = null;
				if(data.password == "")
					data.password = null;
				return data
			}
		},
		'removal-mtlb-attachment': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "description", name: "description", editable: true, resizable: true, type: "text" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "preview", name: "preview", editable: true, resizable: true, type: "blob" },
			   	{ key: "removalMtlbId", name: "removal_mtlb_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.description == "")
					data.description = null;
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'email-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "administrativeUpdateMtlbId", name: "administrative_update_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "email", name: "email", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.email == "")
					data.email = null;
				return data
			}
		},
		'user-preferences-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "userId", name: "user_id", editable: true, resizable: true, type: "text" },
			   	{ key: "value", name: "value", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.key == "")
					data.key = null;
				if(data.userId == "")
					data.userId = null;
				if(data.value == "")
					data.value = null;
				return data
			}
		},
		'removal-mtlb-tag-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "removalMtlbId", name: "removal_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'death-record-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "address", name: "address", editable: true, resizable: true, type: "number" },
			   	{ key: "birthCountry", name: "birth_country", editable: true, resizable: true, type: "number" },
			   	{ key: "birthDayUnknown", name: "birth_day_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthMonthUnknown", name: "birth_month_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthPlace", name: "birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "birthTime", name: "birth_time", editable: true, resizable: true, type: "time" },
			   	{ key: "birthTimeUnknown", name: "birth_time_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthYearUnknown", name: "birth_year_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthdate", name: "birthdate", editable: true, resizable: true, type: "date" },
			   	{ key: "cardId", name: "card_id", editable: true, resizable: true, type: "number" },
			   	{ key: "commonIdNumber", name: "common_id_number", editable: true, resizable: true, type: "text" },
			   	{ key: "dateOfDeath", name: "date_of_death", editable: true, resizable: true, type: "date" },
			   	{ key: "deathDayUnknown", name: "death_day_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathMonthUnknown", name: "death_month_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathTimeUnknown", name: "death_time_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathYearUnknown", name: "death_year_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "disability", name: "disability", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donor", name: "donor", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donorNotes", name: "donor_notes", editable: true, resizable: true, type: "text" },
			   	{ key: "faceId", name: "face_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fatherId", name: "father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fifthname", name: "fifthname", editable: true, resizable: true, type: "text" },
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "fourthname", name: "fourthname", editable: true, resizable: true, type: "text" },
			   	{ key: "gender", name: "gender", editable: true, resizable: true, type: "number" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "isDeleted", name: "is_deleted", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "maritalStatus", name: "marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "motherId", name: "mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "otherCitizenshipCsv", name: "other_citizenship_csv", editable: true, resizable: true, type: "text" },
			   	{ key: "primaryCitizenship", name: "primary_citizenship", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedFatherName", name: "reported_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedMotherName", name: "reported_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "secondname", name: "secondname", editable: true, resizable: true, type: "text" },
			   	{ key: "thirdname", name: "thirdname", editable: true, resizable: true, type: "text" },
			   	{ key: "timeOfDeath", name: "time_of_death", editable: true, resizable: true, type: "time" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "voter", name: "voter", editable: true, resizable: true, type: "checkbox" },
			],
			form2dto: (data) => {
				if(data.birthPlace == "")
					data.birthPlace = null;
				if(data.birthdate != null && data.birthdate instanceof Date)
					data.birthdate = data.birthdate.getFullYear() + "-" + ('0' + (data.birthdate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.birthdate.getDate()).slice(-2);
				if(data.commonIdNumber == "")
					data.commonIdNumber = null;
				if(data.dateOfDeath != null && data.dateOfDeath instanceof Date)
					data.dateOfDeath = data.dateOfDeath.getFullYear() + "-" + ('0' + (data.dateOfDeath.getMonth() + 1)).slice(-2) + "-" + ('0' + data.dateOfDeath.getDate()).slice(-2);
				if(data.donorNotes == "")
					data.donorNotes = null;
				if(data.fifthname == "")
					data.fifthname = null;
				if(data.firstname == "")
					data.firstname = null;
				if(data.fourthname == "")
					data.fourthname = null;
				if(data.notes == "")
					data.notes = null;
				if(data.otherCitizenshipCsv == "")
					data.otherCitizenshipCsv = null;
				if(data.reportedFatherName == "")
					data.reportedFatherName = null;
				if(data.reportedMotherName == "")
					data.reportedMotherName = null;
				if(data.secondname == "")
					data.secondname = null;
				if(data.thirdname == "")
					data.thirdname = null;
				return data
			}
		},
		'vital-record-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "adoptiveFatherId", name: "adoptive_father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "adoptiveMotherId", name: "adoptive_mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "birthCountry", name: "birth_country", editable: true, resizable: true, type: "number" },
			   	{ key: "birthDayUnknown", name: "birth_day_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthMonthUnknown", name: "birth_month_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthPlace", name: "birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "birthTime", name: "birth_time", editable: true, resizable: true, type: "time" },
			   	{ key: "birthTimeUnknown", name: "birth_time_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthYearUnknown", name: "birth_year_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthdate", name: "birthdate", editable: true, resizable: true, type: "date" },
			   	{ key: "cardId", name: "card_id", editable: true, resizable: true, type: "number" },
			   	{ key: "commonIdNumber", name: "common_id_number", editable: true, resizable: true, type: "text" },
			   	{ key: "courtAdoptionNumber", name: "court_adoption_number", editable: true, resizable: true, type: "text" },
			   	{ key: "declarantId", name: "declarant_id", editable: true, resizable: true, type: "number" },
			   	{ key: "disability", name: "disability", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donor", name: "donor", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donorNotes", name: "donor_notes", editable: true, resizable: true, type: "text" },
			   	{ key: "faceId", name: "face_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fatherId", name: "father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fifthname", name: "fifthname", editable: true, resizable: true, type: "text" },
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "fourthname", name: "fourthname", editable: true, resizable: true, type: "text" },
			   	{ key: "gender", name: "gender", editable: true, resizable: true, type: "number" },
			   	{ key: "historyTimestamp", name: "history_timestamp", editable: true, resizable: true, type: "timestampz" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "inactive", name: "inactive", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "isDeleted", name: "is_deleted", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "maritalStatus", name: "marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "markedInactive", name: "marked_inactive", editable: true, resizable: true, type: "date" },
			   	{ key: "marriageCertificateNumberAdoptive", name: "marriage_certificate_number_adoptive", editable: true, resizable: true, type: "text" },
			   	{ key: "motherId", name: "mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "otherCitizenshipCsv", name: "other_citizenship_csv", editable: true, resizable: true, type: "text" },
			   	{ key: "primaryCitizenship", name: "primary_citizenship", editable: true, resizable: true, type: "text" },
			   	{ key: "recordStatus", name: "record_status", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedFatherName", name: "reported_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedMotherName", name: "reported_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "secondname", name: "secondname", editable: true, resizable: true, type: "text" },
			   	{ key: "thirdname", name: "thirdname", editable: true, resizable: true, type: "text" },
			   	{ key: "timestamp", name: "timestamp", editable: true, resizable: true, type: "timestampz" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "tribe", name: "tribe", editable: true, resizable: true, type: "number" },
			   	{ key: "typeOfAcquisition", name: "type_of_acquisition", editable: true, resizable: true, type: "number" },
			   	{ key: "unknownFather", name: "unknown_father", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "unknownMother", name: "unknown_mother", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "villageOfOrigin", name: "village_of_origin", editable: true, resizable: true, type: "text" },
			   	{ key: "villageOfOriginAdoptive", name: "village_of_origin_adoptive", editable: true, resizable: true, type: "text" },
			   	{ key: "voter", name: "voter", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "witness1Id", name: "witness1_id", editable: true, resizable: true, type: "number" },
			   	{ key: "witness2Id", name: "witness2_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.birthPlace == "")
					data.birthPlace = null;
				if(data.birthdate != null && data.birthdate instanceof Date)
					data.birthdate = data.birthdate.getFullYear() + "-" + ('0' + (data.birthdate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.birthdate.getDate()).slice(-2);
				if(data.commonIdNumber == "")
					data.commonIdNumber = null;
				if(data.courtAdoptionNumber == "")
					data.courtAdoptionNumber = null;
				if(data.donorNotes == "")
					data.donorNotes = null;
				if(data.fifthname == "")
					data.fifthname = null;
				if(data.firstname == "")
					data.firstname = null;
				if(data.fourthname == "")
					data.fourthname = null;
				if(data.historyTimestamp != null && data.historyTimestamp instanceof Date)
					data.historyTimestamp = data.historyTimestamp.getFullYear() + "-" + ('0' + (data.historyTimestamp.getMonth() + 1)).slice(-2) + "-" + ('0' + data.historyTimestamp.getDate()).slice(-2);
				if(data.markedInactive != null && data.markedInactive instanceof Date)
					data.markedInactive = data.markedInactive.getFullYear() + "-" + ('0' + (data.markedInactive.getMonth() + 1)).slice(-2) + "-" + ('0' + data.markedInactive.getDate()).slice(-2);
				if(data.marriageCertificateNumberAdoptive == "")
					data.marriageCertificateNumberAdoptive = null;
				if(data.notes == "")
					data.notes = null;
				if(data.otherCitizenshipCsv == "")
					data.otherCitizenshipCsv = null;
				if(data.primaryCitizenship == "")
					data.primaryCitizenship = null;
				if(data.reportedFatherName == "")
					data.reportedFatherName = null;
				if(data.reportedMotherName == "")
					data.reportedMotherName = null;
				if(data.secondname == "")
					data.secondname = null;
				if(data.thirdname == "")
					data.thirdname = null;
				if(data.timestamp != null && data.timestamp instanceof Date)
					data.timestamp = data.timestamp.getFullYear() + "-" + ('0' + (data.timestamp.getMonth() + 1)).slice(-2) + "-" + ('0' + data.timestamp.getDate()).slice(-2);
				if(data.villageOfOrigin == "")
					data.villageOfOrigin = null;
				if(data.villageOfOriginAdoptive == "")
					data.villageOfOriginAdoptive = null;
				return data
			}
		},
		'removal-mtlb-attachment-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "removalMtlbId", name: "removal_mtlb_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'vital-record-attachment-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'vital-record-tag-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'registrar': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "cancelled", name: "cancelled", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "cancelledDate", name: "cancelled_date", editable: true, resizable: true, type: "date" },
			   	{ key: "issuedDate", name: "issued_date", editable: true, resizable: true, type: "date" },
			   	{ key: "privateKey", name: "private_key", editable: true, resizable: true, type: "text" },
			   	{ key: "publicKey", name: "public_key", editable: true, resizable: true, type: "text" },
			   	{ key: "signature", name: "signature", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.cancelledDate != null && data.cancelledDate instanceof Date)
					data.cancelledDate = data.cancelledDate.getFullYear() + "-" + ('0' + (data.cancelledDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.cancelledDate.getDate()).slice(-2);
				if(data.issuedDate != null && data.issuedDate instanceof Date)
					data.issuedDate = data.issuedDate.getFullYear() + "-" + ('0' + (data.issuedDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.issuedDate.getDate()).slice(-2);
				if(data.privateKey == "")
					data.privateKey = null;
				if(data.publicKey == "")
					data.publicKey = null;
				return data
			}
		},
		'signature': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "idMtlbId", name: "id_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "image", name: "image", editable: true, resizable: true, type: "blob" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				return data
			}
		},
		'administrative-update-mtlb-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "city", name: "city", editable: true, resizable: true, type: "text" },
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "email", name: "email", editable: true, resizable: true, type: "text" },
			   	{ key: "isDonor", name: "is_donor", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "numberOfAttachments", name: "number_of_attachments", editable: true, resizable: true, type: "number" },
			   	{ key: "occupationId", name: "occupation_id", editable: true, resizable: true, type: "number" },
			   	{ key: "otherPhone", name: "other_phone", editable: true, resizable: true, type: "text" },
			   	{ key: "phoneNumber", name: "phone_number", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedId", name: "reported_id", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedLegacyId", name: "reported_legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "residenceCountryId", name: "residence_country_id", editable: true, resizable: true, type: "number" },
			   	{ key: "residenceLocation", name: "residence_location", editable: true, resizable: true, type: "text" },
			   	{ key: "streetAddress", name: "street_address", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "zipCode", name: "zip_code", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.city == "")
					data.city = null;
				if(data.email == "")
					data.email = null;
				if(data.otherPhone == "")
					data.otherPhone = null;
				if(data.phoneNumber == "")
					data.phoneNumber = null;
				if(data.reportedLegacyId == "")
					data.reportedLegacyId = null;
				if(data.residenceLocation == "")
					data.residenceLocation = null;
				if(data.streetAddress == "")
					data.streetAddress = null;
				if(data.zipCode == "")
					data.zipCode = null;
				return data
			}
		},
		'birth-certificate-data': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "birthCountry", name: "birth_country", editable: true, resizable: true, type: "number" },
			   	{ key: "birthPlace", name: "birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "birthTime", name: "birth_time", editable: true, resizable: true, type: "time" },
			   	{ key: "birthdate", name: "birthdate", editable: true, resizable: true, type: "date" },
			   	{ key: "cardId", name: "card_id", editable: true, resizable: true, type: "number" },
			   	{ key: "commonIdNumber", name: "common_id_number", editable: true, resizable: true, type: "text" },
			   	{ key: "fatherBirthCountry", name: "father_birth_country", editable: true, resizable: true, type: "number" },
			   	{ key: "fatherBirthPlace", name: "father_birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "fatherBirthTime", name: "father_birth_time", editable: true, resizable: true, type: "time" },
			   	{ key: "fatherBirthdate", name: "father_birthdate", editable: true, resizable: true, type: "date" },
			   	{ key: "fatherCardId", name: "father_card_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fatherCommonIdNumber", name: "father_common_id_number", editable: true, resizable: true, type: "text" },
			   	{ key: "fatherFatherId", name: "father_father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fatherFifthname", name: "father_fifthname", editable: true, resizable: true, type: "text" },
			   	{ key: "fatherFirstname", name: "father_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "fatherFourthname", name: "father_fourthname", editable: true, resizable: true, type: "text" },
			   	{ key: "fatherGender", name: "father_gender", editable: true, resizable: true, type: "number" },
			   	{ key: "fatherId", name: "father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fatherMaritalStatus", name: "father_marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "fatherMotherId", name: "father_mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fatherReportedFatherName", name: "father_reported_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "fatherReportedMotherName", name: "father_reported_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "fatherSecondname", name: "father_secondname", editable: true, resizable: true, type: "text" },
			   	{ key: "fatherThirdname", name: "father_thirdname", editable: true, resizable: true, type: "text" },
			   	{ key: "fatherTribe", name: "father_tribe", editable: true, resizable: true, type: "number" },
			   	{ key: "fifthname", name: "fifthname", editable: true, resizable: true, type: "text" },
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "fourthname", name: "fourthname", editable: true, resizable: true, type: "text" },
			   	{ key: "gender", name: "gender", editable: true, resizable: true, type: "number" },
			   	{ key: "grandFatherFatherFirstname", name: "grand_father_father_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "grandFatherFatherSecondname", name: "grand_father_father_secondname", editable: true, resizable: true, type: "text" },
			   	{ key: "grandFatherMotherFirstname", name: "grand_father_mother_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "grandFatherMotherSecondname", name: "grand_father_mother_secondname", editable: true, resizable: true, type: "text" },
			   	{ key: "grandMotherFatherFirstname", name: "grand_mother_father_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "grandMotherFatherSecondname", name: "grand_mother_father_secondname", editable: true, resizable: true, type: "text" },
			   	{ key: "grandMotherMotherFirstname", name: "grand_mother_mother_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "grandMotherMotherSecondname", name: "grand_mother_mother_secondname", editable: true, resizable: true, type: "text" },
			   	{ key: "maritalStatus", name: "marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "motherBirthCountry", name: "mother_birth_country", editable: true, resizable: true, type: "number" },
			   	{ key: "motherBirthPlace", name: "mother_birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "motherBirthTime", name: "mother_birth_time", editable: true, resizable: true, type: "time" },
			   	{ key: "motherBirthdate", name: "mother_birthdate", editable: true, resizable: true, type: "date" },
			   	{ key: "motherCardId", name: "mother_card_id", editable: true, resizable: true, type: "number" },
			   	{ key: "motherCommonIdNumber", name: "mother_common_id_number", editable: true, resizable: true, type: "text" },
			   	{ key: "motherFatherId", name: "mother_father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "motherFifthname", name: "mother_fifthname", editable: true, resizable: true, type: "text" },
			   	{ key: "motherFirstname", name: "mother_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "motherFourthname", name: "mother_fourthname", editable: true, resizable: true, type: "text" },
			   	{ key: "motherGender", name: "mother_gender", editable: true, resizable: true, type: "number" },
			   	{ key: "motherId", name: "mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "motherMaritalStatus", name: "mother_marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "motherMotherId", name: "mother_mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "motherReportedFatherName", name: "mother_reported_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "motherReportedMotherName", name: "mother_reported_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "motherSecondname", name: "mother_secondname", editable: true, resizable: true, type: "text" },
			   	{ key: "motherThirdname", name: "mother_thirdname", editable: true, resizable: true, type: "text" },
			   	{ key: "motherTribe", name: "mother_tribe", editable: true, resizable: true, type: "number" },
			   	{ key: "registrarFirstName", name: "registrar_first_name", editable: true, resizable: true, type: "text" },
			   	{ key: "registrarFourthName", name: "registrar_fourth_name", editable: true, resizable: true, type: "text" },
			   	{ key: "registrarId", name: "registrar_id", editable: true, resizable: true, type: "number" },
			   	{ key: "registrarSignatureId", name: "registrar_signature_id", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedFatherName", name: "reported_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedMotherName", name: "reported_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "secondname", name: "secondname", editable: true, resizable: true, type: "text" },
			   	{ key: "thirdname", name: "thirdname", editable: true, resizable: true, type: "text" },
			   	{ key: "timestamp", name: "timestamp", editable: true, resizable: true, type: "timestampz" },
			   	{ key: "tribe", name: "tribe", editable: true, resizable: true, type: "number" },
			   	{ key: "witness1Firstname", name: "witness1_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1Fourthname", name: "witness1_fourthname", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1Id", name: "witness1_id", editable: true, resizable: true, type: "number" },
			   	{ key: "witness1Secondname", name: "witness1_secondname", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1Thirdname", name: "witness1_thirdname", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2Firstname", name: "witness2_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2Fourthname", name: "witness2_fourthname", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2Id", name: "witness2_id", editable: true, resizable: true, type: "number" },
			   	{ key: "witness2Secondname", name: "witness2_secondname", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2Thirdname", name: "witness2_thirdname", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.birthPlace == "")
					data.birthPlace = null;
				if(data.birthdate != null && data.birthdate instanceof Date)
					data.birthdate = data.birthdate.getFullYear() + "-" + ('0' + (data.birthdate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.birthdate.getDate()).slice(-2);
				if(data.commonIdNumber == "")
					data.commonIdNumber = null;
				if(data.fatherBirthPlace == "")
					data.fatherBirthPlace = null;
				if(data.fatherBirthdate != null && data.fatherBirthdate instanceof Date)
					data.fatherBirthdate = data.fatherBirthdate.getFullYear() + "-" + ('0' + (data.fatherBirthdate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.fatherBirthdate.getDate()).slice(-2);
				if(data.fatherCommonIdNumber == "")
					data.fatherCommonIdNumber = null;
				if(data.fatherFifthname == "")
					data.fatherFifthname = null;
				if(data.fatherFirstname == "")
					data.fatherFirstname = null;
				if(data.fatherFourthname == "")
					data.fatherFourthname = null;
				if(data.fatherReportedFatherName == "")
					data.fatherReportedFatherName = null;
				if(data.fatherReportedMotherName == "")
					data.fatherReportedMotherName = null;
				if(data.fatherSecondname == "")
					data.fatherSecondname = null;
				if(data.fatherThirdname == "")
					data.fatherThirdname = null;
				if(data.fifthname == "")
					data.fifthname = null;
				if(data.firstname == "")
					data.firstname = null;
				if(data.fourthname == "")
					data.fourthname = null;
				if(data.grandFatherFatherFirstname == "")
					data.grandFatherFatherFirstname = null;
				if(data.grandFatherFatherSecondname == "")
					data.grandFatherFatherSecondname = null;
				if(data.grandFatherMotherFirstname == "")
					data.grandFatherMotherFirstname = null;
				if(data.grandFatherMotherSecondname == "")
					data.grandFatherMotherSecondname = null;
				if(data.grandMotherFatherFirstname == "")
					data.grandMotherFatherFirstname = null;
				if(data.grandMotherFatherSecondname == "")
					data.grandMotherFatherSecondname = null;
				if(data.grandMotherMotherFirstname == "")
					data.grandMotherMotherFirstname = null;
				if(data.grandMotherMotherSecondname == "")
					data.grandMotherMotherSecondname = null;
				if(data.motherBirthPlace == "")
					data.motherBirthPlace = null;
				if(data.motherBirthdate != null && data.motherBirthdate instanceof Date)
					data.motherBirthdate = data.motherBirthdate.getFullYear() + "-" + ('0' + (data.motherBirthdate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.motherBirthdate.getDate()).slice(-2);
				if(data.motherCommonIdNumber == "")
					data.motherCommonIdNumber = null;
				if(data.motherFifthname == "")
					data.motherFifthname = null;
				if(data.motherFirstname == "")
					data.motherFirstname = null;
				if(data.motherFourthname == "")
					data.motherFourthname = null;
				if(data.motherReportedFatherName == "")
					data.motherReportedFatherName = null;
				if(data.motherReportedMotherName == "")
					data.motherReportedMotherName = null;
				if(data.motherSecondname == "")
					data.motherSecondname = null;
				if(data.motherThirdname == "")
					data.motherThirdname = null;
				if(data.registrarFirstName == "")
					data.registrarFirstName = null;
				if(data.registrarFourthName == "")
					data.registrarFourthName = null;
				if(data.reportedFatherName == "")
					data.reportedFatherName = null;
				if(data.reportedMotherName == "")
					data.reportedMotherName = null;
				if(data.secondname == "")
					data.secondname = null;
				if(data.thirdname == "")
					data.thirdname = null;
				if(data.timestamp != null && data.timestamp instanceof Date)
					data.timestamp = data.timestamp.getFullYear() + "-" + ('0' + (data.timestamp.getMonth() + 1)).slice(-2) + "-" + ('0' + data.timestamp.getDate()).slice(-2);
				if(data.witness1Firstname == "")
					data.witness1Firstname = null;
				if(data.witness1Fourthname == "")
					data.witness1Fourthname = null;
				if(data.witness1Secondname == "")
					data.witness1Secondname = null;
				if(data.witness1Thirdname == "")
					data.witness1Thirdname = null;
				if(data.witness2Firstname == "")
					data.witness2Firstname = null;
				if(data.witness2Fourthname == "")
					data.witness2Fourthname = null;
				if(data.witness2Secondname == "")
					data.witness2Secondname = null;
				if(data.witness2Thirdname == "")
					data.witness2Thirdname = null;
				return data
			}
		},
		'union-record-attachment': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "description", name: "description", editable: true, resizable: true, type: "text" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "preview", name: "preview", editable: true, resizable: true, type: "blob" },
			   	{ key: "unionRecordId", name: "union_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.description == "")
					data.description = null;
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'ward': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "constituencyId", name: "constituency_id", editable: true, resizable: true, type: "number" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'union-mtlb-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "address", name: "address", editable: true, resizable: true, type: "text" },
			   	{ key: "declaredDate", name: "declared_date", editable: true, resizable: true, type: "date" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatusData", name: "mtlb_status_data", editable: true, resizable: true, type: "json" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1BirthPlace", name: "partner1_birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1FatherId", name: "partner1_father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner1FatherName", name: "partner1_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1Firstname", name: "partner1_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1IndividualMelanesianName", name: "partner1_individual_melanesian_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1MaritalStatus", name: "partner1_marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "partner1MotherId", name: "partner1_mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner1MotherName", name: "partner1_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1Occupation", name: "partner1_occupation", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1ResidenceAddress", name: "partner1_residence_address", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1Surname", name: "partner1_surname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1VitalRecordId", name: "partner1_vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner2BirthPlace", name: "partner2_birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2FatherId", name: "partner2_father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner2FatherName", name: "partner2_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2Firstname", name: "partner2_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2IndividualMelanesianName", name: "partner2_individual_melanesian_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2MaritalStatus", name: "partner2_marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "partner2MotherId", name: "partner2_mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner2MotherName", name: "partner2_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2Occupation", name: "partner2_occupation", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2ResidenceAddress", name: "partner2_residence_address", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2Surname", name: "partner2_surname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2VitalRecordId", name: "partner2_vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "place", name: "place", editable: true, resizable: true, type: "text" },
			   	{ key: "rites", name: "rites", editable: true, resizable: true, type: "text" },
			   	{ key: "unionRegime", name: "union_regime", editable: true, resizable: true, type: "number" },
			   	{ key: "witness1BirthPlace", name: "witness1_birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1Firstname", name: "witness1_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1Occupation", name: "witness1_occupation", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1ResidenceAddress", name: "witness1_residence_address", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1Surname", name: "witness1_surname", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1VitalRecordId", name: "witness1_vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "witness2BirthPlace", name: "witness2_birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2Firstname", name: "witness2_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2Occupation", name: "witness2_occupation", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2ResidenceAddress", name: "witness2_residence_address", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2Surname", name: "witness2_surname", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2VitalRecordId", name: "witness2_vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.address == "")
					data.address = null;
				if(data.declaredDate != null && data.declaredDate instanceof Date)
					data.declaredDate = data.declaredDate.getFullYear() + "-" + ('0' + (data.declaredDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.declaredDate.getDate()).slice(-2);
				if(data.notes == "")
					data.notes = null;
				if(data.partner1BirthPlace == "")
					data.partner1BirthPlace = null;
				if(data.partner1FatherName == "")
					data.partner1FatherName = null;
				if(data.partner1Firstname == "")
					data.partner1Firstname = null;
				if(data.partner1IndividualMelanesianName == "")
					data.partner1IndividualMelanesianName = null;
				if(data.partner1MotherName == "")
					data.partner1MotherName = null;
				if(data.partner1Occupation == "")
					data.partner1Occupation = null;
				if(data.partner1ResidenceAddress == "")
					data.partner1ResidenceAddress = null;
				if(data.partner1Surname == "")
					data.partner1Surname = null;
				if(data.partner2BirthPlace == "")
					data.partner2BirthPlace = null;
				if(data.partner2FatherName == "")
					data.partner2FatherName = null;
				if(data.partner2Firstname == "")
					data.partner2Firstname = null;
				if(data.partner2IndividualMelanesianName == "")
					data.partner2IndividualMelanesianName = null;
				if(data.partner2MotherName == "")
					data.partner2MotherName = null;
				if(data.partner2Occupation == "")
					data.partner2Occupation = null;
				if(data.partner2ResidenceAddress == "")
					data.partner2ResidenceAddress = null;
				if(data.partner2Surname == "")
					data.partner2Surname = null;
				if(data.place == "")
					data.place = null;
				if(data.rites == "")
					data.rites = null;
				if(data.witness1BirthPlace == "")
					data.witness1BirthPlace = null;
				if(data.witness1Firstname == "")
					data.witness1Firstname = null;
				if(data.witness1Occupation == "")
					data.witness1Occupation = null;
				if(data.witness1ResidenceAddress == "")
					data.witness1ResidenceAddress = null;
				if(data.witness1Surname == "")
					data.witness1Surname = null;
				if(data.witness2BirthPlace == "")
					data.witness2BirthPlace = null;
				if(data.witness2Firstname == "")
					data.witness2Firstname = null;
				if(data.witness2Occupation == "")
					data.witness2Occupation = null;
				if(data.witness2ResidenceAddress == "")
					data.witness2ResidenceAddress = null;
				if(data.witness2Surname == "")
					data.witness2Surname = null;
				return data
			}
		},
		'administrative-information-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "city", name: "city", editable: true, resizable: true, type: "text" },
			   	{ key: "donor", name: "donor", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "email", name: "email", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "occupationId", name: "occupation_id", editable: true, resizable: true, type: "number" },
			   	{ key: "otherPhone", name: "other_phone", editable: true, resizable: true, type: "text" },
			   	{ key: "phoneNumber", name: "phone_number", editable: true, resizable: true, type: "text" },
			   	{ key: "residenceCountryId", name: "residence_country_id", editable: true, resizable: true, type: "number" },
			   	{ key: "residenceLocation", name: "residence_location", editable: true, resizable: true, type: "text" },
			   	{ key: "streetAddress", name: "street_address", editable: true, resizable: true, type: "text" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "zipCode", name: "zip_code", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.city == "")
					data.city = null;
				if(data.email == "")
					data.email = null;
				if(data.otherPhone == "")
					data.otherPhone = null;
				if(data.phoneNumber == "")
					data.phoneNumber = null;
				if(data.residenceLocation == "")
					data.residenceLocation = null;
				if(data.streetAddress == "")
					data.streetAddress = null;
				if(data.zipCode == "")
					data.zipCode = null;
				return data
			}
		},
		'vital-record-note-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "addedBy", name: "added_by", editable: true, resizable: true, type: "text" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.addedBy == "")
					data.addedBy = null;
				if(data.content == "")
					data.content = null;
				return data
			}
		},
		'vital-record-mention': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "addedBy", name: "added_by", editable: true, resizable: true, type: "text" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "timestamp", name: "timestamp", editable: true, resizable: true, type: "timestampz" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.addedBy == "")
					data.addedBy = null;
				if(data.content == "")
					data.content = null;
				if(data.timestamp != null && data.timestamp instanceof Date)
					data.timestamp = data.timestamp.getFullYear() + "-" + ('0' + (data.timestamp.getMonth() + 1)).slice(-2) + "-" + ('0' + data.timestamp.getDate()).slice(-2);
				return data
			}
		},
		'civil-status-mtlb-note': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "addedBy", name: "added_by", editable: true, resizable: true, type: "text" },
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "timestamp", name: "timestamp", editable: true, resizable: true, type: "timestampz" },
			],
			form2dto: (data) => {
				if(data.addedBy == "")
					data.addedBy = null;
				if(data.content == "")
					data.content = null;
				if(data.timestamp != null && data.timestamp instanceof Date)
					data.timestamp = data.timestamp.getFullYear() + "-" + ('0' + (data.timestamp.getMonth() + 1)).slice(-2) + "-" + ('0' + data.timestamp.getDate()).slice(-2);
				return data
			}
		},
		'phone-number-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "administrativeUpdateMtlbId", name: "administrative_update_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "phoneNumber", name: "phone_number", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.phoneNumber == "")
					data.phoneNumber = null;
				return data
			}
		},
		'country-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "flag", name: "flag", editable: true, resizable: true, type: "blob" },
			   	{ key: "flagMimeType", name: "flag_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.flagMimeType == "")
					data.flagMimeType = null;
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'civil-status-mtlb-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "afisId", name: "afis_id", editable: true, resizable: true, type: "number" },
			   	{ key: "birthCountry", name: "birth_country", editable: true, resizable: true, type: "number" },
			   	{ key: "birthDayUnknown", name: "birth_day_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthMonthUnknown", name: "birth_month_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthPlace", name: "birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "birthTime", name: "birth_time", editable: true, resizable: true, type: "time" },
			   	{ key: "birthTimeUnknown", name: "birth_time_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthYearUnknown", name: "birth_year_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthdate", name: "birthdate", editable: true, resizable: true, type: "date" },
			   	{ key: "book", name: "book", editable: true, resizable: true, type: "number" },
			   	{ key: "dateOfDeath", name: "date_of_death", editable: true, resizable: true, type: "date" },
			   	{ key: "deathDayUnknown", name: "death_day_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathMonthUnknown", name: "death_month_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathTimeUnknown", name: "death_time_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathYearUnknown", name: "death_year_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "declarantId", name: "declarant_id", editable: true, resizable: true, type: "number" },
			   	{ key: "disability", name: "disability", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donor", name: "donor", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donorNotes", name: "donor_notes", editable: true, resizable: true, type: "text" },
			   	{ key: "face", name: "face", editable: true, resizable: true, type: "blob" },
			   	{ key: "faceMimeType", name: "face_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "fatherId", name: "father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fifthname", name: "fifthname", editable: true, resizable: true, type: "text" },
			   	{ key: "fingersMimeType", name: "fingers_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "fourthname", name: "fourthname", editable: true, resizable: true, type: "text" },
			   	{ key: "gender", name: "gender", editable: true, resizable: true, type: "number" },
			   	{ key: "leftIndexFinger", name: "left_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftMiddleFinger", name: "left_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftPinky", name: "left_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftRingFinger", name: "left_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftThumb", name: "left_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "maritalStatus", name: "marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "medicalNotification", name: "medical_notification", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "motherId", name: "mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatusData", name: "mtlb_status_data", editable: true, resizable: true, type: "json" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "numberOfAttachments", name: "number_of_attachments", editable: true, resizable: true, type: "number" },
			   	{ key: "otherCitizenshipCsv", name: "other_citizenship_csv", editable: true, resizable: true, type: "text" },
			   	{ key: "page", name: "page", editable: true, resizable: true, type: "number" },
			   	{ key: "primaryCitizenship", name: "primary_citizenship", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedFatherName", name: "reported_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedId", name: "reported_id", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedLegacyId", name: "reported_legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedMotherName", name: "reported_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "rightIndexFinger", name: "right_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightMiddleFinger", name: "right_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightPinky", name: "right_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightRingFinger", name: "right_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightThumb", name: "right_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "secondname", name: "secondname", editable: true, resizable: true, type: "text" },
			   	{ key: "signature", name: "signature", editable: true, resizable: true, type: "blob" },
			   	{ key: "signatureMimeType", name: "signature_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "sixthname", name: "sixthname", editable: true, resizable: true, type: "text" },
			   	{ key: "thirdname", name: "thirdname", editable: true, resizable: true, type: "text" },
			   	{ key: "timeOfDeath", name: "time_of_death", editable: true, resizable: true, type: "time" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "unknownFather", name: "unknown_father", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "witness1Id", name: "witness1_id", editable: true, resizable: true, type: "number" },
			   	{ key: "witness2Id", name: "witness2_id", editable: true, resizable: true, type: "number" },
			   	{ key: "year", name: "year", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.birthPlace == "")
					data.birthPlace = null;
				if(data.birthdate != null && data.birthdate instanceof Date)
					data.birthdate = data.birthdate.getFullYear() + "-" + ('0' + (data.birthdate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.birthdate.getDate()).slice(-2);
				if(data.dateOfDeath != null && data.dateOfDeath instanceof Date)
					data.dateOfDeath = data.dateOfDeath.getFullYear() + "-" + ('0' + (data.dateOfDeath.getMonth() + 1)).slice(-2) + "-" + ('0' + data.dateOfDeath.getDate()).slice(-2);
				if(data.donorNotes == "")
					data.donorNotes = null;
				if(data.faceMimeType == "")
					data.faceMimeType = null;
				if(data.fifthname == "")
					data.fifthname = null;
				if(data.fingersMimeType == "")
					data.fingersMimeType = null;
				if(data.firstname == "")
					data.firstname = null;
				if(data.fourthname == "")
					data.fourthname = null;
				if(data.notes == "")
					data.notes = null;
				if(data.otherCitizenshipCsv == "")
					data.otherCitizenshipCsv = null;
				if(data.reportedFatherName == "")
					data.reportedFatherName = null;
				if(data.reportedLegacyId == "")
					data.reportedLegacyId = null;
				if(data.reportedMotherName == "")
					data.reportedMotherName = null;
				if(data.secondname == "")
					data.secondname = null;
				if(data.signatureMimeType == "")
					data.signatureMimeType = null;
				if(data.sixthname == "")
					data.sixthname = null;
				if(data.thirdname == "")
					data.thirdname = null;
				if(data.year == "")
					data.year = null;
				return data
			}
		},
		'death-record-attachment': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "description", name: "description", editable: true, resizable: true, type: "text" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "preview", name: "preview", editable: true, resizable: true, type: "blob" },
			],
			form2dto: (data) => {
				if(data.description == "")
					data.description = null;
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'physical-afis-mapper': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "afisId", name: "afis_id", editable: true, resizable: true, type: "number" },
			   	{ key: "physicalId", name: "physical_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				return data
			}
		},
		'polling-units-metadata': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "registrationAreaId", name: "registration_area_id", editable: true, resizable: true, type: "select", metadata: "registration-areas-metadata"  },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'union-record-tag-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			   	{ key: "unionRecordId", name: "union_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'administrative-update-mtlb-tag-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "administrativeUpdateMtlbId", name: "administrative_update_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'email': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "administrativeUpdateMtlbId", name: "administrative_update_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "email", name: "email", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.email == "")
					data.email = null;
				return data
			}
		},
		'address': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "addressType", name: "address_type", editable: true, resizable: true, type: "number" },
			   	{ key: "administrativeUpdateMtlbId", name: "administrative_update_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "areaId", name: "area_id", editable: true, resizable: true, type: "text" },
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fromDate", name: "from_date", editable: true, resizable: true, type: "date" },
			   	{ key: "locationId", name: "location_id", editable: true, resizable: true, type: "number" },
			   	{ key: "toDate", name: "to_date", editable: true, resizable: true, type: "date" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.areaId == "")
					data.areaId = null;
				if(data.fromDate != null && data.fromDate instanceof Date)
					data.fromDate = data.fromDate.getFullYear() + "-" + ('0' + (data.fromDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.fromDate.getDate()).slice(-2);
				if(data.toDate != null && data.toDate instanceof Date)
					data.toDate = data.toDate.getFullYear() + "-" + ('0' + (data.toDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.toDate.getDate()).slice(-2);
				return data
			}
		},
		'audit-log': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "actionTime", name: "action_time", editable: true, resizable: true, type: "time" },
			   	{ key: "actionType", name: "action_type", editable: true, resizable: true, type: "text" },
			   	{ key: "rowPk", name: "row_pk", editable: true, resizable: true, type: "number" },
			   	{ key: "tableName", name: "table_name", editable: true, resizable: true, type: "text" },
			   	{ key: "userId", name: "user_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.actionType == "")
					data.actionType = null;
				if(data.tableName == "")
					data.tableName = null;
				if(data.userId == "")
					data.userId = null;
				return data
			}
		},
		'id-mtlb-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "afisId", name: "afis_id", editable: true, resizable: true, type: "number" },
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "face", name: "face", editable: true, resizable: true, type: "blob" },
			   	{ key: "faceMimeType", name: "face_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "fingersMimeType", name: "fingers_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "isVoter", name: "is_voter", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "leftIndexFinger", name: "left_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftMiddleFinger", name: "left_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftPinky", name: "left_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftRingFinger", name: "left_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftThumb", name: "left_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "mtlbLocation", name: "mtlb_location", editable: true, resizable: true, type: "text" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatusData", name: "mtlb_status_data", editable: true, resizable: true, type: "json" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "numberOfAttachments", name: "number_of_attachments", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedId", name: "reported_id", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedLegacyId", name: "reported_legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "requestedIdType", name: "requested_id_type", editable: true, resizable: true, type: "number" },
			   	{ key: "residenceDepartment", name: "residence_department", editable: true, resizable: true, type: "text" },
			   	{ key: "residenceMunicipality", name: "residence_municipality", editable: true, resizable: true, type: "text" },
			   	{ key: "rightIndexFinger", name: "right_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightMiddleFinger", name: "right_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightPinky", name: "right_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightRingFinger", name: "right_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightThumb", name: "right_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "signature", name: "signature", editable: true, resizable: true, type: "blob" },
			   	{ key: "signatureMimeType", name: "signature_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "votingCenter", name: "voting_center", editable: true, resizable: true, type: "text" },
			   	{ key: "votingDepartment", name: "voting_department", editable: true, resizable: true, type: "text" },
			   	{ key: "votingMunicipality", name: "voting_municipality", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.faceMimeType == "")
					data.faceMimeType = null;
				if(data.fingersMimeType == "")
					data.fingersMimeType = null;
				if(data.mtlbLocation == "")
					data.mtlbLocation = null;
				if(data.notes == "")
					data.notes = null;
				if(data.reportedLegacyId == "")
					data.reportedLegacyId = null;
				if(data.residenceDepartment == "")
					data.residenceDepartment = null;
				if(data.residenceMunicipality == "")
					data.residenceMunicipality = null;
				if(data.signatureMimeType == "")
					data.signatureMimeType = null;
				if(data.votingCenter == "")
					data.votingCenter = null;
				if(data.votingDepartment == "")
					data.votingDepartment = null;
				if(data.votingMunicipality == "")
					data.votingMunicipality = null;
				return data
			}
		},
		'administrative-update-mtlb-tag': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "administrativeUpdateMtlbId", name: "administrative_update_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'locations-new-locations': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "locationsId", name: "locations_id", editable: true, resizable: true, type: "text" },
			   	{ key: "newLocationsId", name: "new_locations_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.locationsId == "")
					data.locationsId = null;
				if(data.newLocationsId == "")
					data.newLocationsId = null;
				return data
			}
		},
		'union-mtlb-attachment': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "description", name: "description", editable: true, resizable: true, type: "text" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "preview", name: "preview", editable: true, resizable: true, type: "blob" },
			   	{ key: "unionMtlbId", name: "union_mtlb_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.description == "")
					data.description = null;
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'signature-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "idMtlbId", name: "id_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "image", name: "image", editable: true, resizable: true, type: "blob" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				return data
			}
		},
		'legacy-id-trash': {
			columns: [
			   	{ key: "newId", name: "new_id", editable: false, resizable: true},
			   	{ key: "legacyId", name: "legacy_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.legacyId == "")
					data.legacyId = null;
				return data
			}
		},
		'face': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "idMtlbId", name: "id_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "image", name: "image", editable: true, resizable: true, type: "blob" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				return data
			}
		},
		'administrative-information': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "address", name: "address", editable: true, resizable: true, type: "text" },
			   	{ key: "email", name: "email", editable: true, resizable: true, type: "text" },
			   	{ key: "phoneNumber", name: "phone_number", editable: true, resizable: true, type: "text" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.address == "")
					data.address = null;
				if(data.email == "")
					data.email = null;
				if(data.phoneNumber == "")
					data.phoneNumber = null;
				return data
			}
		},
		'removal-mtlb-attachment-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "description", name: "description", editable: true, resizable: true, type: "text" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "preview", name: "preview", editable: true, resizable: true, type: "blob" },
			   	{ key: "removalMtlbId", name: "removal_mtlb_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.description == "")
					data.description = null;
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'union-record': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "address", name: "address", editable: true, resizable: true, type: "text" },
			   	{ key: "annulmentOrder", name: "annulment_order", editable: true, resizable: true, type: "text" },
			   	{ key: "dateOfEffect", name: "date_of_effect", editable: true, resizable: true, type: "date" },
			   	{ key: "dateOfMarriage", name: "date_of_marriage", editable: true, resizable: true, type: "date" },
			   	{ key: "dateOfOrder", name: "date_of_order", editable: true, resizable: true, type: "date" },
			   	{ key: "firstWitnessId", name: "first_witness_id", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1FatherId", name: "partner1_father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner1FatherName", name: "partner1_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1Firstname", name: "partner1_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1MotherId", name: "partner1_mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner1MotherName", name: "partner1_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1Surname", name: "partner1_surname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1VitalRecordId", name: "partner1_vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner2FatherId", name: "partner2_father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner2FatherName", name: "partner2_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2Firstname", name: "partner2_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2MotherId", name: "partner2_mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner2MotherName", name: "partner2_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2Surname", name: "partner2_surname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2VitalRecordId", name: "partner2_vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "place", name: "place", editable: true, resizable: true, type: "text" },
			   	{ key: "rites", name: "rites", editable: true, resizable: true, type: "text" },
			   	{ key: "secondWitnessId", name: "second_witness_id", editable: true, resizable: true, type: "number" },
			   	{ key: "timeOfMarriage", name: "time_of_marriage", editable: true, resizable: true, type: "time" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "unionType", name: "union_type", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.address == "")
					data.address = null;
				if(data.annulmentOrder == "")
					data.annulmentOrder = null;
				if(data.dateOfEffect != null && data.dateOfEffect instanceof Date)
					data.dateOfEffect = data.dateOfEffect.getFullYear() + "-" + ('0' + (data.dateOfEffect.getMonth() + 1)).slice(-2) + "-" + ('0' + data.dateOfEffect.getDate()).slice(-2);
				if(data.dateOfMarriage != null && data.dateOfMarriage instanceof Date)
					data.dateOfMarriage = data.dateOfMarriage.getFullYear() + "-" + ('0' + (data.dateOfMarriage.getMonth() + 1)).slice(-2) + "-" + ('0' + data.dateOfMarriage.getDate()).slice(-2);
				if(data.dateOfOrder != null && data.dateOfOrder instanceof Date)
					data.dateOfOrder = data.dateOfOrder.getFullYear() + "-" + ('0' + (data.dateOfOrder.getMonth() + 1)).slice(-2) + "-" + ('0' + data.dateOfOrder.getDate()).slice(-2);
				if(data.notes == "")
					data.notes = null;
				if(data.partner1FatherName == "")
					data.partner1FatherName = null;
				if(data.partner1Firstname == "")
					data.partner1Firstname = null;
				if(data.partner1MotherName == "")
					data.partner1MotherName = null;
				if(data.partner1Surname == "")
					data.partner1Surname = null;
				if(data.partner2FatherName == "")
					data.partner2FatherName = null;
				if(data.partner2Firstname == "")
					data.partner2Firstname = null;
				if(data.partner2MotherName == "")
					data.partner2MotherName = null;
				if(data.partner2Surname == "")
					data.partner2Surname = null;
				if(data.place == "")
					data.place = null;
				if(data.rites == "")
					data.rites = null;
				return data
			}
		},
		'vital-record-attachment': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "description", name: "description", editable: true, resizable: true, type: "text" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "fileType", name: "file_type", editable: true, resizable: true, type: "number" },
			   	{ key: "preview", name: "preview", editable: true, resizable: true, type: "blob" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.description == "")
					data.description = null;
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'user-account': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "image", name: "image", editable: true, resizable: true, type: "blob" },
			   	{ key: "userId", name: "user_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.userId == "")
					data.userId = null;
				return data
			}
		},
		'id-mtlb-attachment': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "description", name: "description", editable: true, resizable: true, type: "text" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "idMtlbId", name: "id_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "preview", name: "preview", editable: true, resizable: true, type: "blob" },
			],
			form2dto: (data) => {
				if(data.description == "")
					data.description = null;
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'vital-record': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "adoptiveFatherId", name: "adoptive_father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "adoptiveMotherId", name: "adoptive_mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "birthCountry", name: "birth_country", editable: true, resizable: true, type: "number" },
			   	{ key: "birthDayUnknown", name: "birth_day_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthMonthUnknown", name: "birth_month_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthPlace", name: "birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "birthTime", name: "birth_time", editable: true, resizable: true, type: "time" },
			   	{ key: "birthTimeUnknown", name: "birth_time_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthYearUnknown", name: "birth_year_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthdate", name: "birthdate", editable: true, resizable: true, type: "date" },
			   	{ key: "cardId", name: "card_id", editable: true, resizable: true, type: "number" },
			   	{ key: "commonIdNumber", name: "common_id_number", editable: true, resizable: true, type: "text" },
			   	{ key: "courtAdoptionNumber", name: "court_adoption_number", editable: true, resizable: true, type: "text" },
			   	{ key: "declarantId", name: "declarant_id", editable: true, resizable: true, type: "number" },
			   	{ key: "disability", name: "disability", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donor", name: "donor", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donorNotes", name: "donor_notes", editable: true, resizable: true, type: "text" },
			   	{ key: "faceId", name: "face_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fatherId", name: "father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fifthname", name: "fifthname", editable: true, resizable: true, type: "text" },
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "fourthname", name: "fourthname", editable: true, resizable: true, type: "text" },
			   	{ key: "gender", name: "gender", editable: true, resizable: true, type: "number" },
			   	{ key: "inactive", name: "inactive", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "isDeleted", name: "is_deleted", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "maritalStatus", name: "marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "markedInactive", name: "marked_inactive", editable: true, resizable: true, type: "date" },
			   	{ key: "marriageCertificateNumberAdoptive", name: "marriage_certificate_number_adoptive", editable: true, resizable: true, type: "text" },
			   	{ key: "medicalNotificationFile", name: "medical_notification_file", editable: true, resizable: true, type: "text" },
			   	{ key: "motherId", name: "mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "otherCitizenshipCsv", name: "other_citizenship_csv", editable: true, resizable: true, type: "text" },
			   	{ key: "primaryCitizenship", name: "primary_citizenship", editable: true, resizable: true, type: "text" },
			   	{ key: "recordStatus", name: "record_status", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedFatherName", name: "reported_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedMotherName", name: "reported_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "secondname", name: "secondname", editable: true, resizable: true, type: "text" },
			   	{ key: "thirdname", name: "thirdname", editable: true, resizable: true, type: "text" },
			   	{ key: "timestamp", name: "timestamp", editable: true, resizable: true, type: "timestampz" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "tribe", name: "tribe", editable: true, resizable: true, type: "number" },
			   	{ key: "typeOfAcquisition", name: "type_of_acquisition", editable: true, resizable: true, type: "number" },
			   	{ key: "unknownFather", name: "unknown_father", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "unknownMother", name: "unknown_mother", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "villageOfOrigin", name: "village_of_origin", editable: true, resizable: true, type: "text" },
			   	{ key: "villageOfOriginAdoptive", name: "village_of_origin_adoptive", editable: true, resizable: true, type: "text" },
			   	{ key: "voter", name: "voter", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "witness1Id", name: "witness1_id", editable: true, resizable: true, type: "number" },
			   	{ key: "witness2Id", name: "witness2_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.birthPlace == "")
					data.birthPlace = null;
				if(data.birthdate != null && data.birthdate instanceof Date)
					data.birthdate = data.birthdate.getFullYear() + "-" + ('0' + (data.birthdate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.birthdate.getDate()).slice(-2);
				if(data.commonIdNumber == "")
					data.commonIdNumber = null;
				if(data.courtAdoptionNumber == "")
					data.courtAdoptionNumber = null;
				if(data.donorNotes == "")
					data.donorNotes = null;
				if(data.fifthname == "")
					data.fifthname = null;
				if(data.firstname == "")
					data.firstname = null;
				if(data.fourthname == "")
					data.fourthname = null;
				if(data.markedInactive != null && data.markedInactive instanceof Date)
					data.markedInactive = data.markedInactive.getFullYear() + "-" + ('0' + (data.markedInactive.getMonth() + 1)).slice(-2) + "-" + ('0' + data.markedInactive.getDate()).slice(-2);
				if(data.marriageCertificateNumberAdoptive == "")
					data.marriageCertificateNumberAdoptive = null;
				if(data.notes == "")
					data.notes = null;
				if(data.otherCitizenshipCsv == "")
					data.otherCitizenshipCsv = null;
				if(data.primaryCitizenship == "")
					data.primaryCitizenship = null;
				if(data.reportedFatherName == "")
					data.reportedFatherName = null;
				if(data.reportedMotherName == "")
					data.reportedMotherName = null;
				if(data.secondname == "")
					data.secondname = null;
				if(data.thirdname == "")
					data.thirdname = null;
				if(data.timestamp != null && data.timestamp instanceof Date)
					data.timestamp = data.timestamp.getFullYear() + "-" + ('0' + (data.timestamp.getMonth() + 1)).slice(-2) + "-" + ('0' + data.timestamp.getDate()).slice(-2);
				if(data.villageOfOrigin == "")
					data.villageOfOrigin = null;
				if(data.villageOfOriginAdoptive == "")
					data.villageOfOriginAdoptive = null;
				return data
			}
		},
		'country': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "flag", name: "flag", editable: true, resizable: true, type: "blob" },
			   	{ key: "flagMimeType", name: "flag_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.flagMimeType == "")
					data.flagMimeType = null;
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'id-mtlb': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "afisId", name: "afis_id", editable: true, resizable: true, type: "number" },
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deduplicationMtlbId", name: "deduplication_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "face", name: "face", editable: true, resizable: true, type: "blob" },
			   	{ key: "faceMimeType", name: "face_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "fingersMimeType", name: "fingers_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "fourthname", name: "fourthname", editable: true, resizable: true, type: "text" },
			   	{ key: "isVoter", name: "is_voter", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "leftIndexFinger", name: "left_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftMiddleFinger", name: "left_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftPinky", name: "left_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftRingFinger", name: "left_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftThumb", name: "left_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "mtlbLocation", name: "mtlb_location", editable: true, resizable: true, type: "text" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatusData", name: "mtlb_status_data", editable: true, resizable: true, type: "json" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "numberOfAttachments", name: "number_of_attachments", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedId", name: "reported_id", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedLegacyId", name: "reported_legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "requestedIdType", name: "requested_id_type", editable: true, resizable: true, type: "number" },
			   	{ key: "residenceDepartment", name: "residence_department", editable: true, resizable: true, type: "text" },
			   	{ key: "residenceMunicipality", name: "residence_municipality", editable: true, resizable: true, type: "text" },
			   	{ key: "rightIndexFinger", name: "right_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightMiddleFinger", name: "right_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightPinky", name: "right_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightRingFinger", name: "right_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightThumb", name: "right_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "secondname", name: "secondname", editable: true, resizable: true, type: "text" },
			   	{ key: "signature", name: "signature", editable: true, resizable: true, type: "blob" },
			   	{ key: "signatureMimeType", name: "signature_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "thirdname", name: "thirdname", editable: true, resizable: true, type: "text" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "uploaded", name: "uploaded", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "uuid", name: "uuid", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "votingCenter", name: "voting_center", editable: true, resizable: true, type: "text" },
			   	{ key: "votingDepartment", name: "voting_department", editable: true, resizable: true, type: "text" },
			   	{ key: "votingMunicipality", name: "voting_municipality", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.faceMimeType == "")
					data.faceMimeType = null;
				if(data.fingersMimeType == "")
					data.fingersMimeType = null;
				if(data.firstname == "")
					data.firstname = null;
				if(data.fourthname == "")
					data.fourthname = null;
				if(data.mtlbLocation == "")
					data.mtlbLocation = null;
				if(data.notes == "")
					data.notes = null;
				if(data.reportedLegacyId == "")
					data.reportedLegacyId = null;
				if(data.residenceDepartment == "")
					data.residenceDepartment = null;
				if(data.residenceMunicipality == "")
					data.residenceMunicipality = null;
				if(data.secondname == "")
					data.secondname = null;
				if(data.signatureMimeType == "")
					data.signatureMimeType = null;
				if(data.thirdname == "")
					data.thirdname = null;
				if(data.uuid == "")
					data.uuid = null;
				if(data.votingCenter == "")
					data.votingCenter = null;
				if(data.votingDepartment == "")
					data.votingDepartment = null;
				if(data.votingMunicipality == "")
					data.votingMunicipality = null;
				return data
			}
		},
		'adoptive-fathers': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "childId", name: "child_id", editable: true, resizable: true, type: "number" },
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "courtAdoptionNumber", name: "court_adoption_number", editable: true, resizable: true, type: "text" },
			   	{ key: "current", name: "current", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "fatherAdoptionDate", name: "father_adoption_date", editable: true, resizable: true, type: "date" },
			   	{ key: "fatherEndAdoptionDate", name: "father_end_adoption_date", editable: true, resizable: true, type: "date" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.courtAdoptionNumber == "")
					data.courtAdoptionNumber = null;
				if(data.fatherAdoptionDate != null && data.fatherAdoptionDate instanceof Date)
					data.fatherAdoptionDate = data.fatherAdoptionDate.getFullYear() + "-" + ('0' + (data.fatherAdoptionDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.fatherAdoptionDate.getDate()).slice(-2);
				if(data.fatherEndAdoptionDate != null && data.fatherEndAdoptionDate instanceof Date)
					data.fatherEndAdoptionDate = data.fatherEndAdoptionDate.getFullYear() + "-" + ('0' + (data.fatherEndAdoptionDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.fatherEndAdoptionDate.getDate()).slice(-2);
				return data
			}
		},
		'union-mtlb': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "address", name: "address", editable: true, resizable: true, type: "text" },
			   	{ key: "annullmentOrder", name: "annullment_order", editable: true, resizable: true, type: "text" },
			   	{ key: "dateOfEffect", name: "date_of_effect", editable: true, resizable: true, type: "date" },
			   	{ key: "dateOfOrder", name: "date_of_order", editable: true, resizable: true, type: "date" },
			   	{ key: "declaredDate", name: "declared_date", editable: true, resizable: true, type: "date" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatusData", name: "mtlb_status_data", editable: true, resizable: true, type: "json" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1BirthPlace", name: "partner1_birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1FatherId", name: "partner1_father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner1FatherName", name: "partner1_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1Firstname", name: "partner1_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1IndividualMelanesianName", name: "partner1_individual_melanesian_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1MaritalStatus", name: "partner1_marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "partner1MotherId", name: "partner1_mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner1MotherName", name: "partner1_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1Occupation", name: "partner1_occupation", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1ResidenceAddress", name: "partner1_residence_address", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1Surname", name: "partner1_surname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1VitalRecordId", name: "partner1_vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner2BirthPlace", name: "partner2_birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2FatherId", name: "partner2_father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner2FatherName", name: "partner2_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2Firstname", name: "partner2_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2IndividualMelanesianName", name: "partner2_individual_melanesian_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2MaritalStatus", name: "partner2_marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "partner2MotherId", name: "partner2_mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner2MotherName", name: "partner2_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2Occupation", name: "partner2_occupation", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2ResidenceAddress", name: "partner2_residence_address", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2Surname", name: "partner2_surname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2VitalRecordId", name: "partner2_vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "place", name: "place", editable: true, resizable: true, type: "text" },
			   	{ key: "rites", name: "rites", editable: true, resizable: true, type: "text" },
			   	{ key: "timeOfMarriage", name: "time_of_marriage", editable: true, resizable: true, type: "time" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "unionRecordId", name: "union_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "unionRegime", name: "union_regime", editable: true, resizable: true, type: "number" },
			   	{ key: "uploaded", name: "uploaded", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "uuid", name: "uuid", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1BirthPlace", name: "witness1_birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1Firstname", name: "witness1_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1Occupation", name: "witness1_occupation", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1ResidenceAddress", name: "witness1_residence_address", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1Surname", name: "witness1_surname", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1VitalRecordId", name: "witness1_vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "witness2BirthPlace", name: "witness2_birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2Firstname", name: "witness2_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2Occupation", name: "witness2_occupation", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2ResidenceAddress", name: "witness2_residence_address", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2Surname", name: "witness2_surname", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2VitalRecordId", name: "witness2_vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.address == "")
					data.address = null;
				if(data.annullmentOrder == "")
					data.annullmentOrder = null;
				if(data.dateOfEffect != null && data.dateOfEffect instanceof Date)
					data.dateOfEffect = data.dateOfEffect.getFullYear() + "-" + ('0' + (data.dateOfEffect.getMonth() + 1)).slice(-2) + "-" + ('0' + data.dateOfEffect.getDate()).slice(-2);
				if(data.dateOfOrder != null && data.dateOfOrder instanceof Date)
					data.dateOfOrder = data.dateOfOrder.getFullYear() + "-" + ('0' + (data.dateOfOrder.getMonth() + 1)).slice(-2) + "-" + ('0' + data.dateOfOrder.getDate()).slice(-2);
				if(data.declaredDate != null && data.declaredDate instanceof Date)
					data.declaredDate = data.declaredDate.getFullYear() + "-" + ('0' + (data.declaredDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.declaredDate.getDate()).slice(-2);
				if(data.notes == "")
					data.notes = null;
				if(data.partner1BirthPlace == "")
					data.partner1BirthPlace = null;
				if(data.partner1FatherName == "")
					data.partner1FatherName = null;
				if(data.partner1Firstname == "")
					data.partner1Firstname = null;
				if(data.partner1IndividualMelanesianName == "")
					data.partner1IndividualMelanesianName = null;
				if(data.partner1MotherName == "")
					data.partner1MotherName = null;
				if(data.partner1Occupation == "")
					data.partner1Occupation = null;
				if(data.partner1ResidenceAddress == "")
					data.partner1ResidenceAddress = null;
				if(data.partner1Surname == "")
					data.partner1Surname = null;
				if(data.partner2BirthPlace == "")
					data.partner2BirthPlace = null;
				if(data.partner2FatherName == "")
					data.partner2FatherName = null;
				if(data.partner2Firstname == "")
					data.partner2Firstname = null;
				if(data.partner2IndividualMelanesianName == "")
					data.partner2IndividualMelanesianName = null;
				if(data.partner2MotherName == "")
					data.partner2MotherName = null;
				if(data.partner2Occupation == "")
					data.partner2Occupation = null;
				if(data.partner2ResidenceAddress == "")
					data.partner2ResidenceAddress = null;
				if(data.partner2Surname == "")
					data.partner2Surname = null;
				if(data.place == "")
					data.place = null;
				if(data.rites == "")
					data.rites = null;
				if(data.uuid == "")
					data.uuid = null;
				if(data.witness1BirthPlace == "")
					data.witness1BirthPlace = null;
				if(data.witness1Firstname == "")
					data.witness1Firstname = null;
				if(data.witness1Occupation == "")
					data.witness1Occupation = null;
				if(data.witness1ResidenceAddress == "")
					data.witness1ResidenceAddress = null;
				if(data.witness1Surname == "")
					data.witness1Surname = null;
				if(data.witness2BirthPlace == "")
					data.witness2BirthPlace = null;
				if(data.witness2Firstname == "")
					data.witness2Firstname = null;
				if(data.witness2Occupation == "")
					data.witness2Occupation = null;
				if(data.witness2ResidenceAddress == "")
					data.witness2ResidenceAddress = null;
				if(data.witness2Surname == "")
					data.witness2Surname = null;
				return data
			}
		},
		'death-record-tag': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'civil-status-mtlb': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "adoptiveFatherId", name: "adoptive_father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "adoptiveMotherId", name: "adoptive_mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "afisId", name: "afis_id", editable: true, resizable: true, type: "number" },
			   	{ key: "attachment", name: "attachment", editable: true, resizable: true, type: "text" },
			   	{ key: "birthCountry", name: "birth_country", editable: true, resizable: true, type: "number" },
			   	{ key: "birthDayUnknown", name: "birth_day_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthMonthUnknown", name: "birth_month_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthPlace", name: "birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "birthTime", name: "birth_time", editable: true, resizable: true, type: "time" },
			   	{ key: "birthTimeUnknown", name: "birth_time_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthYearUnknown", name: "birth_year_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthdate", name: "birthdate", editable: true, resizable: true, type: "date" },
			   	{ key: "book", name: "book", editable: true, resizable: true, type: "number" },
			   	{ key: "burialPlace", name: "burial_place", editable: true, resizable: true, type: "text" },
			   	{ key: "courtAdoptionNumber", name: "court_adoption_number", editable: true, resizable: true, type: "text" },
			   	{ key: "dateOfDeath", name: "date_of_death", editable: true, resizable: true, type: "date" },
			   	{ key: "deathDayUnknown", name: "death_day_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathMonthUnknown", name: "death_month_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathNotification", name: "death_notification", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathNotificationFile", name: "death_notification_file", editable: true, resizable: true, type: "text" },
			   	{ key: "deathPlace", name: "death_place", editable: true, resizable: true, type: "text" },
			   	{ key: "deathTimeUnknown", name: "death_time_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathYearUnknown", name: "death_year_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "declarantId", name: "declarant_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deduplicationMtlbId", name: "deduplication_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "disability", name: "disability", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donor", name: "donor", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donorNotes", name: "donor_notes", editable: true, resizable: true, type: "text" },
			   	{ key: "face", name: "face", editable: true, resizable: true, type: "blob" },
			   	{ key: "faceMimeType", name: "face_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "fatherEndAdoptionDate", name: "father_end_adoption_date", editable: true, resizable: true, type: "date" },
			   	{ key: "fatherId", name: "father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fifthname", name: "fifthname", editable: true, resizable: true, type: "text" },
			   	{ key: "fingersMimeType", name: "fingers_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "fourthname", name: "fourthname", editable: true, resizable: true, type: "text" },
			   	{ key: "gender", name: "gender", editable: true, resizable: true, type: "number" },
			   	{ key: "leftIndexFinger", name: "left_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftMiddleFinger", name: "left_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftPinky", name: "left_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftRingFinger", name: "left_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftThumb", name: "left_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "maritalStatus", name: "marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "marriageCertificateNumberAdoptive", name: "marriage_certificate_number_adoptive", editable: true, resizable: true, type: "text" },
			   	{ key: "medicalNotification", name: "medical_notification", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "medicalNotificationFile", name: "medical_notification_file", editable: true, resizable: true, type: "text" },
			   	{ key: "motherEndAdoptionDate", name: "mother_end_adoption_date", editable: true, resizable: true, type: "date" },
			   	{ key: "motherId", name: "mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatusData", name: "mtlb_status_data", editable: true, resizable: true, type: "json" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "numberOfAttachments", name: "number_of_attachments", editable: true, resizable: true, type: "number" },
			   	{ key: "otherCitizenshipCsv", name: "other_citizenship_csv", editable: true, resizable: true, type: "text" },
			   	{ key: "page", name: "page", editable: true, resizable: true, type: "number" },
			   	{ key: "primaryCitizenship", name: "primary_citizenship", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedFatherName", name: "reported_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedId", name: "reported_id", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedLegacyId", name: "reported_legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedMotherName", name: "reported_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "rightIndexFinger", name: "right_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightMiddleFinger", name: "right_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightPinky", name: "right_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightRingFinger", name: "right_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightThumb", name: "right_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "secondname", name: "secondname", editable: true, resizable: true, type: "text" },
			   	{ key: "signature", name: "signature", editable: true, resizable: true, type: "blob" },
			   	{ key: "signatureMimeType", name: "signature_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "sixthname", name: "sixthname", editable: true, resizable: true, type: "text" },
			   	{ key: "thirdname", name: "thirdname", editable: true, resizable: true, type: "text" },
			   	{ key: "timeOfDeath", name: "time_of_death", editable: true, resizable: true, type: "time" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "tribe", name: "tribe", editable: true, resizable: true, type: "number" },
			   	{ key: "typeOfAcquisition", name: "type_of_acquisition", editable: true, resizable: true, type: "number" },
			   	{ key: "unknownFather", name: "unknown_father", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "unknownMother", name: "unknown_mother", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "uploaded", name: "uploaded", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "uuid", name: "uuid", editable: true, resizable: true, type: "text" },
			   	{ key: "villageOfOrigin", name: "village_of_origin", editable: true, resizable: true, type: "text" },
			   	{ key: "villageOfOriginAdoptive", name: "village_of_origin_adoptive", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "witness1Id", name: "witness1_id", editable: true, resizable: true, type: "number" },
			   	{ key: "witness2Id", name: "witness2_id", editable: true, resizable: true, type: "number" },
			   	{ key: "year", name: "year", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.birthPlace == "")
					data.birthPlace = null;
				if(data.birthdate != null && data.birthdate instanceof Date)
					data.birthdate = data.birthdate.getFullYear() + "-" + ('0' + (data.birthdate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.birthdate.getDate()).slice(-2);
				if(data.burialPlace == "")
					data.burialPlace = null;
				if(data.courtAdoptionNumber == "")
					data.courtAdoptionNumber = null;
				if(data.dateOfDeath != null && data.dateOfDeath instanceof Date)
					data.dateOfDeath = data.dateOfDeath.getFullYear() + "-" + ('0' + (data.dateOfDeath.getMonth() + 1)).slice(-2) + "-" + ('0' + data.dateOfDeath.getDate()).slice(-2);
				if(data.deathPlace == "")
					data.deathPlace = null;
				if(data.donorNotes == "")
					data.donorNotes = null;
				if(data.faceMimeType == "")
					data.faceMimeType = null;
				if(data.fatherEndAdoptionDate != null && data.fatherEndAdoptionDate instanceof Date)
					data.fatherEndAdoptionDate = data.fatherEndAdoptionDate.getFullYear() + "-" + ('0' + (data.fatherEndAdoptionDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.fatherEndAdoptionDate.getDate()).slice(-2);
				if(data.fifthname == "")
					data.fifthname = null;
				if(data.fingersMimeType == "")
					data.fingersMimeType = null;
				if(data.firstname == "")
					data.firstname = null;
				if(data.fourthname == "")
					data.fourthname = null;
				if(data.marriageCertificateNumberAdoptive == "")
					data.marriageCertificateNumberAdoptive = null;
				if(data.motherEndAdoptionDate != null && data.motherEndAdoptionDate instanceof Date)
					data.motherEndAdoptionDate = data.motherEndAdoptionDate.getFullYear() + "-" + ('0' + (data.motherEndAdoptionDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.motherEndAdoptionDate.getDate()).slice(-2);
				if(data.notes == "")
					data.notes = null;
				if(data.otherCitizenshipCsv == "")
					data.otherCitizenshipCsv = null;
				if(data.primaryCitizenship == "")
					data.primaryCitizenship = null;
				if(data.reportedFatherName == "")
					data.reportedFatherName = null;
				if(data.reportedLegacyId == "")
					data.reportedLegacyId = null;
				if(data.reportedMotherName == "")
					data.reportedMotherName = null;
				if(data.secondname == "")
					data.secondname = null;
				if(data.signatureMimeType == "")
					data.signatureMimeType = null;
				if(data.sixthname == "")
					data.sixthname = null;
				if(data.thirdname == "")
					data.thirdname = null;
				if(data.uuid == "")
					data.uuid = null;
				if(data.villageOfOrigin == "")
					data.villageOfOrigin = null;
				if(data.villageOfOriginAdoptive == "")
					data.villageOfOriginAdoptive = null;
				if(data.year == "")
					data.year = null;
				return data
			}
		},
		'administrative-information-attachment-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "administrativeInformationId", name: "administrative_information_id", editable: true, resizable: true, type: "number" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'occupation-metadata': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'digitalization-queue': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "userId", name: "user_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				if(data.userId == "")
					data.userId = null;
				return data
			}
		},
		'fingerprints-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "afisId", name: "afis_id", editable: true, resizable: true, type: "number" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "leftIndexFinger", name: "left_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftMiddleFinger", name: "left_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftPinky", name: "left_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftRingFinger", name: "left_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftThumb", name: "left_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "physicalId", name: "physical_id", editable: true, resizable: true, type: "number" },
			   	{ key: "rightIndexFinger", name: "right_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightMiddleFinger", name: "right_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightPinky", name: "right_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightRingFinger", name: "right_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightThumb", name: "right_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				return data
			}
		},
		'geo-data': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "areaId", name: "area_id", editable: true, resizable: true, type: "text" },
			   	{ key: "disabled", name: "disabled", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "flag", name: "flag", editable: true, resizable: true, type: "blob" },
			   	{ key: "flagMimeType", name: "flag_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "parentId", name: "parent_id", editable: true, resizable: true, type: "number" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.areaId == "")
					data.areaId = null;
				if(data.flagMimeType == "")
					data.flagMimeType = null;
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'union-mtlb-tag': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			   	{ key: "unionMtlbId", name: "union_mtlb_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'address-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "addressType", name: "address_type", editable: true, resizable: true, type: "number" },
			   	{ key: "administrativeUpdateMtlbId", name: "administrative_update_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "areaId", name: "area_id", editable: true, resizable: true, type: "text" },
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fromDate", name: "from_date", editable: true, resizable: true, type: "date" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "locationId", name: "location_id", editable: true, resizable: true, type: "number" },
			   	{ key: "toDate", name: "to_date", editable: true, resizable: true, type: "date" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.areaId == "")
					data.areaId = null;
				if(data.fromDate != null && data.fromDate instanceof Date)
					data.fromDate = data.fromDate.getFullYear() + "-" + ('0' + (data.fromDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.fromDate.getDate()).slice(-2);
				if(data.toDate != null && data.toDate instanceof Date)
					data.toDate = data.toDate.getFullYear() + "-" + ('0' + (data.toDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.toDate.getDate()).slice(-2);
				return data
			}
		},
		'administrative-information-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "city", name: "city", editable: true, resizable: true, type: "text" },
			   	{ key: "donor", name: "donor", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "email", name: "email", editable: true, resizable: true, type: "text" },
			   	{ key: "occupationId", name: "occupation_id", editable: true, resizable: true, type: "number" },
			   	{ key: "otherPhone", name: "other_phone", editable: true, resizable: true, type: "text" },
			   	{ key: "phoneNumber", name: "phone_number", editable: true, resizable: true, type: "text" },
			   	{ key: "residenceCountryId", name: "residence_country_id", editable: true, resizable: true, type: "number" },
			   	{ key: "residenceLocation", name: "residence_location", editable: true, resizable: true, type: "text" },
			   	{ key: "streetAddress", name: "street_address", editable: true, resizable: true, type: "text" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "zipCode", name: "zip_code", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.city == "")
					data.city = null;
				if(data.email == "")
					data.email = null;
				if(data.otherPhone == "")
					data.otherPhone = null;
				if(data.phoneNumber == "")
					data.phoneNumber = null;
				if(data.residenceLocation == "")
					data.residenceLocation = null;
				if(data.streetAddress == "")
					data.streetAddress = null;
				if(data.zipCode == "")
					data.zipCode = null;
				return data
			}
		},
		'rvfour-divorce-mtlb': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "annex", name: "annex", editable: true, resizable: true, type: "text" },
			   	{ key: "dateOfEffect", name: "date_of_effect", editable: true, resizable: true, type: "date" },
			   	{ key: "dateOfOrder", name: "date_of_order", editable: true, resizable: true, type: "date" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "unionRecordId", name: "union_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.annex == "")
					data.annex = null;
				if(data.dateOfEffect != null && data.dateOfEffect instanceof Date)
					data.dateOfEffect = data.dateOfEffect.getFullYear() + "-" + ('0' + (data.dateOfEffect.getMonth() + 1)).slice(-2) + "-" + ('0' + data.dateOfEffect.getDate()).slice(-2);
				if(data.dateOfOrder != null && data.dateOfOrder instanceof Date)
					data.dateOfOrder = data.dateOfOrder.getFullYear() + "-" + ('0' + (data.dateOfOrder.getMonth() + 1)).slice(-2) + "-" + ('0' + data.dateOfOrder.getDate()).slice(-2);
				return data
			}
		},
		'digitalization-queue-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "userId", name: "user_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				if(data.userId == "")
					data.userId = null;
				return data
			}
		},
		'union-record-tag': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			   	{ key: "unionRecordId", name: "union_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'id-mtlb-tag': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "idMtlbId", name: "id_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'fingerprints': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "afisId", name: "afis_id", editable: true, resizable: true, type: "number" },
			   	{ key: "leftIndexFinger", name: "left_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftMiddleFinger", name: "left_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftPinky", name: "left_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftRingFinger", name: "left_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftThumb", name: "left_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "physicalId", name: "physical_id", editable: true, resizable: true, type: "number" },
			   	{ key: "rightIndexFinger", name: "right_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightMiddleFinger", name: "right_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightPinky", name: "right_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightRingFinger", name: "right_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightThumb", name: "right_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				return data
			}
		},
		'union-mtlb-tag-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			   	{ key: "unionMtlbId", name: "union_mtlb_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'civil-status-mtlb-attachment-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "description", name: "description", editable: true, resizable: true, type: "text" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "preview", name: "preview", editable: true, resizable: true, type: "blob" },
			],
			form2dto: (data) => {
				if(data.description == "")
					data.description = null;
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'rvfour-death-mtlb': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "annex", name: "annex", editable: true, resizable: true, type: "text" },
			   	{ key: "dateOfDeath", name: "date_of_death", editable: true, resizable: true, type: "date" },
			   	{ key: "deathLocation", name: "death_location", editable: true, resizable: true, type: "text" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "declarantId", name: "declarant_id", editable: true, resizable: true, type: "number" },
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "fourthname", name: "fourthname", editable: true, resizable: true, type: "text" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "secondname", name: "secondname", editable: true, resizable: true, type: "text" },
			   	{ key: "thirdname", name: "thirdname", editable: true, resizable: true, type: "text" },
			   	{ key: "timeOfDeath", name: "time_of_death", editable: true, resizable: true, type: "time" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.annex == "")
					data.annex = null;
				if(data.dateOfDeath != null && data.dateOfDeath instanceof Date)
					data.dateOfDeath = data.dateOfDeath.getFullYear() + "-" + ('0' + (data.dateOfDeath.getMonth() + 1)).slice(-2) + "-" + ('0' + data.dateOfDeath.getDate()).slice(-2);
				if(data.deathLocation == "")
					data.deathLocation = null;
				if(data.firstname == "")
					data.firstname = null;
				if(data.fourthname == "")
					data.fourthname = null;
				if(data.secondname == "")
					data.secondname = null;
				if(data.thirdname == "")
					data.thirdname = null;
				return data
			}
		},
		'vital-record-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "birthCountry", name: "birth_country", editable: true, resizable: true, type: "number" },
			   	{ key: "birthDayUnknown", name: "birth_day_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthMonthUnknown", name: "birth_month_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthPlace", name: "birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "birthTime", name: "birth_time", editable: true, resizable: true, type: "time" },
			   	{ key: "birthTimeUnknown", name: "birth_time_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthYearUnknown", name: "birth_year_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthdate", name: "birthdate", editable: true, resizable: true, type: "date" },
			   	{ key: "cardId", name: "card_id", editable: true, resizable: true, type: "number" },
			   	{ key: "commonIdNumber", name: "common_id_number", editable: true, resizable: true, type: "text" },
			   	{ key: "disability", name: "disability", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donor", name: "donor", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donorNotes", name: "donor_notes", editable: true, resizable: true, type: "text" },
			   	{ key: "faceId", name: "face_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fatherId", name: "father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fifthname", name: "fifthname", editable: true, resizable: true, type: "text" },
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "fourthname", name: "fourthname", editable: true, resizable: true, type: "text" },
			   	{ key: "gender", name: "gender", editable: true, resizable: true, type: "number" },
			   	{ key: "inactive", name: "inactive", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "isDeleted", name: "is_deleted", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "maritalStatus", name: "marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "markedInactive", name: "marked_inactive", editable: true, resizable: true, type: "date" },
			   	{ key: "motherId", name: "mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "otherCitizenshipCsv", name: "other_citizenship_csv", editable: true, resizable: true, type: "text" },
			   	{ key: "primaryCitizenship", name: "primary_citizenship", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedFatherName", name: "reported_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedMotherName", name: "reported_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "secondname", name: "secondname", editable: true, resizable: true, type: "text" },
			   	{ key: "thirdname", name: "thirdname", editable: true, resizable: true, type: "text" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "unknownFather", name: "unknown_father", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "voter", name: "voter", editable: true, resizable: true, type: "checkbox" },
			],
			form2dto: (data) => {
				if(data.birthPlace == "")
					data.birthPlace = null;
				if(data.birthdate != null && data.birthdate instanceof Date)
					data.birthdate = data.birthdate.getFullYear() + "-" + ('0' + (data.birthdate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.birthdate.getDate()).slice(-2);
				if(data.commonIdNumber == "")
					data.commonIdNumber = null;
				if(data.donorNotes == "")
					data.donorNotes = null;
				if(data.fifthname == "")
					data.fifthname = null;
				if(data.firstname == "")
					data.firstname = null;
				if(data.fourthname == "")
					data.fourthname = null;
				if(data.markedInactive != null && data.markedInactive instanceof Date)
					data.markedInactive = data.markedInactive.getFullYear() + "-" + ('0' + (data.markedInactive.getMonth() + 1)).slice(-2) + "-" + ('0' + data.markedInactive.getDate()).slice(-2);
				if(data.notes == "")
					data.notes = null;
				if(data.otherCitizenshipCsv == "")
					data.otherCitizenshipCsv = null;
				if(data.reportedFatherName == "")
					data.reportedFatherName = null;
				if(data.reportedMotherName == "")
					data.reportedMotherName = null;
				if(data.secondname == "")
					data.secondname = null;
				if(data.thirdname == "")
					data.thirdname = null;
				return data
			}
		},
		'administrative-update-mtlb-attachment-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "administrativeUpdateMtlbId", name: "administrative_update_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'phone-number': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "administrativeUpdateMtlbId", name: "administrative_update_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "phoneNumber", name: "phone_number", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.phoneNumber == "")
					data.phoneNumber = null;
				return data
			}
		},
		'registration-areas-metadata': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "localGovernmentId", name: "local_government_id", editable: true, resizable: true, type: "select", metadata: "local-governments-metadata"  },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'vital-record-tag': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'id-mtlb-attachment-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "idMtlbId", name: "id_mtlb_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'civil-status-mtlb-tag-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'removal-mtlb-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "afisId", name: "afis_id", editable: true, resizable: true, type: "number" },
			   	{ key: "faceImageType", name: "face_image_type", editable: true, resizable: true, type: "number" },
			   	{ key: "fingerImageType", name: "finger_image_type", editable: true, resizable: true, type: "number" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "numberOfAttachments", name: "number_of_attachments", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedId", name: "reported_id", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedLegacyId", name: "reported_legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "requestorEmail", name: "requestor_email", editable: true, resizable: true, type: "text" },
			   	{ key: "requestorFace", name: "requestor_face", editable: true, resizable: true, type: "blob" },
			   	{ key: "requestorFingerWhich", name: "requestor_finger_which", editable: true, resizable: true, type: "number" },
			   	{ key: "requestorFingerprint", name: "requestor_fingerprint", editable: true, resizable: true, type: "blob" },
			   	{ key: "requestorPhone", name: "requestor_phone", editable: true, resizable: true, type: "text" },
			   	{ key: "requestorVitalRecordId", name: "requestor_vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.notes == "")
					data.notes = null;
				if(data.reportedId == "")
					data.reportedId = null;
				if(data.reportedLegacyId == "")
					data.reportedLegacyId = null;
				if(data.requestorEmail == "")
					data.requestorEmail = null;
				if(data.requestorPhone == "")
					data.requestorPhone = null;
				return data
			}
		},
		'civil-status-mtlb-tag': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'keys': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "pgp1", name: "pgp1", editable: true, resizable: true, type: "number" },
			   	{ key: "pgp2", name: "pgp2", editable: true, resizable: true, type: "number" },
			   	{ key: "pgp3", name: "pgp3", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				return data
			}
		},
		'polling-stations': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "population", name: "population", editable: true, resizable: true, type: "number" },
			   	{ key: "regAreaId", name: "reg_area_id", editable: true, resizable: true, type: "number" },
			   	{ key: "wardId", name: "ward_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'issued-documents-tag': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "issuedDocumentsId", name: "issued_documents_id", editable: true, resizable: true, type: "number" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'adoptive-mothers': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "childId", name: "child_id", editable: true, resizable: true, type: "number" },
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "courtAdoptionNumber", name: "court_adoption_number", editable: true, resizable: true, type: "text" },
			   	{ key: "current", name: "current", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "motherAdoptionDate", name: "mother_adoption_date", editable: true, resizable: true, type: "date" },
			   	{ key: "motherEndAdoptionDate", name: "mother_end_adoption_date", editable: true, resizable: true, type: "date" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.courtAdoptionNumber == "")
					data.courtAdoptionNumber = null;
				if(data.motherAdoptionDate != null && data.motherAdoptionDate instanceof Date)
					data.motherAdoptionDate = data.motherAdoptionDate.getFullYear() + "-" + ('0' + (data.motherAdoptionDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.motherAdoptionDate.getDate()).slice(-2);
				if(data.motherEndAdoptionDate != null && data.motherEndAdoptionDate instanceof Date)
					data.motherEndAdoptionDate = data.motherEndAdoptionDate.getFullYear() + "-" + ('0' + (data.motherEndAdoptionDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.motherEndAdoptionDate.getDate()).slice(-2);
				return data
			}
		},
		'id-mtlb-attachment-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "idMtlbId", name: "id_mtlb_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'rvfour-birth-mtlb-attachment': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "description", name: "description", editable: true, resizable: true, type: "text" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "fileType", name: "file_type", editable: true, resizable: true, type: "number" },
			   	{ key: "preview", name: "preview", editable: true, resizable: true, type: "blob" },
			   	{ key: "rvfourBirthMtlbId", name: "rvfour_birth_mtlb_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.description == "")
					data.description = null;
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'death-record-note-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "addedBy", name: "added_by", editable: true, resizable: true, type: "text" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.addedBy == "")
					data.addedBy = null;
				if(data.content == "")
					data.content = null;
				return data
			}
		},
		'civil-status-mtlb-attachment-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'fingerprints-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "afisId", name: "afis_id", editable: true, resizable: true, type: "number" },
			   	{ key: "leftIndexFinger", name: "left_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftMiddleFinger", name: "left_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftPinky", name: "left_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftRingFinger", name: "left_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftThumb", name: "left_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "physicalId", name: "physical_id", editable: true, resizable: true, type: "number" },
			   	{ key: "rightIndexFinger", name: "right_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightMiddleFinger", name: "right_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightPinky", name: "right_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightRingFinger", name: "right_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightThumb", name: "right_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				return data
			}
		},
		'ward-municipal': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "pollingStationId", name: "polling_station_id", editable: true, resizable: true, type: "number" },
			   	{ key: "wardId", name: "ward_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				return data
			}
		},
		'rvfour-birth-mtlb': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "address", name: "address", editable: true, resizable: true, type: "text" },
			   	{ key: "annex", name: "annex", editable: true, resizable: true, type: "text" },
			   	{ key: "birthDayUnknown", name: "birth_day_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthMonthUnknown", name: "birth_month_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthPlace", name: "birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "birthYearUnknown", name: "birth_year_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthdate", name: "birthdate", editable: true, resizable: true, type: "date" },
			   	{ key: "birthtime", name: "birthtime", editable: true, resizable: true, type: "time" },
			   	{ key: "disability", name: "disability", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "email", name: "email", editable: true, resizable: true, type: "text" },
			   	{ key: "face", name: "face", editable: true, resizable: true, type: "blob" },
			   	{ key: "faceMimeType", name: "face_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "fatherId", name: "father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fifthname", name: "fifthname", editable: true, resizable: true, type: "text" },
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "fourthname", name: "fourthname", editable: true, resizable: true, type: "text" },
			   	{ key: "gender", name: "gender", editable: true, resizable: true, type: "number" },
			   	{ key: "maritalStatus", name: "marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "motherId", name: "mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "multiBirth", name: "multi_birth", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "numberOfAttachments", name: "number_of_attachments", editable: true, resizable: true, type: "number" },
			   	{ key: "occupation", name: "occupation", editable: true, resizable: true, type: "text" },
			   	{ key: "oldAddress", name: "old_address", editable: true, resizable: true, type: "text" },
			   	{ key: "phoneNumber", name: "phone_number", editable: true, resizable: true, type: "text" },
			   	{ key: "religion", name: "religion", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedFatherName", name: "reported_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedMotherName", name: "reported_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "secondname", name: "secondname", editable: true, resizable: true, type: "text" },
			   	{ key: "sixthname", name: "sixthname", editable: true, resizable: true, type: "text" },
			   	{ key: "thirdname", name: "thirdname", editable: true, resizable: true, type: "text" },
			   	{ key: "timestamp", name: "timestamp", editable: true, resizable: true, type: "timestampz" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.address == "")
					data.address = null;
				if(data.annex == "")
					data.annex = null;
				if(data.birthPlace == "")
					data.birthPlace = null;
				if(data.birthdate != null && data.birthdate instanceof Date)
					data.birthdate = data.birthdate.getFullYear() + "-" + ('0' + (data.birthdate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.birthdate.getDate()).slice(-2);
				if(data.email == "")
					data.email = null;
				if(data.faceMimeType == "")
					data.faceMimeType = null;
				if(data.fifthname == "")
					data.fifthname = null;
				if(data.firstname == "")
					data.firstname = null;
				if(data.fourthname == "")
					data.fourthname = null;
				if(data.notes == "")
					data.notes = null;
				if(data.occupation == "")
					data.occupation = null;
				if(data.oldAddress == "")
					data.oldAddress = null;
				if(data.phoneNumber == "")
					data.phoneNumber = null;
				if(data.religion == "")
					data.religion = null;
				if(data.reportedFatherName == "")
					data.reportedFatherName = null;
				if(data.reportedMotherName == "")
					data.reportedMotherName = null;
				if(data.secondname == "")
					data.secondname = null;
				if(data.sixthname == "")
					data.sixthname = null;
				if(data.thirdname == "")
					data.thirdname = null;
				if(data.timestamp != null && data.timestamp instanceof Date)
					data.timestamp = data.timestamp.getFullYear() + "-" + ('0' + (data.timestamp.getMonth() + 1)).slice(-2) + "-" + ('0' + data.timestamp.getDate()).slice(-2);
				return data
			}
		},
		'id-mtlb-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "afisId", name: "afis_id", editable: true, resizable: true, type: "number" },
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deduplicationMtlbId", name: "deduplication_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "face", name: "face", editable: true, resizable: true, type: "blob" },
			   	{ key: "faceMimeType", name: "face_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "fingersMimeType", name: "fingers_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "isVoter", name: "is_voter", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "leftIndexFinger", name: "left_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftMiddleFinger", name: "left_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftPinky", name: "left_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftRingFinger", name: "left_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftThumb", name: "left_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "mtlbLocation", name: "mtlb_location", editable: true, resizable: true, type: "text" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatusData", name: "mtlb_status_data", editable: true, resizable: true, type: "json" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "numberOfAttachments", name: "number_of_attachments", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedId", name: "reported_id", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedLegacyId", name: "reported_legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "requestedIdType", name: "requested_id_type", editable: true, resizable: true, type: "number" },
			   	{ key: "residenceDepartment", name: "residence_department", editable: true, resizable: true, type: "text" },
			   	{ key: "residenceMunicipality", name: "residence_municipality", editable: true, resizable: true, type: "text" },
			   	{ key: "rightIndexFinger", name: "right_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightMiddleFinger", name: "right_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightPinky", name: "right_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightRingFinger", name: "right_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightThumb", name: "right_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "signature", name: "signature", editable: true, resizable: true, type: "blob" },
			   	{ key: "signatureMimeType", name: "signature_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "votingCenter", name: "voting_center", editable: true, resizable: true, type: "text" },
			   	{ key: "votingDepartment", name: "voting_department", editable: true, resizable: true, type: "text" },
			   	{ key: "votingMunicipality", name: "voting_municipality", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.faceMimeType == "")
					data.faceMimeType = null;
				if(data.fingersMimeType == "")
					data.fingersMimeType = null;
				if(data.mtlbLocation == "")
					data.mtlbLocation = null;
				if(data.notes == "")
					data.notes = null;
				if(data.reportedLegacyId == "")
					data.reportedLegacyId = null;
				if(data.residenceDepartment == "")
					data.residenceDepartment = null;
				if(data.residenceMunicipality == "")
					data.residenceMunicipality = null;
				if(data.signatureMimeType == "")
					data.signatureMimeType = null;
				if(data.votingCenter == "")
					data.votingCenter = null;
				if(data.votingDepartment == "")
					data.votingDepartment = null;
				if(data.votingMunicipality == "")
					data.votingMunicipality = null;
				return data
			}
		},
		'union-record-mention': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "addedBy", name: "added_by", editable: true, resizable: true, type: "text" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "timestamp", name: "timestamp", editable: true, resizable: true, type: "timestampz" },
			   	{ key: "unionRecordId", name: "union_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.addedBy == "")
					data.addedBy = null;
				if(data.content == "")
					data.content = null;
				if(data.timestamp != null && data.timestamp instanceof Date)
					data.timestamp = data.timestamp.getFullYear() + "-" + ('0' + (data.timestamp.getMonth() + 1)).slice(-2) + "-" + ('0' + data.timestamp.getDate()).slice(-2);
				return data
			}
		},
		'local-governments-metadata': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "stateId", name: "state_id", editable: true, resizable: true, type: "select", metadata: "states-metadata"  },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'removal-mtlb': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "afisId", name: "afis_id", editable: true, resizable: true, type: "number" },
			   	{ key: "faceImageType", name: "face_image_type", editable: true, resizable: true, type: "number" },
			   	{ key: "fingerImageType", name: "finger_image_type", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "numberOfAttachments", name: "number_of_attachments", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedId", name: "reported_id", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedLegacyId", name: "reported_legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "requestorEmail", name: "requestor_email", editable: true, resizable: true, type: "text" },
			   	{ key: "requestorFace", name: "requestor_face", editable: true, resizable: true, type: "blob" },
			   	{ key: "requestorFingerWhich", name: "requestor_finger_which", editable: true, resizable: true, type: "number" },
			   	{ key: "requestorFingerprint", name: "requestor_fingerprint", editable: true, resizable: true, type: "blob" },
			   	{ key: "requestorPhone", name: "requestor_phone", editable: true, resizable: true, type: "text" },
			   	{ key: "requestorVitalRecordId", name: "requestor_vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.notes == "")
					data.notes = null;
				if(data.reportedId == "")
					data.reportedId = null;
				if(data.reportedLegacyId == "")
					data.reportedLegacyId = null;
				if(data.requestorEmail == "")
					data.requestorEmail = null;
				if(data.requestorPhone == "")
					data.requestorPhone = null;
				return data
			}
		},
		'death-record': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "address", name: "address", editable: true, resizable: true, type: "number" },
			   	{ key: "birthCountry", name: "birth_country", editable: true, resizable: true, type: "number" },
			   	{ key: "birthDayUnknown", name: "birth_day_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthMonthUnknown", name: "birth_month_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthPlace", name: "birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "birthTime", name: "birth_time", editable: true, resizable: true, type: "time" },
			   	{ key: "birthTimeUnknown", name: "birth_time_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthYearUnknown", name: "birth_year_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthdate", name: "birthdate", editable: true, resizable: true, type: "date" },
			   	{ key: "burialPlace", name: "burial_place", editable: true, resizable: true, type: "text" },
			   	{ key: "cardId", name: "card_id", editable: true, resizable: true, type: "number" },
			   	{ key: "commonIdNumber", name: "common_id_number", editable: true, resizable: true, type: "text" },
			   	{ key: "dateOfDeath", name: "date_of_death", editable: true, resizable: true, type: "date" },
			   	{ key: "deathDayUnknown", name: "death_day_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathLocation", name: "death_location", editable: true, resizable: true, type: "text" },
			   	{ key: "deathMonthUnknown", name: "death_month_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathNotificationFile", name: "death_notification_file", editable: true, resizable: true, type: "text" },
			   	{ key: "deathPlace", name: "death_place", editable: true, resizable: true, type: "text" },
			   	{ key: "deathTimeUnknown", name: "death_time_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathYearUnknown", name: "death_year_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "disability", name: "disability", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donor", name: "donor", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donorNotes", name: "donor_notes", editable: true, resizable: true, type: "text" },
			   	{ key: "faceId", name: "face_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fatherId", name: "father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fifthname", name: "fifthname", editable: true, resizable: true, type: "text" },
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "fourthname", name: "fourthname", editable: true, resizable: true, type: "text" },
			   	{ key: "gender", name: "gender", editable: true, resizable: true, type: "number" },
			   	{ key: "isDeleted", name: "is_deleted", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "maritalStatus", name: "marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "motherId", name: "mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "otherCitizenshipCsv", name: "other_citizenship_csv", editable: true, resizable: true, type: "text" },
			   	{ key: "primaryCitizenship", name: "primary_citizenship", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedFatherName", name: "reported_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedMotherName", name: "reported_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "secondname", name: "secondname", editable: true, resizable: true, type: "text" },
			   	{ key: "thirdname", name: "thirdname", editable: true, resizable: true, type: "text" },
			   	{ key: "timeOfDeath", name: "time_of_death", editable: true, resizable: true, type: "time" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "voter", name: "voter", editable: true, resizable: true, type: "checkbox" },
			],
			form2dto: (data) => {
				if(data.birthPlace == "")
					data.birthPlace = null;
				if(data.birthdate != null && data.birthdate instanceof Date)
					data.birthdate = data.birthdate.getFullYear() + "-" + ('0' + (data.birthdate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.birthdate.getDate()).slice(-2);
				if(data.burialPlace == "")
					data.burialPlace = null;
				if(data.commonIdNumber == "")
					data.commonIdNumber = null;
				if(data.dateOfDeath != null && data.dateOfDeath instanceof Date)
					data.dateOfDeath = data.dateOfDeath.getFullYear() + "-" + ('0' + (data.dateOfDeath.getMonth() + 1)).slice(-2) + "-" + ('0' + data.dateOfDeath.getDate()).slice(-2);
				if(data.deathLocation == "")
					data.deathLocation = null;
				if(data.deathPlace == "")
					data.deathPlace = null;
				if(data.donorNotes == "")
					data.donorNotes = null;
				if(data.fifthname == "")
					data.fifthname = null;
				if(data.firstname == "")
					data.firstname = null;
				if(data.fourthname == "")
					data.fourthname = null;
				if(data.notes == "")
					data.notes = null;
				if(data.otherCitizenshipCsv == "")
					data.otherCitizenshipCsv = null;
				if(data.primaryCitizenship == "")
					data.primaryCitizenship = null;
				if(data.reportedFatherName == "")
					data.reportedFatherName = null;
				if(data.reportedMotherName == "")
					data.reportedMotherName = null;
				if(data.secondname == "")
					data.secondname = null;
				if(data.thirdname == "")
					data.thirdname = null;
				return data
			}
		},
		'legacy-id': {
			columns: [
			   	{ key: "newId", name: "new_id", editable: false, resizable: true},
			   	{ key: "legacyId", name: "legacy_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.legacyId == "")
					data.legacyId = null;
				return data
			}
		},
		'country-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "flag", name: "flag", editable: true, resizable: true, type: "blob" },
			   	{ key: "flagMimeType", name: "flag_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.flagMimeType == "")
					data.flagMimeType = null;
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'physical': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "message", name: "message", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.message == "")
					data.message = null;
				return data
			}
		},
		'death-record-attachment-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "description", name: "description", editable: true, resizable: true, type: "text" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "preview", name: "preview", editable: true, resizable: true, type: "blob" },
			],
			form2dto: (data) => {
				if(data.description == "")
					data.description = null;
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'death-record-note': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "addedBy", name: "added_by", editable: true, resizable: true, type: "text" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "timestamp", name: "timestamp", editable: true, resizable: true, type: "timestampz" },
			],
			form2dto: (data) => {
				if(data.addedBy == "")
					data.addedBy = null;
				if(data.content == "")
					data.content = null;
				if(data.timestamp != null && data.timestamp instanceof Date)
					data.timestamp = data.timestamp.getFullYear() + "-" + ('0' + (data.timestamp.getMonth() + 1)).slice(-2) + "-" + ('0' + data.timestamp.getDate()).slice(-2);
				return data
			}
		},
		'occupation-metadata-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'administrative-update-mtlb': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "address", name: "address", editable: true, resizable: true, type: "text" },
			   	{ key: "email", name: "email", editable: true, resizable: true, type: "text" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "numberOfAttachments", name: "number_of_attachments", editable: true, resizable: true, type: "number" },
			   	{ key: "phoneNumber", name: "phone_number", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.address == "")
					data.address = null;
				if(data.email == "")
					data.email = null;
				if(data.phoneNumber == "")
					data.phoneNumber = null;
				return data
			}
		},
		'signature-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "idMtlbId", name: "id_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "image", name: "image", editable: true, resizable: true, type: "blob" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				return data
			}
		},
		'global-state': {
			columns: [
			   	{ key: "key", name: "key", editable: false, resizable: true},
			   	{ key: "value", name: "value", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.value == "")
					data.value = null;
				return data
			}
		},
		'union-mtlb-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "address", name: "address", editable: true, resizable: true, type: "text" },
			   	{ key: "declaredDate", name: "declared_date", editable: true, resizable: true, type: "date" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatusData", name: "mtlb_status_data", editable: true, resizable: true, type: "json" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1BirthPlace", name: "partner1_birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1FatherId", name: "partner1_father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner1FatherName", name: "partner1_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1Firstname", name: "partner1_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1IndividualMelanesianName", name: "partner1_individual_melanesian_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1MaritalStatus", name: "partner1_marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "partner1MotherId", name: "partner1_mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner1MotherName", name: "partner1_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1Occupation", name: "partner1_occupation", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1ResidenceAddress", name: "partner1_residence_address", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1Surname", name: "partner1_surname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1VitalRecordId", name: "partner1_vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner2BirthPlace", name: "partner2_birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2FatherId", name: "partner2_father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner2FatherName", name: "partner2_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2Firstname", name: "partner2_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2IndividualMelanesianName", name: "partner2_individual_melanesian_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2MaritalStatus", name: "partner2_marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "partner2MotherId", name: "partner2_mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner2MotherName", name: "partner2_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2Occupation", name: "partner2_occupation", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2ResidenceAddress", name: "partner2_residence_address", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2Surname", name: "partner2_surname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2VitalRecordId", name: "partner2_vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "place", name: "place", editable: true, resizable: true, type: "text" },
			   	{ key: "rites", name: "rites", editable: true, resizable: true, type: "text" },
			   	{ key: "unionRegime", name: "union_regime", editable: true, resizable: true, type: "number" },
			   	{ key: "witness1BirthPlace", name: "witness1_birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1Firstname", name: "witness1_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1Occupation", name: "witness1_occupation", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1ResidenceAddress", name: "witness1_residence_address", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1Surname", name: "witness1_surname", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1VitalRecordId", name: "witness1_vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "witness2BirthPlace", name: "witness2_birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2Firstname", name: "witness2_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2Occupation", name: "witness2_occupation", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2ResidenceAddress", name: "witness2_residence_address", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2Surname", name: "witness2_surname", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2VitalRecordId", name: "witness2_vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.address == "")
					data.address = null;
				if(data.declaredDate != null && data.declaredDate instanceof Date)
					data.declaredDate = data.declaredDate.getFullYear() + "-" + ('0' + (data.declaredDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.declaredDate.getDate()).slice(-2);
				if(data.notes == "")
					data.notes = null;
				if(data.partner1BirthPlace == "")
					data.partner1BirthPlace = null;
				if(data.partner1FatherName == "")
					data.partner1FatherName = null;
				if(data.partner1Firstname == "")
					data.partner1Firstname = null;
				if(data.partner1IndividualMelanesianName == "")
					data.partner1IndividualMelanesianName = null;
				if(data.partner1MotherName == "")
					data.partner1MotherName = null;
				if(data.partner1Occupation == "")
					data.partner1Occupation = null;
				if(data.partner1ResidenceAddress == "")
					data.partner1ResidenceAddress = null;
				if(data.partner1Surname == "")
					data.partner1Surname = null;
				if(data.partner2BirthPlace == "")
					data.partner2BirthPlace = null;
				if(data.partner2FatherName == "")
					data.partner2FatherName = null;
				if(data.partner2Firstname == "")
					data.partner2Firstname = null;
				if(data.partner2IndividualMelanesianName == "")
					data.partner2IndividualMelanesianName = null;
				if(data.partner2MotherName == "")
					data.partner2MotherName = null;
				if(data.partner2Occupation == "")
					data.partner2Occupation = null;
				if(data.partner2ResidenceAddress == "")
					data.partner2ResidenceAddress = null;
				if(data.partner2Surname == "")
					data.partner2Surname = null;
				if(data.place == "")
					data.place = null;
				if(data.rites == "")
					data.rites = null;
				if(data.witness1BirthPlace == "")
					data.witness1BirthPlace = null;
				if(data.witness1Firstname == "")
					data.witness1Firstname = null;
				if(data.witness1Occupation == "")
					data.witness1Occupation = null;
				if(data.witness1ResidenceAddress == "")
					data.witness1ResidenceAddress = null;
				if(data.witness1Surname == "")
					data.witness1Surname = null;
				if(data.witness2BirthPlace == "")
					data.witness2BirthPlace = null;
				if(data.witness2Firstname == "")
					data.witness2Firstname = null;
				if(data.witness2Occupation == "")
					data.witness2Occupation = null;
				if(data.witness2ResidenceAddress == "")
					data.witness2ResidenceAddress = null;
				if(data.witness2Surname == "")
					data.witness2Surname = null;
				return data
			}
		},
		'administrative-information-attachment-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "administrativeInformationId", name: "administrative_information_id", editable: true, resizable: true, type: "number" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'administrative-update-mtlb-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "city", name: "city", editable: true, resizable: true, type: "text" },
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "email", name: "email", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "isDonor", name: "is_donor", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "numberOfAttachments", name: "number_of_attachments", editable: true, resizable: true, type: "number" },
			   	{ key: "occupationId", name: "occupation_id", editable: true, resizable: true, type: "number" },
			   	{ key: "otherPhone", name: "other_phone", editable: true, resizable: true, type: "text" },
			   	{ key: "phoneNumber", name: "phone_number", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedId", name: "reported_id", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedLegacyId", name: "reported_legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "residenceCountryId", name: "residence_country_id", editable: true, resizable: true, type: "number" },
			   	{ key: "residenceLocation", name: "residence_location", editable: true, resizable: true, type: "text" },
			   	{ key: "streetAddress", name: "street_address", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "zipCode", name: "zip_code", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.city == "")
					data.city = null;
				if(data.email == "")
					data.email = null;
				if(data.otherPhone == "")
					data.otherPhone = null;
				if(data.phoneNumber == "")
					data.phoneNumber = null;
				if(data.reportedLegacyId == "")
					data.reportedLegacyId = null;
				if(data.residenceLocation == "")
					data.residenceLocation = null;
				if(data.streetAddress == "")
					data.streetAddress = null;
				if(data.zipCode == "")
					data.zipCode = null;
				return data
			}
		},
		'administrative-update-mtlb-attachment': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "administrativeUpdateMtlbId", name: "administrative_update_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "description", name: "description", editable: true, resizable: true, type: "text" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "preview", name: "preview", editable: true, resizable: true, type: "blob" },
			],
			form2dto: (data) => {
				if(data.description == "")
					data.description = null;
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'witness': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "surname", name: "surname", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.firstname == "")
					data.firstname = null;
				if(data.surname == "")
					data.surname = null;
				return data
			}
		},
		'user-count': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "count", name: "count", editable: true, resizable: true, type: "number" },
			   	{ key: "userId", name: "user_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.userId == "")
					data.userId = null;
				return data
			}
		},
		'constituency': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "provinceId", name: "province_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'administrative-information-attachment': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "administrativeInformationId", name: "administrative_information_id", editable: true, resizable: true, type: "number" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "description", name: "description", editable: true, resizable: true, type: "text" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "preview", name: "preview", editable: true, resizable: true, type: "blob" },
			],
			form2dto: (data) => {
				if(data.description == "")
					data.description = null;
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'issued-documents': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "cancelledDate", name: "cancelled_date", editable: true, resizable: true, type: "date" },
			   	{ key: "cancelledReason", name: "cancelled_reason", editable: true, resizable: true, type: "text" },
			   	{ key: "documentId", name: "document_id", editable: true, resizable: true, type: "text" },
			   	{ key: "documentType", name: "document_type", editable: true, resizable: true, type: "number" },
			   	{ key: "expires", name: "expires", editable: true, resizable: true, type: "date" },
			   	{ key: "face", name: "face", editable: true, resizable: true, type: "blob" },
			   	{ key: "fullName", name: "full_name", editable: true, resizable: true, type: "text" },
			   	{ key: "isVoter", name: "is_voter", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "issuedDate", name: "issued_date", editable: true, resizable: true, type: "date" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "residenceDepartment", name: "residence_department", editable: true, resizable: true, type: "text" },
			   	{ key: "residenceMunicipality", name: "residence_municipality", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "votingCenter", name: "voting_center", editable: true, resizable: true, type: "text" },
			   	{ key: "votingDepartment", name: "voting_department", editable: true, resizable: true, type: "text" },
			   	{ key: "votingMunicipality", name: "voting_municipality", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.cancelledDate != null && data.cancelledDate instanceof Date)
					data.cancelledDate = data.cancelledDate.getFullYear() + "-" + ('0' + (data.cancelledDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.cancelledDate.getDate()).slice(-2);
				if(data.cancelledReason == "")
					data.cancelledReason = null;
				if(data.documentId == "")
					data.documentId = null;
				if(data.expires != null && data.expires instanceof Date)
					data.expires = data.expires.getFullYear() + "-" + ('0' + (data.expires.getMonth() + 1)).slice(-2) + "-" + ('0' + data.expires.getDate()).slice(-2);
				if(data.fullName == "")
					data.fullName = null;
				if(data.issuedDate != null && data.issuedDate instanceof Date)
					data.issuedDate = data.issuedDate.getFullYear() + "-" + ('0' + (data.issuedDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.issuedDate.getDate()).slice(-2);
				if(data.notes == "")
					data.notes = null;
				if(data.residenceDepartment == "")
					data.residenceDepartment = null;
				if(data.residenceMunicipality == "")
					data.residenceMunicipality = null;
				if(data.votingCenter == "")
					data.votingCenter = null;
				if(data.votingDepartment == "")
					data.votingDepartment = null;
				if(data.votingMunicipality == "")
					data.votingMunicipality = null;
				return data
			}
		},
		'rvfour-death-mtlb-tag': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "rvfourDeathMtlbId", name: "rvfour_death_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'death-record-tag-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'locations': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "areaId", name: "area_id", editable: true, resizable: true, type: "text" },
			   	{ key: "flag", name: "flag", editable: true, resizable: true, type: "blob" },
			   	{ key: "flagMimeType", name: "flag_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "parentId", name: "parent_id", editable: true, resizable: true, type: "number" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.areaId == "")
					data.areaId = null;
				if(data.flagMimeType == "")
					data.flagMimeType = null;
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'locations-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "areaId", name: "area_id", editable: true, resizable: true, type: "text" },
			   	{ key: "flag", name: "flag", editable: true, resizable: true, type: "blob" },
			   	{ key: "flagMimeType", name: "flag_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "parentId", name: "parent_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.areaId == "")
					data.areaId = null;
				if(data.flagMimeType == "")
					data.flagMimeType = null;
				if(data.name == "")
					data.name = null;
				return data
			}
		},
}

export const buildEmptyObject = (entity) => {
	var columns = new Object();
	let fields = pojoMetadata[entity].columns
	for (let col in fields) {		
		if(fields[col].type == 'text'){
			columns[fields[col].key] = "";
		}else if(fields[col].type=='checkbox'){
			columns[fields[col].key] = false;
		}else if(fields[col].type=='datetime-local'){
			columns[fields[col].key] = "";
		}
		else if(fields[col].type=='autocomplete'){
			columns[fields[col].key] = null;
		}
	 }
	return columns
};

export const getEntityIdCol = (entity) => {
	return pojoMetadata[entity].columns[0].key;
}

export const getRowId = (entity, row) => {
	return row[getEntityIdCol(entity)];
}

