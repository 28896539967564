import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Provider, connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay-ts';

import {GUI_LANGUAGE_CHANGE_EVENT, PRINT_EVENT} from './events/Gui';

import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faCalendarAlt, faPersonBooth, faAddressBook, faHome, faChartLine, faTrash,
	faSignOutAlt, faEdit, faSave, faPlus, faTimesCircle, faSearch, faTrashRestore,
	faTools, faUsers, faLanguage, faHistory, faFile, faFileExcel, faFileWord, faFileCsv,
	faFileImage, faFilePdf, faFilePowerpoint, faFileVideo, faFileAudio, faPrint, faListAlt,
	faFlag, faBabyCarriage, faIdCard, faMapMarkedAlt, faRainbow, faUserCheck, faEuroSign, faCar,
	faPaste, faBook, faUsersSlash, faRing, faBaby, faUserCog, faHeartBroken, faQuestionCircle
} from '@fortawesome/free-solid-svg-icons';

import { i18ndb, rest } from './services';
import { store } from './store';
import { MainPage } from '../../../src/auto/js/pages/MainPage';
import { AlertDialog } from './widgets';
import { loadUserInfo } from './users/UserInfo';
import { commandHooks, subcommandOptions } from '../../main/js/CliOptions';
import { parseHashArguments } from './utils';

import { RibbonBrowser } from '../../auto/js/browsers/RibbonBrowser';
import { Welcome } from '../../main/js/pages/Welcome';

import {
	loadCountries,
	loadGeoData,
} from '../../main/js/GetReady';

import PrintService from './services/PrintService';


import 'bootstrap/dist/css/bootstrap.css';


library.add(faCalendarAlt, faPersonBooth, faAddressBook, faHome, faChartLine, faTrash,
	faSignOutAlt, faEdit, faSave, faPlus, faTimesCircle, faSearch, faTrashRestore,
	faTools, faUsers, faLanguage, faHistory, faFile, faFileExcel, faFileWord, faFileCsv,
	faFileImage, faFilePdf, faFilePowerpoint, faFileVideo, faFileAudio, faPrint, faListAlt, faFlag,
	faBabyCarriage, faIdCard, faMapMarkedAlt, faRainbow, faUserCheck, faEuroSign, faCar, faPaste, faBook,
	faUsersSlash, faRing, faBaby, faUserCog, faHeartBroken, faQuestionCircle);



var supportedLanguages = [{"code":"en","label":"English"},{"code":"fr","label":"Francais"},{"code":"na","label":"Naoreo"}];

//This should be automatically setup from user preferences or application wide defaults
const theme = createTheme({
  direction: 'ltr',
});

const excludedArguments = ['state', 'session_state', 'code'];
const url = window.location.hash;
const { subcommand, argsMap } = parseHashArguments(url, subcommandOptions, excludedArguments);

class App extends Component {

	constructor(props) {
		super(props);
		this.state = {
			authenticated: false,
			ready: {
				userInfo: false
			,
				country: false,
				geoData: false,
			},
			catalog: false,
			language: undefined,
			loadingMessage: "Loading metadata"
		}
		GUI_LANGUAGE_CHANGE_EVENT.subscribe(l => this.setState({language: l}));
	}
	
	componentDidMount() {
		rest.oidcService.init({
			"public-client": true,
			pkceMethod: 'S256',
			onLoad: 'login-required'
		}).then((authenticated) =>
			setInterval(() => {
				rest.oidcService.updateToken(15).success((refreshed) => {
					if (refreshed) {
						console.debug('Token refreshed ' + refreshed);
					} else {
						console.warn('Token not refreshed, valid for '
							+ Math.round(rest.oidcService.tokenParsed.exp +
								rest.oidcService.timeSkew - new Date().getTime() / 1000) + ' seconds');
					}
				}).error(() => {
					console.error('Failed to refresh token');
				})
		}, 50000)).then(() => {
		   	if(!this.state.catalog) {
						rest.readPreferences().then( response => {
							let lang = response.find(l => l.key == "language")?.value;
							i18ndb.setLocale((lang!=null)?lang:'en');
						i18ndb.loadCatalog().then(() => {
							GUI_LANGUAGE_CHANGE_EVENT.publish((lang!=null)?lang:'en');
							this.setState({catalog: i18ndb.getCatalog()});
						});
						})
			}

			loadUserInfo().then(() => this.setReady("userInfo"));
			loadCountries().then(() => this.setReady("country"));
			loadGeoData().then(() => this.setReady("geoData"));
		});
  	}
  	
	setReady = (stateName) => {
				   var readyState = this.state.ready;
		   readyState[stateName] = true;
		   this.setState({
		       ready: readyState, 
		       loadingMessage: stateName + ' is ready'
		   });
	}

	isReady = () => {
		for (var b of Object.keys(this.state.ready)) {
    		if (!this.state.ready[b])
    			return false;
    	}
    	if (!this.state.language)
    		return false;
    	return true;
	}
	
	render() {
		let ready = this.isReady();
		if (this.props.failedConnection)
			return <AlertDialog
					confirmMessage={t`Communication failed with the server, please check your internet connection and refresh the page`}
					open={true}
					noCancel={true}
					noAgree={true}
					/>
		return (
			<LoadingOverlay
				active={!ready}
				spinner
				text={this.state.loadingMessage}
			>
				<PrintService trigger={PRINT_EVENT}/>
				{ ready && (<RibbonBrowser supportedLanguages={supportedLanguages}
				 	welcome={(subcommand) ? () => commandHooks[subcommand](argsMap):(uuid)=><Welcome key={uuid}/>}/>) }	
			</LoadingOverlay>
		);

	}
}

const mapStateToProps = state => {
	return {
		failedConnection: !!state.failedConnection
	};
}

const AppContainer = connect(mapStateToProps)(App);

ReactDOM.render(
	<ThemeProvider theme={theme}>
		<Provider store={store}>
			<AppContainer />
		</Provider>
	</ThemeProvider>,
	document.getElementById('container')
);