export const MtlbType = {
	"ID_CARD": 1
	,
	"CIVIL_STATUS_INCLUSION": 2
	,
	"DEATH": 3
	,
	"BIRTH_REGISTRATION": 4
	,
	"USURPATION_CLAIM": 5
	,
	"CIVIL_STATUS_CHANGE": 6
	,
	"ADMIN_INFO_UPDATE": 7
	,
	"DIGITALIZATION": 8
	,
	"MARRIAGE": 9
	,
	"DIVORCE": 10
	,
	"ADOPTION": 11
	,
	"DEATH_REGISTRATION": 12
	,
	"RESIDENCE_CARD": 13
	,
	"RV4_BIRTH_VITAL_RECORD": 14
	,
	"RV4_BIRTH_FAMILY_TREE_RECORD": 15
	,
	"RV4_BIRTH_APPLICATION": 16
	,
	"RV4_VITAL_RECORD_TRASH": 17
	,
	"RV4_DEATH_RECORD": 18
	,
	"RV4_DEATH_APPLICATION": 19
	,
	"RV4_DEATH_RECORD_TRASH": 20
	,
	"RV4_MARRIAGE_APPLICATION": 21
	,
	"RV4_UNION_RECORD_TRASH": 22
	,
	"RV4_UNION_RECORD": 23
	,
	"RV4_DIVORCE_APPLICATION": 24
	,
	"RV4_VITAL_RECORD_HISTORY": 25
	,
	"E_ID": 26
	,
	"GENERAL_STATISTICS": 27
	
}
Object.freeze(MtlbType);
const toTypeMap = {};
Object.keys(MtlbType).forEach(key => {
	toTypeMap[MtlbType[key]] = key;
});
export function getMtlbType (value) {
	return toTypeMap[value];
}

export function getAllMtlbType () {
    return Object.keys(MtlbType);
}

export const MTLB_TYPE_ID_CARD = "ID_CARD";
export const MTLB_TYPE_CIVIL_STATUS_INCLUSION = "CIVIL_STATUS_INCLUSION";
export const MTLB_TYPE_DEATH = "DEATH";
export const MTLB_TYPE_BIRTH_REGISTRATION = "BIRTH_REGISTRATION";
export const MTLB_TYPE_USURPATION_CLAIM = "USURPATION_CLAIM";
export const MTLB_TYPE_CIVIL_STATUS_CHANGE = "CIVIL_STATUS_CHANGE";
export const MTLB_TYPE_ADMIN_INFO_UPDATE = "ADMIN_INFO_UPDATE";
export const MTLB_TYPE_DIGITALIZATION = "DIGITALIZATION";
export const MTLB_TYPE_MARRIAGE = "MARRIAGE";
export const MTLB_TYPE_DIVORCE = "DIVORCE";
export const MTLB_TYPE_ADOPTION = "ADOPTION";
export const MTLB_TYPE_DEATH_REGISTRATION = "DEATH_REGISTRATION";
export const MTLB_TYPE_RESIDENCE_CARD = "RESIDENCE_CARD";
export const MTLB_TYPE_RV4_BIRTH_VITAL_RECORD = "RV4_BIRTH_VITAL_RECORD";
export const MTLB_TYPE_RV4_BIRTH_FAMILY_TREE_RECORD = "RV4_BIRTH_FAMILY_TREE_RECORD";
export const MTLB_TYPE_RV4_BIRTH_APPLICATION = "RV4_BIRTH_APPLICATION";
export const MTLB_TYPE_RV4_VITAL_RECORD_TRASH = "RV4_VITAL_RECORD_TRASH";
export const MTLB_TYPE_RV4_DEATH_RECORD = "RV4_DEATH_RECORD";
export const MTLB_TYPE_RV4_DEATH_APPLICATION = "RV4_DEATH_APPLICATION";
export const MTLB_TYPE_RV4_DEATH_RECORD_TRASH = "RV4_DEATH_RECORD_TRASH";
export const MTLB_TYPE_RV4_MARRIAGE_APPLICATION = "RV4_MARRIAGE_APPLICATION";
export const MTLB_TYPE_RV4_UNION_RECORD_TRASH = "RV4_UNION_RECORD_TRASH";
export const MTLB_TYPE_RV4_UNION_RECORD = "RV4_UNION_RECORD";
export const MTLB_TYPE_RV4_DIVORCE_APPLICATION = "RV4_DIVORCE_APPLICATION";
export const MTLB_TYPE_RV4_VITAL_RECORD_HISTORY = "RV4_VITAL_RECORD_HISTORY";
export const MTLB_TYPE_E_ID = "E_ID";
export const MTLB_TYPE_GENERAL_STATISTICS = "GENERAL_STATISTICS";
