export const divorceCertificateStyle = `
@charset "UTF-8";
@import url('https://fonts.cdnfonts.com/css/montserrat');
:root {
  --bleed: 0.5cm;
  --margin: 1cm;
}

@page {
  size: A4;
  margin: 0;
}

* {
  box-sizing: border-box;
}
* {
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important;                 /* Firefox 48 – 96 */
    print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
}

.header {
    background-color: #ffffff;
}
.logos {
}

body {
  --bleed: 0mm;
  --margin: 10mm;
  margin: 0 auto;
  padding: 0;
  background: rgb(204, 204, 204);
  display: flex;
  flex-direction: column;
}

.page {
  display: inline-block;
  position: relative;
  width: 210mm;
  height: 297mm;
  font-size: 15pt;
  margin: 10mm auto;
  padding: calc(var(--bleed) + var(--margin));
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  background: white;
  font-family: montserrat;
}
h1 {
    font-size: 15pt;
    font-weight: bold !important;
    text-align: left;
    margin: 0mm;
	position: relative;
    bottom: 1mm;
}
.headercol5 h1 {
    bottom: 4mm;
	text-align: center;
}
h2 {
	font-size:8pt;
	font-weight:bold !important;
}
h2.marriageinfo {
	font-size:10pt;
	font-weight:normal !important;
	text-align: center;
	margin:5mm 0mm 5mm 0mm;
}
.headercol5 h2 {
	text-align: center;
	margin-top: 0mm;
	position: relative;
	top:-4mm;
}
.headercol5 h1 {
	text-align: center;
	padding-bottom: 0mm !important;
}
h3 {
	font-size:0.95em;
	font-weight:bold !important;
	margin: 0 !important;
}

p {
	font-size:10pt;
	font-weight:normal;
	line-height: 13pt;
}
p.bottom {
margin-top: 0mm !important;
font-size:11pt;
line-height:16pt;
}
.lefttext {
	text-align: left;
}
.righttext {
	text-align: right;
}
.idnumber1 {
    margin: 0mm;
    position: relative;
    bottom: -2mm;
}
.pattern {
	margin: 10mm calc(-1mm - var(--margin));
}
.logos {
    background-repeat: no-repeat;
	background-size: contain;
	margin:-2mm 0;
	text-align:center;
	background-position:bottom 50%;
	height:49mm !important;
}
.header {
    margin: calc(0mm - var(--margin))!important;
	padding: 4mm 10mm;
	border-bottom: 0.75mm solid #000000;
	width:210mm;
	height:65mm;
	display: flex;
	flex-direction:row;
	flex-wrap: wrap;
}
.header::after {
    position: absolute;
    content: '';
    top: 65mm;
    left: 0;
    width: 100%;
    height: 10mm;
    margin: 0;
    pointer-events: none;
    z-index: 9999;
    background-image: url(https://cr.nr.chahinehamila.com/public/birth-certificate-pattern.svg);
    background-repeat: no-repeat;
}
.headercol1 {
    height: 90%;
	margin:0mm 0mm 0mm 0mm;
	flex-basis: 50mm;
}

.headercol2 {
	margin:0mm;
	flex-basis: 90mm;
}
.headercol3 {
    margin: 0mm 0mm 0mm 0mm;
    height: 90%;
    flex-basis: 50mm;
}
.headercol4 {
    margin: 5mm 0mm 0mm 0mm;
    height: 10%;
    flex-basis: 50mm;
}
.headercol5 {
    margin: 2mm 0 0 0;
    flex-basis: 90mm;
    height: 10%;
}
.headercol6 {
    margin: 0mm 0mm 0mm 0mm;
    height: 10%;
    flex-basis: 50mm;
}

.clear {clear:both;}
.bodycontent {
	margin-top: 18mm;
		display: flex;
	flex-wrap: wrap;
}
.bodyrow {
	margin:15mm 0mm 0mm 0mm;
	flex-basis: 190mm;
}
.bodycol1 {

margin:4mm 10mm 0mm 0mm;
	flex-basis: 87.333mm;
}

.bodycol2 {
	height:65mm; 
	margin:4mm 0mm 0mm 0mm;
	flex-basis: 87.333mm;
}
.bodycol3 {
	height:50mm; 
	margin:4mm 0mm 0mm 10mm ;
	flex-basis: 82.333mm;
}
.bodycol4 {
	height:20mm; 
	margin:2mm 10mm 0mm 0mm;
	flex-basis: 174.333mm;
}
.bodycol5 {
	height:25mm; 
	margin:8mm 0mm 0mm 0mm;
	flex-basis: 87.333mm;
}
.bodycol6 {
	height:41mm; 
	margin:-28mm 0mm 0mm 10mm ;
	flex-basis: 82.333mm;
}
.nestedcol1 {
	width:36%;
	margin: 0 2% 2mm 0;
	/*background-color: #999999;*/
	float:left;
	font-weight: bold;
}
.nestedcol1 p{
	font-weight: bold;
}
.nestedcol1 p:first-child, .nestedcol2 p:first-child{
	margin-top:0mm !important;
}
.nestedcol2 {
	width:62%;
	margin: 0 0 2mm 0;
	/*background-color: #313131;*/
	float:left;
}
.bodyrowbottom {
    position: absolute;
    bottom: 2mm;
    left: 15mm;
    height: 30mm;
    width: 100mm;
}

.official {
    height: 25mm;
    width: 85mm;
}
.qr-code {
    position: absolute;
    top: -26mm;
    left: 0mm;
    width: 22mm;
    height: 22mm;
    margin-bottom: 2mm;
}
.stamp {
	position: relative;
	top:-8mm;
	left:20mm;
	width: 32mm;
	height:32mm;
	margin-bottom:2mm;
	background-image:url(https://cr.nr.chahinehamila.com/public/offical-approved-stamp.svg);
	background-size: contain;
	z-index:9999;
}
.signature {
    position: absolute;
    z-index: 9999;
    top: -32mm;
    left: 29mm;
    width: 32mm;
    height: 32mm;
    margin-bottom: 2mm;
    background-image: url(https://cr.nr.chahinehamila.com/public/signature-temp.png);
    background-size: contain;
    background-repeat: no-repeat;
}
.details {
	margin: 0 8mm 0 0;
}

@media screen {
  .page::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: calc(100% - var(--bleed) * 2);
    height: calc(100% - var(--bleed) * 2);
    margin: var(--bleed);
    outline: thin dashed black;
    pointer-events: none;
    z-index: 9999;
  }
}

@media print {
  .page {
    margin: 0;
    overflow: hidden;
  }
		html, body {
        height: 100vh;  
		overflow: hidden;
    }
	@page {
  size: A4;
  margin: 0;
}
	body {
	margin: 0;
}
	html {
		margin: 0;
	}
	
	
	
	* {
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important;                 /* Firefox 48 – 96 */
    print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
}
}

`;
