import * as React from 'react';
import { useField } from "formik";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import arLocale from "date-fns/locale/ar-SA";
import enLocale from "date-fns/locale/en-US";
import frLocale from "date-fns/locale/fr"
import parseISO from 'date-fns/parseISO';

import { t } from "../services";
import { FormControl, InputLabel } from '@mui/material';


export function DateInput(props) {

  const [field, meta, helpers] = useField(props.name);
  const [language, setLanguage] = React.useState(undefined);
  const [locale, setLocal] = React.useState(undefined);
  const { value } = meta;
  const { setValue } = helpers;

  const adjustTimezoneOffset = newDate => {
    var date = new Date(newDate);
    var timezoneOffset = date.getTimezoneOffset() * 60 * 1000;
    return new Date(date.getTime() - timezoneOffset);
  }

  const handleDateChange = newDate => {
    setValue(adjustTimezoneOffset(newDate));
  };

  if (!locale || language != props.language) {
    setLanguage(props.language);
    if (props.language == "ar")
      setLocal(arLocale);
    else if (props.language == "en")
      setLocal(enLocale)
    else if (props.language == "fr")
      setLocal(frLocale);
    else
      setLocal(enLocale)
  }
  return (
    <FormControl variant="standard">
      <InputLabel shrink htmlFor={props.name}>
        {props.label}
      </InputLabel>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale} >
        <KeyboardDatePicker
          margin="normal"
          placeholder="dd/mm/yyyy"
          id={props.id}
          format="dd/MM/yyyy"
          value={value}
          minDate={undefined}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': t`change date`,
          }}
          disabled={props.disabled}
          /*  ToolbarComponent={
             (toolbarProps) => (
                 <div>
                     <CustomDateToolbar {...toolbarProps}/>
                 </div>
             )
         } */
          okLabel={t`ok`}
          cancelLabel={t`cancel`}
          InputLabelProps={{ shrink: false }}
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  );
}