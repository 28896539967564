import React from "react";
import {v4 as uuidv4} from 'uuid';
import * as Yup from 'yup';

import { createFormComponent } from '../../../../auto/js/widgets/FormComponent';
import { OPEN_VIEW_EVENT, CAN_I_SWITCH, DELETE_ATTACHMENT_EVENT, FILE_UPLOADED_EVENT, OPEN_SIDE_PANEL } from '../../../../auto/js/events/Gui';
import { AlertDialog, FormDialog } from "../../../../auto/js/widgets";
import { rest } from "../../../../auto/js/services/RestClient";
import { t } from "../../../../auto/js/services/i18ndb";
import { getServiceUri } from "../../../../auto/js/metadata";
import { showNotification } from "../../../../auto/js/utils";

import { birthRegistrationFields } from "../../../../main/js/forms/birthRegistration/BirthRegistrationFormCommon";

import { loadCivilStatusMtlbAttachment } from "../../../../main/js/forms/birthRegistration/BirthRegistrationFormCommon";
import { readDigitalizationAttachment, openNewImageTab } from "./DigitalizationSearchForm";
import { Section } from "../../../../auto/js/utils";
import { whoami } from "../../../../auto/js/users/UserInfo";
import { Button } from "@mui/material";
import { NotesGrid } from "../../../../auto/js/widgets/NotesGrid";
import { FileInput } from "../../../../main/js/widgets/FileInput";
import { loadBirthRegistrationFormData, rejectReadyBirthRegistrationForm } from "../../../../auto/js/forms/birthRegistration/BirthRegistrationApi";


let BirthRegistrationForm = createFormComponent(birthRegistrationFields);

class FormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		CAN_I_SWITCH.pickUpThePhone(this.listen);
		this.state = {
			closeRequested: undefined,
			openFormDialog: false,
			id: undefined
		}
    }

	listen = (closeMe) => {
		if (!this.isDirty())
			closeMe(true);
		this.setState({closeRequested: closeMe})
	}

	isDirty = () => {
		return this.myRef.current.isDirty();
	}

	handleDialogCancel = () => {
		this.state.closeRequested(false);
		this.setState({closeRequested: undefined});
	}

	handleFormDialogClose = () => {
		this.setState({openFormDialog: false})
	}

	handleSave = () => {
		this.myRef.current.save().then(() => {
			this.state.closeRequested(true);
		});
	}

	handleDontSave = () => {
		this.state.closeRequested(true);
	}

	handleSaveFormDialog = (value) => {
		saveWithNote(this.state.id, value).then(() => {this.setState({openFormDialog: false})});
	}

	reassign = async (id) => {
		this.setState({id: id, openFormDialog: true});
	}

	getButtons = (id) => {
		return (
		<>
		<div className="row">
			<div className="col-md-6">
			<button style={{ minWidth: '5rem', margin: 'auto' }} type="submit" className={'reject-button'} onClick={() => this.reassign(id)}> {t`Re-assign`} </button>
			</div>
			<div className="col-md-6">
			<button style={{ minWidth: '5rem', margin: 'auto' }} type="button" className={'reject-button'} onClick={() => reject(id)}> {t`Reject`} </button>
			</div>
		</div>
		</>
		)
	}

	render() {
		return (
		<>
			<AlertDialog
				title={t`Save your changes ?`}
				open={(this.state.closeRequested && this.isDirty())?true:false}
				handleClose={this.handleDialogCancel}
				noAgree={true}
				save={this.handleSave}
				dontSave={this.handleDontSave}
			/>
			<FormDialog
				title={t`Re-assign reasons`}
				label={t`Reasons`}
				message=""
				open={this.state.openFormDialog}
				handleSave={(value) => this.handleSaveFormDialog(value)}
				handleClose={this.handleFormDialogClose}
			/>
			<Button className="link-button" onClick={() => openSidePanel(this.props.id)}>{t`Notes`}</Button>
			<BirthRegistrationForm ref={this.myRef} key={this.props.key} loadData={async () => loadFormData(this.props.id)} readOnly id={this.props.id} buttons={() => this.getButtons(this.props.id)} />
            <FileInput previewUrl={(id) => getPreviewUrl(id)} uploadUrl={getServiceUri() + 'civil-status-mtlb/attachment/' +  this.props.id} updateFileData={(data) => updateFileData(data)} loadData={async () => loadCivilStatusMtlbAttachment(this.props.id)} handleDelete={(id) => handleCivilStatusMtlbAttachmentDelete(id)} handleClick={(id) => handleCivilStatusMtlbAttachmentClick(id)}/>
		</>
		)
	}

}

const handleCivilStatusMtlbAttachmentDelete = (id) => {
	rest.delete('civil-status-mtlb/attachment', id).then(() => {
		DELETE_ATTACHMENT_EVENT.publish(id)
	});
};

const getPreviewUrl = (id) => {
	return getServiceUri() + 'civil-status-mtlb/attachment' + '/preview/' + id;
}

const handleCivilStatusMtlbAttachmentClick = async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	window.location = getServiceUri() + 'civil-status-mtlb/attachment' + '/' + id + '/' + token;
};

const updateFileData = (data) => {
	let filter = {name: data.fileName, description: data.description};
	rest.request(getServiceUri() + 'civil-status-mtlb/attachment' + '/' + data.id, "PUT", filter);
}

export const displayReadPendingDigitalizationApplicationForm = (gui, id) => {
	const readPendingDigitalizationApplicationForm = (onFinish) => () => {
		let uuid = uuidv4();
		return {
			uuid, view: () =>  {
				return (
					<>
						<FormComponent uuid={uuid} id={id} />
					</>
				)}
		};
	}
	readDigitalizationAttachment(id).then(async response => {
		openNewImageTab(response[0].id);
		gui.goTo(readPendingDigitalizationApplicationForm())
	})
}

const openSidePanel = (id) => {
	let noteFilter = {and: true};
	noteFilter['civil-status-mtlb-note'] = {civilStatusMtlbId: id};
	rest.search('civil-status-mtlb-note', noteFilter).then(notes => {
		OPEN_SIDE_PANEL.publish(
			{body: <NotesGrid list={notes}/>, title: t`Notes`}
		)
	})
}

const reject = async (id) => {
	return rejectReadyBirthRegistrationForm(id).then((response) =>{
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error")
		else
			showNotification(t`Case Rejected`, "success");
	});
}

const loadFormData = async (id) => {
	return await loadBirthRegistrationFormData(id);
}

const saveWithNote = async (id, note) => {
	let filter = {};
	filter.id = id;
	try {
		return rest.request(getServiceUri() + 'apply/reassign-digitalization-mtlb', 'POST', filter).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Case Reassigned`, "success");
		}).then(() => {
			let noteData = { civilStatusMtlbId: id, content: note, addedBy: whoami().firstname + " " +  whoami().lastname, timestamp: new Date()}
			rest.create('civil-status-mtlb-note', noteData);
		});
	} catch (err) {
		alert(err);
	}
}
