import { displayNewBirthRegistrationForm } from '../../../auto/js/forms/birthRegistration/NewBirthRegistrationForm'
import { displayCivilRecordAdvancedSearch, displayCivilRecordsList } from '../../../auto/js/lists/CivilRecordList'
import { displayNewOccupationForm } from '../../../auto/js/forms/metadata/OccupationForm'
import { displayNewTranslationsForm } from '../../../auto/js/forms/metadata/TranslationsForm'
import { displayNewCountryForm } from '../../../auto/js/forms/metadata/CountryForm'
import { displayCountriesList } from '../../../auto/js/lists/metadata/CountriesList'
import { displayOccupationList } from '../../../auto/js/lists/metadata/OccupationList'
import { displayTranslationsList } from '../../../auto/js/lists/metadata/TranslationsList'
import { displayNewDeathRegistrationForm } from '../../../auto/js/forms/deathRegistration/NewDeathRegistrationForm'
import { displayDeathRecordAdvancedSearch, displayDeathRecordList } from '../../../auto/js/lists/DeathRecordList'
import { displayNewMarriageRegistrationForm } from '../../../auto/js/forms/marriageRegistration/NewMarriageRegistrationForm'
import { displayNewDivorceRegistrationForm } from '../../../auto/js/forms/divorceRegistration/NewDivorceRegistrationForm'
import { displayUnionRecordAdvancedSearch, displayUnionRecordList } from '../../../auto/js/lists/UnionRecordList'
import { whoami } from '../../../auto/js/users/UserInfo';
import { displayBirthRegistrationAdvancedSearch, displayBirthRegistrationList } from '../../../auto/js/lists/birthRegistration/BirthRegistrationList'
import { displayAmendmentApplicationList, displayAmendmentApplicationAdvancedSearch } from '../../../auto/js/lists/amendmentApplication/AmendmentApplicationList'
import { displayDeathRegistrationAdvancedSearch, displayDeathRegistrationList } from '../../../auto/js/lists/deathRegistration/DeathRegistrationList'
import { displayIdCardApplicationList } from '../../../auto/js/lists/idCardApplication/IdCardApplicationList'
import { displayMarriageRegistrationAdvancedSearch, displayMarriageRegistrationList } from '../../../auto/js/lists/marriageRegistration/MarriageRegistrationList'
import { displayDivorceRegistrationAdvancedSearch, displayDivorceRegistrationList } from '../../../auto/js/lists/divorceRegistration/DivorceRegistrationList'
import { displayDigitalizationApplicationList } from '../../../auto/js/lists/digitalization/DigitalizationApplicationList'
import { displayIdCardList } from '../../../auto/js/lists/IssuedIdCardList'
import { displayDistrictList } from '../../../auto/js/lists/metadata/DistrictList'
import { displayNewDistrictForm } from '../../../auto/js/forms/metadata/DistrictForm'
import { displayRegistrarList } from '../../../auto/js/lists/registrar/RegistrarList'
import { displayNewRegistrarForm } from '../../../auto/js/forms/registrar/RegistrarForm'

export const menu = () => {
	return {
		...((whoami().roles.includes('ALL')|| whoami().roles.includes('REGISTRAR')) && {
			"civil-records": {
				submenu: {
					"birth-applications": {
						options: {
							new: { label: "New", do: (gui) => displayNewBirthRegistrationForm(gui) },
							// temporaryCertificate: { label: "Temporary Certificate", do: (gui) => displayTemporaryCertificateWorkflow(gui) },
							applications: { label: "Applications", do: (gui) => displayBirthRegistrationList(gui)},
							advancedSearch: { label: "Advanced Search", do: (gui) => displayBirthRegistrationAdvancedSearch(gui)},
						},
						label: "Birth Applications"
					},
					"amendment-application": {
						options: {
							applications: { label: "Applications", do: (gui) => displayAmendmentApplicationList(gui)},
							advancedSearch: { label: "Advanced Search", do: (gui) => displayAmendmentApplicationAdvancedSearch(gui)}
						},
						label: "Amendments"
					},
					"birth-registry": {
						options: {
							search: { label: "Records", do: (gui) => displayCivilRecordsList(gui) },
							advancedSearch: { label: "Advanced Search", do: (gui) => displayCivilRecordAdvancedSearch(gui)}
						},
						label: "Birth Registry"
					}
				},
				label: "Birth"
			},
			"marriage-records": {
				submenu: {
					"marriage-registration": {
						options: {
							new: { label: "New", do: (gui) =>( displayNewMarriageRegistrationForm(gui)) },
							applications: { label: "Applications", do: (gui) => displayMarriageRegistrationList(gui)},
							advancedSearch: { label: "Advanced Search", do: (gui) => displayMarriageRegistrationAdvancedSearch(gui)}
						},
						label: "Marriage Registration"
					},
					"divorce-registration": {
						options: {
							new: { label: "New", do: (gui) => (displayNewDivorceRegistrationForm(gui)) },
							applications: { label: "Applications", do: (gui) => displayDivorceRegistrationList(gui)},
							advancedSearch: { label: "Advanced Search", do: (gui) => displayDivorceRegistrationAdvancedSearch(gui)}

						},
						label: "Divorce Registration"
					},
					other: {
						options: {
							search: { label: "Records", do: (gui) => displayUnionRecordList(gui) },
							advancedSearch: { label: "Advanced Search", do: (gui) => displayUnionRecordAdvancedSearch(gui)}

						}
					}
				},
				label: "Marriage & Divorces"
			},
			"death-records": {
				submenu: {
					"death-registration": {
						options: {
							new: { label: "New", do: (gui) => (displayNewDeathRegistrationForm(gui)) },
							applications: { label: "Applications", do: (gui) => displayDeathRegistrationList(gui)},
							advancedSearch: { label: "Advanced Search", do: (gui) => displayDeathRegistrationAdvancedSearch(gui)}

						},
						label: "Death Applications"
					},
					"death-registry": {
						options: {
							search: { label: "Records", do: (gui) => displayDeathRecordList(gui) },
							advancedSearch: { label: "Advanced Search", do: (gui) => displayDeathRecordAdvancedSearch(gui)}
						},
						label: "Death Registry"
					}
				},
				label: "Death"
			},
			"id-database": {
				submenu: {
					"id-cards": {
						options: {
							applications: { label: "Applications", do: (gui) => displayIdCardApplicationList(gui)}
						},
						label: "ID Card Applications"
					},
					other: {
						options: {
							search: { label: "Issued Documents", do: (gui) => displayIdCardList(gui) }
						}
					}
				},
				label: "ID Cards"
			}
		}),
		...((whoami().roles.includes('ALL') || whoami().roles.includes('DIGITALIZER') || whoami().roles.includes('REGISTRAR')) && {
			"digitalization": {
				submenu: {
					"digitalization": {
						options: {
							"digitalization-forms": {label: "Digitalization Forms", do: (gui) => displayDigitalizationApplicationList(gui)}
						}
					}
				},
				label: "Digitalization"
			}
		}),
		...((whoami().roles.includes('ALL') || whoami().roles.includes('MAINTENANCE')) && {
			"crvs-admin": {
				submenu: {
					/* "occupations": {
						options: {
							list: { label: "Occupations", do: (gui) => displayOccupationList(gui) },
							new: { label: "New", do: (gui) => gui.goTo(displayNewOccupationForm()) },
						},
						label: "Occupations"
					},
					"countries": {
						options: {
							list: { label: "Countries", do: (gui) => displayCountriesList(gui) },
							new: { label: "New", do: (gui) => gui.goTo(displayNewCountryForm()) },
						},
						label: "Countries"
					}, */
					"registrar": {
						options: {
							list: { label: "Registrar List", do : (gui) => displayRegistrarList(gui)},
							new: { label: "New", do: (gui) => displayNewRegistrarForm(gui)}
						},
						label: "Registrars"
					},
					"translations": {
						options: {
							list: { label: "Translations", do: (gui) => displayTranslationsList(gui) },
							new: { label: "New", do: (gui) => displayNewTranslationsForm(gui) },
						},
						label: "Translations"
					},
				},
				label: "Configuration"
			}
		}),
		...((whoami().roles.includes('ALL') || whoami().roles.includes('MAINTENANCE')) && {
			"geography": {
				submenu: {
					"district": {
						options: {
							list: { label: "District List", do: (gui) => displayDistrictList(gui) },
							new: { label: "New", do: (gui) => gui.goTo(displayNewDistrictForm()) },
						},
						label: "District"
					}
				},
				label: "Geography"
		}}),
		/* ...((whoami().roles.includes('ALL') || whoami().roles.includes('REGISTRAR')) && {
			"reports": {
				submenu: {
					"report-applications": {
						options: {
							apply: {label: "Applications", do: (gui) => displayGeneralStatisticsList(gui) }
						},
						label: "General Statistics Applications"
					},
					"actions": {
						options: {
							apply: {label: "Apply for General Statistics Report", do: (gui) => applyForGeneralStatisticsReport(gui) }
						},
						label: "Actions"
					}
				},
				label: "Reports"
			}
		}) */
	}
}