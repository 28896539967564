import * as React from 'react';
import QRCode from "react-qr-code";

import {marriageCertificateContent} from "./MarriageCerificateCommon.jsx";
import { geoDataMetadataLoader } from '../../../auto/js/metadata/GeoDataMetadataLoader';
import { t } from '../../../auto/js/services';


const formatDate = (date) => {
	let d = new Date(date[0], date[1]-1, date[2]);
	return d.toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
}

const formatLocation = (location) => {
	let marriagePlace = [];
	if (location != null) {
		let marriagePlaceComponents = location.split(".");
		let place = location;
		marriagePlace.push(
			(geoDataMetadataLoader.getArea(place))?t(geoDataMetadataLoader.getArea(place).name + " "):t("INVALID DATA ")
		)
		for (let i = 0; i < marriagePlaceComponents.length - 1 ; i ++) {
			let parentAreaId = place.substring(0, place.lastIndexOf("."));
			place = parentAreaId;
			marriagePlace.push(
				(geoDataMetadataLoader.getArea(parentAreaId))?t(geoDataMetadataLoader.getArea(parentAreaId).name + " "):t("INVALID DATA ")
			)                
		}
	}
	else
		marriagePlace.push(t`Unknown Place`)
	return marriagePlace;
}

export class MarriageCertificate extends React.Component {
	constructor(props) {
		super(props);
		this.data = props.data;
	}

	// @ts-ignore
	render() {
		let marriagedate = this.data.marriagedate? formatDate(this.data.marriagedate):'Unknown';
		var currentdate = new Date();

		let	partner1DateOfBirth = this.data.partner1DateOfBirth? formatDate(this.data.partner1DateOfBirth):'Unknown';
		let partner2DateOfBirth = this.data.partner2DateOfBirth? formatDate(this.data.partner2DateOfBirth):'Unknown';
		let declarationDate = this.data.declarationDate? formatDate(this.data.declarationDate):'Unknown';

		let fullname = '';
		fullname += this.data.firstname ? this.data.firstname + ' ' : '';
		fullname += this.data.secondname ? this.data.secondname + ' ' : '';
		fullname += this.data.fourthname ? this.data.fourthname + ' ' : '';
		let registrarFullName = '';
		if (this.data.registrarId != null) {
			registrarFullName += this.data.registrarFirstName ? this.data.registrarFirstName + ' ' : '';
			registrarFullName += this.data.registrarFourthName ? this.data.registrarFourthName + ' ' : '';
		}
		
		return (

			<div className="page">
				<div className="header">
					<div className="headercol1">
						<h2 className="lefttext"> {marriageCertificateContent.leftTitle1}<br/>
							{marriageCertificateContent.leftTitle2}</h2></div>
					<div className="headercol2 logos">

					</div>
					<div className="headercol3">
						<h2 className="righttext">{marriageCertificateContent.rightTitle1}<br/>{marriageCertificateContent.rightTitle2}</h2>
					</div>
					<div className="headercol4">

					</div>
					<div className="headercol5">
						<h1>{marriageCertificateContent.mainTitle}</h1>
						<h2>{marriageCertificateContent.mainSubTitle}</h2>
					</div>
					{/* <div className="headercol6">
						<h2 className="righttext idnumber1">{t`National ID Number`}: {this.data.id}</h2>
					</div> */}
				</div>
				<div className="bodycontent">
					<div class="bodyrow">
						<h1 class="lefttext" style={{margin:"4mm 0 0 0"}}>{t`Marriage Details`}</h1>
							<p class="bottom" style={{margin:"0 0 4mm 0"}}><span class="details"><strong>{t`Date`}: </strong>{declarationDate}</span> <span  class="details"><strong>{t`Time`}: </strong>{this.data.timeOfMarriage} </span><span class="details"><strong>{t`Place`}: </strong>{formatLocation(this.data.declarationPlace)}</span><span  class="details"><strong>{t`Celebrant`}: </strong>{this.data.celebrant}</span><span  class="details"><strong>{t`Type of Marriage`}: </strong>{this.data.marriageType}.</span></p>
					</div>
					<div className="bodycol1">
						<h1 className="lefttext">{t`Wife`}</h1>
						<div className="nestedcol1">
							<p>Family Name<br/>
							{t`First Name(s)`}<br/>
							{t`Other Name`}<br/>
							{t`National ID Number`}
							</p>
							
							<p>
							{t`Date of birth`} <br/>
							{t`Location of Birth`}<br/>
							</p>
							<p>
							{t`Usual Residence`}<br/>
							&nbsp;<br/>
							&nbsp;<br/>
							</p>
							<p>
							{t`Mother's Name`}<br/>
							{t`Father's Name`}<br/>
							</p>
						</div>
						<div className="nestedcol2">
							<p> 
								{(this.data.partner2Surname !== null)?this.data.partner2Surname: " "}<br/>
								{(this.data.partner2FirstNames !== null)?this.data.partner2FirstNames: " "}<br/>
								{(this.data.partner2MelanesianName !== null)?this.data.partner2MelanesianName:" "}<br/>
								{(this.data.partner2VitalRecordId !== null)?this.data.partner2VitalRecordId: " "}
							</p>

							<p>
								{partner2DateOfBirth}<br/>
								{formatLocation(this.data.partner2BirthLocation)}<br/>
							</p>
							<p>
								{formatLocation(this.data.partner2UsualResidence)}<br/>
							</p>
							<p>
								{this.data.partner2MotherName}<br/>
								{this.data.partner2FatherName}
							</p>
						</div>
					</div>
					<div className="bodycol2">
						<h1 className="lefttext">{t`Husband`}</h1>
						<div className="nestedcol1">
							<p>Family Name<br/>
							{t`First Name(s)`}<br/>
							{t`Other Name`}<br/>
							{t`National ID Number`}
							</p>
							
							<p>
							{t`Date of birth`} <br/>
							{t`Location of Birth`}<br/>
							</p>
							<p>
							{t`Usual Residence`}<br/>
							&nbsp;<br/>
							&nbsp;<br/>
							</p>
							<p>
							{t`Mother's Name`}<br/>
							{t`Father's Name`}<br/>
							</p>
						</div>
						<div className="nestedcol2">
							<p> 
							{(this.data.partner1Surname !== null)?this.data.partner1Surname: " "}<br/>
								{(this.data.partner1FirstNames !== null)?this.data.partner1FirstNames: " "}<br/>
								{(this.data.partner1MelanesianName !== null)?this.data.partner1MelanesianName:" "}<br/>
								{(this.data.partner1VitalRecordId !== null)?this.data.partner1VitalRecordId: " "}
							</p>

							<p>
								{partner1DateOfBirth}<br/>
								{formatLocation(this.data.partner1BirthLocation)}<br/>
							</p>
							<p>
								{formatLocation(this.data.partner1UsualResidence)}<br/>
							</p>
							<p>
								{this.data.partner1MotherName}<br/>
								{this.data.partner1FatherName}
							</p>
						</div>
					</div>
					<div className="bodycol3">
						<h1 className="lefttext">{t`Declaration`}</h1>
						<div className="nestedcol1">
							<p>{t`Full Name`}<br/>
								{t`National ID Number`}<br/>
								{t`Date`}<br/>
								{t`Place`}<br/>
							</p>
						</div>
						<div className="nestedcol2">
							<p>{this.data.declarationFullName}<br/>
								{this.data.declarationNationalIdNumber}<br/>
								{declarationDate}<br/>
								{formatLocation(this.data.declarationPlace)}<br/>
							</p>
						</div>
					</div>
					<div class="bodycol4">
					<h1 class="lefttext">{t`Witnesses`}</h1>
					<div class="nestedcol1">
						<h3>{t`Witness 1`}</h3>
						<p class="bottom">{t`Full Name`}<br/>
						{t`National ID Number`}
						</p>
						
					</div>
					<div class="nestedcol2">
					<h3>&nbsp;</h3>
						<p class="bottom">{(this.data.firstWitnessName !== null)?this.data.firstWitnessName: " "}<br/>
						{(this.data.firstWitnessId !== null)?this.data.firstWitnessId:" "}
						</p>
							
					</div>
					</div>
					<div class="bodycol5">
						<h1 class="lefttext">&nbsp;</h1>
						<div class="nestedcol1">
							<h3>{t`Witness 2`}</h3>
							<p class="bottom">{t`Full Name`}<br/>
							{t`National ID Number`}
							</p>
							
						</div>
					<div class="nestedcol2">
							<h3>&nbsp;</h3>
							<p class="bottom">{(this.data.secondWitnessName !== null)?this.data.secondWitnessName: " "}<br/>
							{(this.data.secondWitnessId !== null)?this.data.secondWitnessId: " "}
							</p>
							
					</div>
					</div>

					<div className="bodycol6">
						<div className="official">
							<div className="qr-code">
								<QRCode value={"\nCertificate issued on " + declarationDate + " - Signed by Director " + registrarFullName} size={80}/>
							</div>
							<div className="signature"></div>
						</div>
						<p className="lefttext idnumber1">Certified to be a true copy issued without alteration
							at </p><p className="strongstyle"><strong>{registrarFullName}<br/>Registrar General</strong></p>
					</div>
				</div>
			</div>

		)
	}
}