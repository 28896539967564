import React from 'react';
import {v4 as uuidv4} from 'uuid';
import { Centrifuge } from "centrifuge";
import { formState } from "../../../auto/js/forms/FormState";
import { MTLB_STATUS_ARCHIVED, MTLB_STATUS_READY_FOR_APPROVAL } from "../../../auto/js/metadata/MtlbStatus";
import { rest } from "../../../auto/js/services";
import { loadingIcon } from "../../../auto/js/loadingIcon";
import { getServiceUri } from "../../../auto/js/metadata";


export const process = (manager) => {
    manager.gui.goTo(displayServerRegistrationLoading(manager.complete), formState.getState().idMtlbId, null);
}

const displayServerRegistrationLoading = (onFinish) => (id) => {
    let uuid = uuidv4();
	return { 
		uuid, view: () => <LoadingComponent key={uuid} id={id} onFinish={() => onFinish()}/>
	}
}

export class LoadingComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            status: MTLB_STATUS_READY_FOR_APPROVAL,
        }

        this.centrifuge = new Centrifuge("wss://cr.osia.vm/connection/websocket", {
            token: rest.oidcService.token,
            getToken: function () {
              return new Promise((resolve, reject) => {
                rest.oidcService.updateToken(55).then(function () {
                  resolve(rest.oidcService.token);
                }).catch(function (err) {
                  reject(err);
                  rest.oidcService.logout();
                });
              })
            }
          });
        
          this.centrifuge.connect();
          this.sub = this.centrifuge.newSubscription("2." + props.id);
    }
    
    componentDidMount() {
    this.getServiceStatus();
    }

    componentWillUnmount() {
        if (this.interval) {
        clearInterval(this.interval);
        }
    }

    getServiceStatus = () => {
        this.interval = setInterval(() => {
            let filter = {};
            filter.type = 2;
            filter.id = this.props.id
            rest.request(getServiceUri() + 'apply/get-service-status', 'POST', filter).then(response => {
                if (response.serviceStatus === MTLB_STATUS_ARCHIVED) {
                    this.sub.off('publication', this.handleStatusChange);
                    this.props.onFinish()
                    clearInterval(this.interval);
                }
            })
        }, 3000)
      };

    handleStatusChange = (ctx) => {
        if (ctx.data.value.key === "status") {
            this.setState({status: ctx.data.value.value});
            if (ctx.data.value.value === MTLB_STATUS_ARCHIVED) {
                this.sub.off('publication', this.handleStatusChange);
                this.props.onFinish()
            }
        }
    }

    render() {
        this.sub.on('publication', this.handleStatusChange).subscribe();
        return loadingIcon;
    }

}